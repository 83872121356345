import {Label, Select} from "flowbite-react";
import React from "react";
import {useTranslation} from "react-i18next";

interface GenderSelectProps {
    value: string,
    setValue: (newValue: string) => void;
}

export default function GenderSelect({value, setValue} : GenderSelectProps) {
    const { t } = useTranslation("users");

    return (
        <div>
            <Label htmlFor="gender" value={t("gender") + "*"} />
            <Select value={value}
                    onChange={(e) => setValue(e.target.value)}
                    id="gender"
                    required
            >
                <option disabled value="">
                    {t("selectGender")}
                </option>
                <option value="M">{t("gender.M")}</option>
                <option value="F">{t("gender.F")}</option>
                <option value="N">{t("gender.N")}</option>
            </Select>
        </div>
    );
}