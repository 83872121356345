import QuizManagement from "./QuizManagement";
import Subject from "../../domain/Subject";
import DutyManagement from "./DutyManagement";

interface SubjectContentManagementProps {
    subject: Subject | undefined;
    canBeUpdated: boolean;
}

export default function SubjectContentManagement({subject, canBeUpdated}: SubjectContentManagementProps) {
    if (subject?.quiz) {
        return <QuizManagement quiz={subject.quiz} canBeUpdated={canBeUpdated} />
    } else if (subject?.duty) {
        return <DutyManagement subject={subject} />
    } else {
        return <div>Empty</div>
    }
}