import React, {useCallback, useEffect, useState} from "react";
import {User} from "../../../domain/User";
import AddUserModal from "../components/AddUserModal";
import {Button, Card, Table} from "flowbite-react";
import {HiChevronRight} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../components/AppContext/AppContext";
import SearchBar from "../../../components/SearchBar";
import PaginationParams from "../../../components/PaginationParams";
import LoadingComponent from "../../../components/LoadingComponent";
import {getUsersPaginated} from "../api/userConnector";

interface UserTableProps {
    users: User[];
}

function UserTable({users} : UserTableProps) {
    const navigate = useNavigate();
    return <Table className="divide-y">
        <Table.Head>
            <Table.HeadCell>
                Nom d'utilisateur
            </Table.HeadCell>
            <Table.HeadCell>
                Prénom
            </Table.HeadCell>
            <Table.HeadCell>
                NOM
            </Table.HeadCell>
            <Table.HeadCell>
                Email
            </Table.HeadCell>
            <Table.HeadCell>
            </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
            {users.map((user) => (
                <Table.Row key={user.username}>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {user.username}
                    </Table.Cell>
                    <Table.Cell>{user.usageFirstName || user.firstName}</Table.Cell>
                    <Table.Cell>{user.lastName}</Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell className="flex justify-end py-2">
                        <Button color="primary" pill onClick={() => navigate(user.id ?? "")}>
                            <HiChevronRight className="h-5 w-5"/>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            ))}
        </Table.Body>
    </Table>;
}

export default function UsersList() {
    const { t } = useTranslation("users");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [searchCriteria, setSearchCriteria] = useState("");

    const [isUsersLoading, setUsersLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const {throwError} = useAppContext();

    const reloadUsers = useCallback((searchCriteriaParams?: string) => {
        setUsersLoading(true);
        getUsersPaginated(pageNumber, pageSize, searchCriteriaParams || "").then((usersAxiosResponse) => {
            setUsersLoading(false);
            setTotalPages(usersAxiosResponse.data.totalPages);
            setUsers(usersAxiosResponse.data.result);
        }).catch(() => throwError(t("getUsersError") || ""));
    }, [pageNumber, pageSize, t, throwError]);

    useEffect(() => {
        reloadUsers(searchCriteria);
    }, [reloadUsers, pageNumber, pageSize, searchCriteria]);

    return (
        <Card className="mx-4">
            <div className="inline-flex">
                <AddUserModal refresh={() => reloadUsers()}/>
                <SearchBar submit={v => setSearchCriteria(v)}/>
            </div>
            <div className="my-2 relative overflow-auto">
                <LoadingComponent isLoading={isUsersLoading}>
                    <UserTable users={users} />
                </LoadingComponent>
            </div>
            <PaginationParams pageSize={pageSize}
                              setPageSize={setPageSize}
                              setPageNumber={setPageNumber}
                              pageNumber={pageNumber}
                              totalPages={totalPages}/>
        </Card>
    );
}