import {Button} from "flowbite-react";
import React, {Dispatch, SetStateAction, useState} from "react";
import {useTranslation} from "react-i18next";
import Answer from "../../domain/Answer";
import AnswerModal from "./AnswerModal";

interface AnswerCreateModalProps {
    setAnswers: Dispatch<SetStateAction<Answer[]>>;
    handleCreate: (content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) => void;
}

export default function AnswerCreateModal({handleCreate, setAnswers} : AnswerCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation("assessments");

    return (
        <>
            <Button color="success" onClick={() => setIsOpen(true)}>{t("addAnswer")}</Button>
            <AnswerModal isOpen={isOpen}
                         setIsOpen={setIsOpen}
                         setAnswers={setAnswers}
                         handleCreate={handleCreate} />
        </>
    );
}