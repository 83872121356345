import {Card} from "flowbite-react";
import {QuestionTypeEnum} from "../domain/QuestionTypeEnum";
import AnswerAccordion from "./AnswerAccordion";
import React, {Dispatch, SetStateAction} from "react";
import Question from "../domain/Question";
import {useTranslation} from "react-i18next";
import QuestionUpdateModal from "./QuestionModals/QuestionUpdateModal";
import QuestionDeleteModal from "./QuestionModals/QuestionDeleteModal";
import MarkdownPreview from "../../../components/Markdown/MarkdownPreview";

interface QuestionCardProps {
    question: Question;
    setQuestions: Dispatch<SetStateAction<Question[]>>;
    subjectId: string;
}

export default function QuestionCard({question, setQuestions, subjectId} : QuestionCardProps) {
    const { t } = useTranslation("enrolmentTests");

    return (
        <Card key={question.id} className="my-4">
            <div className="flex justify-between">
                <div className="flex">
                    <h5 className="font-bold text-2xl">{question.title}&nbsp;</h5>
                </div>
                <div className="space-x-1">
                    <QuestionUpdateModal updatableQuestion={question} setQuestions={setQuestions} subjectId={subjectId} />
                    <QuestionDeleteModal setQuestions={setQuestions} subjectId={subjectId} questionId={question.id} />
                </div>
            </div>
            {question.type === QuestionTypeEnum.FILL_IN_THE_GAPS ? (
                <span className="whitespace-pre-wrap">{question.description}</span>
            ) : (
                <MarkdownPreview source={question.description}/>
            )}
            <div className="flex mb-2">
                {question.time > 0 ?
                    <span className="rounded-3xl border-2 p-1">{question.time} {t("seconds")}</span> : ""}
                <span className="rounded-3xl border-2 p-1">{t(question.type.toString())}</span>
            </div>
            {question.type !== "OPEN" ? (
                <AnswerAccordion question={question} subjectId={subjectId} />
            ) : ""}
        </Card>
    );
}