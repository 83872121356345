import {Button} from "flowbite-react";
import React, {Dispatch, SetStateAction, useState} from "react";
import QuizModal from "./QuizModal";
import {QuizLight} from "../../domain/Quiz";
import {HiPlus} from "react-icons/hi2";

interface QuestionsCreateModalProps {
    setQuizzes: Dispatch<SetStateAction<QuizLight[]>>;
}

export default function QuizCreateModal({setQuizzes} : QuestionsCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button color="success" onClick={() => setIsOpen(true)}><HiPlus size="20" /></Button>
            <QuizModal isOpen={isOpen} setIsOpen={setIsOpen} setQuizzes={setQuizzes} />
        </>
    );
}