import {Accordion} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {AccordionContent} from "flowbite-react/lib/esm/components/Accordion/AccordionContent";
import Question from "../domain/Question";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import ShowIf from "../../../components/ConditionalShow/ShowIf";
import AnswerCreateModal from "./AnswerModals/AnswerCreateModal";
import Answer from "../domain/Answer";
import AnswerUpdateModal from "./AnswerModals/AnswerUpdateModal";
import AnswerDeleteModal from "./AnswerModals/AnswerDeleteModal";

interface AnswerAccordionProps {
    question: Question;
    handleUpdate: (parentId: string, updatableAnswer: Answer, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) => void;
    handleCreate: (parentId: string, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) => void;
    handleDelete: (parentId: string, answerId: number, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) => void;
}

export default function AnswerAccordion({question, handleUpdate, handleCreate, handleDelete} : AnswerAccordionProps) {
    const {t} = useTranslation("assessments");
    const [answers, setAnswers] = useState<Answer[]>([]);

    function handleAnswerUpdate(updatableAnswer: Answer, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) {
        handleUpdate(question.id, updatableAnswer, content, isValid, setIsOpen, setAnswers, setIsValid, setContent);
    }

    function handleAnswerCreate(content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) {
        handleCreate(question.id, content, isValid, setIsOpen, setAnswers, setIsValid, setContent);
    }

    function handleAnswerDelete(answerId: number, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) {
        handleDelete(question.id, answerId, setAnswers, setIsOpen);
    }

    useEffect(() => {
        if (question) {
            setAnswers(question.answers || []);
        }
    }, [question]);

    return (
        <Accordion collapseAll>
            <Accordion.Panel>
                <Accordion.Title>{t("answers")}</Accordion.Title>
                <AccordionContent>
                    <ShowIf condition={answers !== undefined && answers.length > 0}>
                        {answers.map(answer => (
                            <div key={answer.id}>
                                <div className="inline-flex w-full justify-between mt-2">
                                    <div>
                                        <h6 className={answer.isValid ? "text-green-600" : "text-red-600"}>{answer.content}</h6>
                                    </div>
                                    <div className="inline-flex">
                                        <AnswerUpdateModal setAnswers={setAnswers} updatableAnswer={answer} handleUpdate={handleAnswerUpdate} />
                                        <AnswerDeleteModal answerId={answer.id} setAnswers={setAnswers} handleDelete={handleAnswerDelete} />
                                    </div>
                                </div>
                                <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700" />
                            </div>
                        ))}
                    </ShowIf>
                    <div className="w-full flex justify-center mt-2">
                        <AnswerCreateModal setAnswers={setAnswers} handleCreate={handleAnswerCreate} />
                    </div>
                </AccordionContent>
            </Accordion.Panel>
        </Accordion>
    );

}