import axios, {AxiosResponse} from "axios";
import Assessment, {AssessmentLight} from "../domain/Assessment";

export function getAssessments(): Promise<AxiosResponse<AssessmentLight[]>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getAssessment(id: string): Promise<AxiosResponse<Assessment>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/${id}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function createAssessment(body: any): Promise<AxiosResponse<AssessmentLight>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}