import {Button, Modal, Table} from "flowbite-react";
import React, {FormEvent, useState} from "react";
import {HiCheck, HiPlus, HiTrash} from "react-icons/hi2";
import {FooterDivider} from "flowbite-react/lib/esm/components/Footer/FooterDivider";
import UserAssertion from "../../../domain/UserAssertion";
import PhoneNumberField from "./PhoneNumberField";
import AddAssertionModal from "./AddAssertionModal";
import {useTranslation} from "react-i18next";
import {getCampusCityByCode} from "../../../domain/Campus";
import WriteInput from "../../../components/Inputs/WriteInput";
import DoubleColumnGrid from "../../../components/DoubleColumnGrid";
import GenderSelect from "./GenderSelect";
import CivilitySelect from "./CivilitySelect";
import {parseDate} from "../../../components/utils/DateUtil";
import {compareAssertion} from "./AssertionUtil";
import {createUser} from "../api/userConnector";

interface AddUserModalProps {
    refresh: () => void;
}

export default function AddUserModal({refresh}: AddUserModalProps) {
    const {t} = useTranslation("users");
    const [isOpen, setIsOpen] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [usageFirstName, setUsageFirstName] = useState("");
    const [secondName, setSecondName] = useState("");
    const [thirdName, setThirdName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [birthCity, setBirthCity] = useState("");
    const [gender, setGender] = useState("");
    const [civility, setCivility] = useState("");
    const [nationality, setNationality] = useState("");
    const [assertions, setAssertions] = useState<UserAssertion[]>([]);
    const [phonePrefix, setPhonePrefix] = useState("33");
    const [phoneNumber, setPhoneNumber] = useState("");

    function addAssertion(assertion: UserAssertion) {
        return new Promise<void>((resolve, reject) => {
            if (assertions.filter(existingAssertion => compareAssertion(assertion, existingAssertion)).length > 0) {
                reject(new Error(t("addSameAssertionError") || ""))
            } else if (assertion.assertion === 'STUDENT' && (!birthDate || birthDate === "") ) {
                reject(new Error(t("birthDateRequiredError") || ""))
            } else {
                setAssertions(prevState => {
                    let newState = [...prevState];
                    newState.push(assertion);
                    return newState;
                });
                resolve();
            }
        });
    }

    function removeAssertionByIndex(index: number) {
        setAssertions(prevState => {
            return prevState.filter((_, i) => i !== index);
        });
    }

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        createUser({
            firstName: firstName,
            usageFirstName: usageFirstName,
            secondName: secondName,
            thirdName: thirdName,
            lastName: lastName,
            birthCity: birthCity,
            birthDate: parseDate(birthDate),
            civility: civility,
            gender: gender,
            nationality: nationality,
            phonePrefix: phonePrefix,
            phoneNumber: phoneNumber,
            email: email,
            assertions: assertions
        }).then(() => {
            refresh();
            setIsOpen(false);
        })
    }

    return (
        <div>
            <Button onClick={() => setIsOpen(true)} color="success">
                <HiPlus className="h-5 w-5" />
            </Button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <Modal.Header>
                    Création d'un utilisateur
                </Modal.Header>
                <Modal.Body className="max-h-[80vh] overflow-auto">
                    <form onSubmit={submit} id="userForm">
                        <div className="space-y-4">
                            <WriteInput label="lastName" value={lastName} setter={setLastName} t={t} placeholder="SARDOU" required />
                            <DoubleColumnGrid first={<WriteInput label="firstName" value={firstName} setter={setFirstName} t={t} placeholder="Michel" required />}
                                              second={<WriteInput label="usageFirstName" value={usageFirstName} setter={setUsageFirstName} t={t} placeholder="Michou" />} />
                            <DoubleColumnGrid first={<WriteInput label="secondName" value={secondName} setter={setSecondName} t={t} placeholder="Charles" />}
                                              second={<WriteInput label="thirdName" value={thirdName} setter={setThirdName} t={t} placeholder="Fernand" />} />
                            <WriteInput label="email" value={email} setter={setEmail} t={t} placeholder="michel@sardou.fr" required />
                            <DoubleColumnGrid first={<GenderSelect value={gender} setValue={setGender} />}
                                              second={<CivilitySelect value={civility} setValue={setCivility} />} />
                            <PhoneNumberField phoneNumber={phoneNumber}
                                              phonePrefix={phonePrefix}
                                              setPhoneNumber={setPhoneNumber}
                                              setPhonePrefix={setPhonePrefix}/>
                            <DoubleColumnGrid first={<WriteInput label="birthDate" value={birthDate} setter={setBirthDate} t={t} placeholder="" type="date" />}
                                              second={<WriteInput label="birthCity" value={birthCity} setter={setBirthCity} t={t} placeholder="VERSAILLES" />} />
                            <WriteInput label="nationality" value={nationality} setter={setNationality} t={t} placeholder="Française" />
                        </div>
                        <FooterDivider/>
                        <Table>
                            <Table.Head>
                                <Table.HeadCell>
                                    Type d'assignation
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Rôle
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Campus
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Période
                                </Table.HeadCell>
                                <Table.HeadCell>
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {assertions.map((assertion, index) => (
                                    <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell
                                            className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            {t(assertion.assertion, {ns: "common"})}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {assertion.value}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {getCampusCityByCode(assertion.campusCode || "")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {assertion.startDate.toLocaleDateString()} {assertion.endDate.toLocaleDateString()}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button pill outline color="none"
                                                    onClick={() => removeAssertionByIndex(index)}>
                                                <HiTrash className="h-4 w-4"/>
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        {assertions.length === 0 && (
                            <div className="mt-2 text-center">
                                Aucune assignation pour le moment.
                            </div>
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer className="grid grid-cols-2">
                    <AddAssertionModal addAssertion={addAssertion}/>
                    <Button type="submit" color="success" form="userForm">
                        <p>Valider la création</p>
                        <HiCheck className="ml-2 h-5 w-5"/>
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}