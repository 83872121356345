import React, {Dispatch, SetStateAction, useState} from "react";
import {HiTrash} from "react-icons/hi2";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import Question from "../../domain/Question";
import {deleteQuestion} from "../../api/questionConnector";

interface QuestionDeleteModalProps {
    subjectId: string;
    questionId: number;
    setQuestions: Dispatch<SetStateAction<Question[]>>;
}

export default function QuestionDeleteModal({subjectId, questionId, setQuestions} : QuestionDeleteModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("enrolmentTests");
    const {throwError} = useAppContext();

    function submit() {
        deleteQuestion(subjectId, questionId)
            .then(() => {
                setQuestions(prevState => {
                    let newState = [...prevState];
                    const questionIndex = newState.findIndex(q => q.id === questionId);

                    if (questionIndex !== -1) {
                        newState.splice(questionIndex, 1);
                    }
                    return newState;
                })
                setIsOpen(false);
            })
            .catch(() => throwError("deleteQuestionError"));
    }

    return (
        <>
            <button
                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full" onClick={() => setIsOpen(true)}>
                <HiTrash color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <ModalHeader>
                    {t("deleteQuestion")}
                </ModalHeader>
                <ModalBody>
                    {t("confirmDeleteQuestion")}
                </ModalBody>
                <ModalFooter className="flex justify-between">
                    <Button color="primary" onClick={() => setIsOpen(false)}>
                        {t("cancel", {ns: "common"})}
                    </Button>
                    <Button color="failure" onClick={submit}>
                        {t("delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}