import React from "react";

interface DoubleColumnGridProps {
    first: React.ReactNode,
    second: React.ReactNode,
}

export default function DoubleColumnGrid({first, second} : DoubleColumnGridProps) {

    return (
        <div className="grid grid-cols-2">
            <div className="w-auto me-1">
                {first}
            </div>
            <div className="w-auto ms-1">
                {second}
            </div>
        </div>
    );
}