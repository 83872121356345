import ReadInput from "../../../../components/Inputs/ReadInput";
import PhoneNumberField from "../PhoneNumberField";
import {parseDateToString} from "../../../../components/utils/DateUtil";
import {Button, Card} from "flowbite-react";
import React, {useEffect, useState} from "react";
import {User} from "../../../../domain/User";
import {useTranslation} from "react-i18next";
import {HiPencil} from "react-icons/hi2";
import ShowIf from "../../../../components/ConditionalShow/ShowIf";
import UpdateUserForm from "../UpdateUserForm";
import {isAdministrator} from "../../../../components/authentication/RoleUtil";
import {isRestricted} from "../AssertionUtil";
import {useAppContext} from "../../../../components/AppContext/AppContext";

interface PersonalInformationTabProps {
    user?: User;
    doReload: () => void;
}

export default function PersonalInformationTab({user, doReload} : PersonalInformationTabProps) {
    const [isEditing, setIsEditing] = useState(false);
    const { t } = useTranslation("users");
    const { roles } = useAppContext();

    useEffect(() => {
        setIsEditing(false);
    }, [user, setIsEditing]);

    return (
        <Card className="relative">
            <ShowIf condition={!isEditing}>
                <>
                    <ShowIf condition={!isRestricted(user) || isAdministrator(roles)}>
                        <Button onClick={() => setIsEditing(true)} color="secondary" className="absolute end-0 top-0 rounded-none rounded-se-lg rounded-es-lg">
                            <HiPencil />
                        </Button>
                    </ShowIf>
                    <ReadInput value={user?.firstName} label={"firstName"} />
                    <ReadInput value={user?.usageFirstName} label={"usageFirstName"} />
                    <ReadInput value={user?.secondName} label={"secondName"} />
                    <ReadInput value={user?.thirdName} label={"thirdName"} />
                    <ReadInput value={user?.lastName} label={"lastName"} />
                    <ReadInput value={t("gender." + user?.gender ?? "M") ?? ""} label={"gender"} />
                    <ReadInput value={user?.civility} label={"civility"} />
                    <PhoneNumberField readOnly phoneNumber={user?.phoneNumber ?? ""} phonePrefix={user?.phonePrefix ?? ""} />
                    <ReadInput value={parseDateToString(user?.birthDate)} label={"birthDate"} />
                    <ReadInput value={user?.birthCity} label={"birthCity"} />
                    <ReadInput value={user?.nationality} label={"nationality"} />
                </>
            </ShowIf>
            <ShowIf condition={isEditing}>
                <UpdateUserForm setIsEditing={setIsEditing} user={user} doReload={doReload}/>
            </ShowIf>
        </Card>
    );
}