import React, {useState} from "react";
import {CustomFlowbiteTheme, TextInput} from "flowbite-react";
import {HiSearch} from "react-icons/hi";

interface SearchBarProps {
    submit: (value: string) => void;
    placeholder?: string;
}

export default function SearchBar({submit, placeholder} : SearchBarProps) {
    const [searchInput, setSearchInput] = useState("");

    const textInputTheme: CustomFlowbiteTheme['textInput'] = {
        addon: "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400 hover:bg-gray-300 hover:cursor-pointer"
    };

    function handleSearchKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            submit(searchInput);
        }
    }

    return <TextInput theme={textInputTheme}
                      addon={<div className="px-3 py-2" onClick={() => submit(searchInput)}><HiSearch
                          className="h-5 w-5"/></div>}
                      id="search"
                      className="ms-2 w-full"
                      value={searchInput}
                      placeholder={placeholder}
                      onKeyDown={(e) => handleSearchKeyDown(e)}
                      onChange={(e) => setSearchInput(e.target.value)}/>;
}