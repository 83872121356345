import LauncherCategoryTab from "./LauncherCategoryTab";
import {useState} from "react";
import {useAppContext} from "../AppContext/AppContext";
import LauncherCategoryContent from "./LauncherCategoryContent";

export default function AppLauncher() {
    const {access} = useAppContext();
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            {access && access.length > 0 && (
                <>
                    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                        {access.map((category, index) => <LauncherCategoryTab key={category.name}
                                                                              isActive={index === activeTab}
                                                                              setActive={() => setActiveTab(index)}
                                                                              category={category}/>)}
                    </ul>
                    {access.map((category, index) => <LauncherCategoryContent key={category.name}
                                                                              isActive={index === activeTab}
                                                                              category={category}/>)}
                </>
            )}
        </>
    );
}