import React from "react";
import {GrValidate} from "react-icons/gr";
import ShowIf from "../../../../components/ConditionalShow/ShowIf";
import ShowIfElse from "../../../../components/ConditionalShow/ShowIfElse";
import {Tooltip} from "flowbite-react";
import {useTranslation} from "react-i18next";

interface SubjectTitleProps {
    title: string | undefined;
    isPublished: boolean | undefined;
    variant: "h2" | "h4"
}

export default function SubjectTitle({title, isPublished, variant}: SubjectTitleProps) {
    const { t } = useTranslation("assessments")
    return (
        <span className={`w-full inline-flex items-center space-x-2 ${variant === "h4" && "mb-2"}`}>
            <ShowIfElse condition={variant === "h2"} elseShow={(
                <h4 className="font-semibold">{title}</h4>
            )}>
                <h2 className="text-3xl">{title}</h2>
            </ShowIfElse>
            <ShowIf condition={isPublished === true}>
                <Tooltip content={t("subjectApproved")}><GrValidate size="20" /></Tooltip>
            </ShowIf>
        </span>
    );
}