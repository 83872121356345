import AbstractDrawer from "../../../../components/AbstractDrawer";
import {AssessmentInstance} from "../../domain/Assessment";
import {HiX} from "react-icons/hi";
import React from "react";
import ShowIf from "../../../../components/ConditionalShow/ShowIf";
import {SubjectTypeEnum} from "../../domain/Subject";
import MyDutyContent from "./MyDutyContent";

interface MyDutyManagementProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    instance: AssessmentInstance;
    reload: () => void;
}

export default function MyAssessmentInstanceContent({setIsOpen, isOpen, instance, reload}: MyDutyManagementProps) {
    return (
        <AbstractDrawer shadow side="end" size="3xl" isOpen={isOpen}
                        setIsOpen={() => setIsOpen(false)}>
            <div className="flex justify-between items-center">
                <button
                    className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                    onClick={() => setIsOpen(false)}>
                    <HiX color={"rgba(0,0,0,0.66)"} size="20"/>
                </button>
                <div className="inline-flex w-full">
                    <h2 className="text-3xl mx-auto">{instance.assessment.subject.title}</h2>
                </div>
            </div>
            <ShowIf condition={instance.assessment.subject.type === SubjectTypeEnum.DUTY}>
                <MyDutyContent instance={instance} reload={reload} />
            </ShowIf>
        </AbstractDrawer>
    );
}