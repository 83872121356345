import React, {Dispatch, SetStateAction, useState} from "react";
import {HiTrash} from "react-icons/hi2";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "flowbite-react";
import {useTranslation} from "react-i18next";
import Answer from "../../domain/Answer";

interface AnswerDeleteModalProps {
    answerId: number;
    setAnswers: Dispatch<SetStateAction<Answer[]>>;
    handleDelete: (answerId: number, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) => void;
}

export default function AnswerDeleteModal({handleDelete, answerId, setAnswers} : AnswerDeleteModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("assessments");

    function submit() {
        handleDelete(answerId, setAnswers, setIsOpen);
    }

    return (
        <>
            <button
                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full" onClick={() => setIsOpen(true)}>
                <HiTrash color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <ModalHeader>
                    {t("deleteAnswer")}
                </ModalHeader>
                <ModalBody>
                    {t("confirmDeleteAnswer")}
                </ModalBody>
                <ModalFooter className="flex justify-between">
                    <Button color="primary" onClick={() => setIsOpen(false)}>
                        {t("cancel", {ns: "common"})}
                    </Button>
                    <Button color="failure" onClick={submit}>
                        {t("delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}