import {Label, TextInput} from "flowbite-react";
import React from "react";
import {useTranslation} from "react-i18next";

interface ReadInputProps {
    label: string,
    value: string | undefined
}

export default function ReadInput({label, value} : ReadInputProps) {
    const { t } = useTranslation("users");

    return (
        <div>
            <Label
                htmlFor={label}
                value={t(label) ?? ""}
            />
            <TextInput id={label} disabled value={value ?? ""} />
        </div>
    );
}