import {TFunction} from "i18next";
import {parseDate} from "./DateUtil";

export function formatSeconds(seconds: number, t: TFunction) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';

    if (hours > 0) {
        result += hours + 'h';
    }

    if (minutes > 0) {
        if (hours > 0) {
            result += " ";
        }

        if (minutes === 1) {
            result += minutes + " " + t("minute");
        } else {
            result += minutes + " " + t("minutes");
        }
    }

    if (remainingSeconds > 0) {
        if (hours > 0 || minutes > 0) {
            result += ' ' + t("and", "common") + ' ';
        }
        result += remainingSeconds + ' ' + (remainingSeconds === 1 ? t("second", "common") : t("seconds", "common"));
    }

    return result;
}

export function formatSecondsShort(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';

    result += hours + ':';
    result += minutes.toString().padStart(2, '0') + ':';
    result += remainingSeconds.toString().padStart(2, '0');

    return result;
}

export function parseTimeToString(date: Date | undefined) {
    if (!date) {
        return "";
    }
    return parseDate(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
}