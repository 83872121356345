export default interface UserAssertion {
    assertion: Assertion;
    value?: string;
    campusCode?: string;
    startDate: Date;
    endDate: Date;
}

export enum Assertion {
    STUDENT = "STUDENT",
    STAFF = "STAFF",
    TEACHER = "TEACHER"
}

export function findAssertion(assertion: string) {
    return Object.values(Assertion).find(a => a === assertion) || Assertion.STUDENT;
}