import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {HiChevronRight, HiHome} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {getSubjects} from "../api/subjectConnector";
import LoadingComponent from "../../../components/LoadingComponent";
import {Breadcrumb, Button, Table} from "flowbite-react";
import {useNavigate, useParams} from "react-router-dom";
import SubjectManagement from "./SubjectManagement";
import {SubjectLight} from "../domain/Subject";
import SubjectCreateModal from "../components/SubjectModals/SubjectCreateModal";
import {RoleEnum} from "../../../components/authentication/RoleEnum";
import RestrictedAccess from "../../../components/authentication/RestrictedAccess";

interface SubjectsTableProps {
    subjects: SubjectLight[];
    setSubjects: Dispatch<SetStateAction<SubjectLight[]>>
    reloadSubjects: () => void;
}

function SubjectsTable({subjects, setSubjects, reloadSubjects} : SubjectsTableProps) {
    const { t } = useTranslation("enrolmentTests");
    const navigate = useNavigate();

    return <Table className="divide-y">
        <Table.Head>
            <Table.HeadCell>
                {t("subjectLabel")}
            </Table.HeadCell>
            <Table.HeadCell>
                <div className="w-full flex justify-end">
                    <SubjectCreateModal setSubjects={setSubjects} />
                </div>
            </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
            {subjects.length > 0 ? subjects.map((subject) => (
                <Table.Row key={subject.id}>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {subject.name}
                    </Table.Cell>
                    <Table.Cell className="flex justify-end py-2">
                        <Button color="primary" pill onClick={() => navigate("/enrolment-tests/questions-bank/" + (subject.id ?? ""))}>
                            <HiChevronRight className="h-5 w-5"/>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )) : <Table.Row><Table.Cell>{t("noSubjectsYet")}</Table.Cell></Table.Row>}
        </Table.Body>
    </Table>;
}

export default function SubjectsManagement() {
    const [isSubjectsLoading, setIsSubjectsLoading] = useState(true);
    const [selectedSubjectDrawer, setSelectedSubjectDrawer] = useState("");
    const [subjects, setSubjects] = useState<SubjectLight[]>([]);
    const { t } = useTranslation();
    const { throwError } = useAppContext();
    const { subjectId } = useParams();

    const reloadSubjects = useCallback(() => {
        getSubjects().then(subjectsAxiosResponse => {
            setSubjects(subjectsAxiosResponse.data);
            setIsSubjectsLoading(false);
        }).catch(() => {
            throwError(t("getSubjectError"));
        });
    }, [t, throwError]);

    useEffect(() => {
        reloadSubjects();
    }, [reloadSubjects]);

    useEffect(() => {
        setSelectedSubjectDrawer(subjectId ?? "");
    }, [subjectId]);

    return (
        <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS, RoleEnum.STAFF_GM_ACADEMIC]}>
            <div className="mx-4">
                <Breadcrumb className="mx-2 mb-3">
                    <Breadcrumb.Item icon={HiHome}>
                        MyHexagone
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("menu.enrolmentTests")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("menu.enrolmentTests.questionsBank")}</Breadcrumb.Item>
                </Breadcrumb>
                <LoadingComponent isLoading={isSubjectsLoading}>
                    <SubjectsTable subjects={subjects} setSubjects={setSubjects} reloadSubjects={reloadSubjects} />
                </LoadingComponent>
                <SubjectManagement selectedSubjectDrawer={selectedSubjectDrawer} reloadSubjects={reloadSubjects} />
            </div>
        </RestrictedAccess>
    );
}