import React, {Dispatch, SetStateAction, useState} from "react";
import QuestionModal from "./QuestionModal";
import Question from "../../domain/Question";
import {HiPencil} from "react-icons/hi2";

interface QuestionsCreateModalProps {
    setQuestions: Dispatch<SetStateAction<Question[]>>;
    subjectId: string;
    updatableQuestion: Question;
}

export default function QuestionUpdateModal({setQuestions, subjectId, updatableQuestion} : QuestionsCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                onClick={() => setIsOpen(true)}>
                <HiPencil color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <QuestionModal isOpen={isOpen}
                           setIsOpen={setIsOpen}
                           subjectId={subjectId}
                           setQuestions={setQuestions}
                           updatableQuestion={updatableQuestion} />
        </>
    );
}