import React, {useEffect} from "react";

export default function Olvid() {

    useEffect(() => {
        window.location.replace("https://configuration.olvid.io/#eyJzZXJ2ZXIiOiJodHRwczovL3NlcnZlci5vbHZpZC5pbyIsImtleWNsb2FrIjp7InNlcnZlciI6Imh0dHBzOi8vc3NvLW9sdmlkLmVjb2xlLWhleGFnb25lLmNvbS9hdXRoL3JlYWxtcy9vbHZpZC8iLCJzZWNyZXQiOiJVSWg3ZVBZMzlIcUVTWlc3VTlQc1J2S1JIZ0hpNm16aSIsImNpZCI6Im9sdmlkX2NsIn19");
    }, []);

    return (
        <div>
            Redirecting...
        </div>
    );
}