import {AssessmentInstance} from "../../domain/Assessment";
import {TbFolderDown, TbFolderUp} from "react-icons/tb";
import {getMyDutyContent, getUpdateMyDutyResponseLink, performUpdate} from "../../api/dutyConnector";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import React, {ChangeEvent, useState} from "react";
import ShowIfElse from "../../../../components/ConditionalShow/ShowIfElse";
import {Progress, Spinner} from "flowbite-react";
import ShowIf from "../../../../components/ConditionalShow/ShowIf";
import {parseDateToFullString} from "../../../../components/utils/DateUtil";
import {AxiosProgressEvent} from "axios";

interface MyDutyContentProps {
    instance: AssessmentInstance
    reload: () => void;
}

export default function MyDutyContent({ instance, reload }: MyDutyContentProps) {
    const { t } = useTranslation("assessments");
    const [isProcessing, setIsProcessing] = useState(false);
    const [processingPercentage, setProcessingPercentage] = useState(0);
    const { throwError } = useAppContext();

    function handleGetContent() {
        getMyDutyContent(instance.code)
            .then(linkAxiosResponse => {
                window.open(linkAxiosResponse.data, "_blank");
            }).catch(() => throwError(t("getDutyContentError")));
    }

    function handleUploadContent() {
        document.getElementById("fileInput")?.click()
    }

    function handleProgress(progress: AxiosProgressEvent) {
        if (progress.total !== undefined) {
            setProcessingPercentage(Math.round((progress.loaded * 100) / progress.total));
        }
    }

    function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files || !e.target.files[0]) {
            return;
        }
        var file = e.target.files[0];
        setIsProcessing(true);
        getUpdateMyDutyResponseLink(instance.code, e.target.files[0])
            .then((linkAxiosReponse) => {
                performUpdate(linkAxiosReponse.data, file, handleProgress).then(() => {
                    reload();
                    setIsProcessing(false);
                })
            })
            .catch(() => throwError(t("updateMyDutyResponseError")));
    }

    return (
        <div>
            <div className="w-full me-10 bg-orange-200 p-4 rounded-lg">
                <span className="font-bold">{t("warning")} : </span>{t("disclaimerUploadLimit")}
            </div>
            <div className="flex justify-center p-2">
                <div onClick={handleGetContent}
                     className="hover:bg-hexagone-darker-light-grey hover:cursor-pointer p-5 rounded-lg text-center">
                    <TbFolderDown size={150} className="text-hexagone-purple"/>
                    {t("downloadSubject")}
                </div>
            </div>
            <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700"/>
            <div className="flex justify-center p-2">
                <div onClick={handleUploadContent}
                     className="hover:bg-hexagone-darker-light-grey hover:cursor-pointer p-5 rounded-lg text-center">
                    <ShowIfElse condition={!isProcessing} elseShow={
                        <>
                            <Spinner size="xxl"/>
                            <ShowIf condition={processingPercentage > 0}>
                                <Progress progress={processingPercentage} />
                            </ShowIf>
                        </>
                    }>
                        <TbFolderUp size={150} className="text-hexagone-purple"/>
                    </ShowIfElse>
                    <input type="file" id="fileInput" hidden onChange={handleFileChange}/>
                    {t("publishAnswer")}
                </div>
            </div>
            <div className="flex justify-center italic">
                <ShowIf condition={instance.finishedAt !== null}>
                    {t("lastPublishedAt")} {parseDateToFullString(instance.finishedAt)}
                </ShowIf>
            </div>
        </div>
    );
}