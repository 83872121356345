import {CustomFlowbiteTheme, Toast} from "flowbite-react";
import {HiCheck, HiXMark, HiExclamationTriangle, HiInformationCircle} from "react-icons/hi2";

export type Alert = 'success' | 'info' | 'warning' | 'error';

interface ToastLauncherProps {
    toastStatus: boolean,
    toastMessage: string,
    toastVariant: Alert,
    setToastStatus: (status: boolean) => void;
}

export default function ToastLauncher({toastStatus, toastMessage, toastVariant, setToastStatus} : ToastLauncherProps) {

    function getIconByVariant() {
        switch (toastVariant) {
            case "success": return <HiCheck className="h-5 w-5" />
            case "error": return <HiXMark className="h-5 w-5" />
            case "info": return <HiInformationCircle className="h-5 w-5" />
            case "warning": return <HiExclamationTriangle className="h-5 w-5" />
        }
    }

    function getColorByVariant() {
        switch (toastVariant) {
            case "success": return "bg-green-100 text-green-500";
            case "error": return "bg-red-100 text-red-500";
            case "info": return "bg-cyan-100 text-cyan-500";
            case "warning": return "bg-orange-100 text-orange-500";
        }
    }

    const toastTheme: CustomFlowbiteTheme['toast'] = {
        root: {
            base: "z-[51] fixed bottom-2 left-2 flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
        }
    };

    return (
        <>
            {toastStatus && (
                <Toast theme={toastTheme}>
                    <div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${getColorByVariant()} dark:bg-cyan-800 dark:text-cyan-200`}>
                        {getIconByVariant()}
                    </div>
                    <div className="ml-3 text-sm font-normal">{toastMessage}</div>
                    <Toast.Toggle onDismiss={() => setToastStatus(false)} />
                </Toast>
            )}
        </>
    );
}