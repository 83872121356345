export default function LauncherItem({link, image, title, description}: {
    link: string, image: string, title: string, description: string
}) {
    return (
        <a
            href={link}
            className={`items-center rounded-lg border border-transparent px-3 py-2 transition-colors hover:border-gray-300 hover:bg-gray-100 inline-flex sm:block sm:text-center w-full`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                className="relative bg-white rounded-2xl lg:rounded-3xl w-1/6 sm:w-28 sm:mx-auto"
                src={image}
                alt={`${title} Logo`}
            />
            <div className="ms-2 sm:ms-0 sm:m-0 sm:my-2 w-full">
                <h2 className="sm:mb-3 text-2xl font-semibold">
                    {title}&nbsp;
                </h2>
                <p className="m-0 max-w-[30ch] text-sm opacity-50">
                    {description}
                </p>
            </div>
        </a>
    );

}