import React, {ReactNode} from "react";
import {Sidebar} from "flowbite-react";
import ShowIfRoles from "../../../ConditionalShow/ShowIfRoles";
import {useTranslation} from "react-i18next";
import {IconType} from "react-icons/lib";
import {RoleEnum} from "../../../authentication/RoleEnum";

export default function DrawerCategoryRestricted({icon, label, restrictedTo, children}: {
    icon: IconType, label: string, restrictedTo: RoleEnum[], children: ReactNode
}) {
    const { t } = useTranslation();

    return (
        <ShowIfRoles restrictedTo={restrictedTo}>
            <Sidebar.Collapse
                icon={icon}
                label={t("menu." + label) || ""}
            >
                {children}
            </Sidebar.Collapse>
        </ShowIfRoles>
    );
}