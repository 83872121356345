import UsersList from "./pages/UsersList";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import UserManagement from "./pages/UserManagement";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import {RoleEnum} from "../../components/authentication/RoleEnum";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import {useTranslation} from "react-i18next";

export default function UsersManagement() {
    const [selectedUserDrawer, setSelectedUserDrawer] = useState("");
    const { userId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        setSelectedUserDrawer(userId ?? "");
    }, [userId])

    return (
        <div>
            <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS, RoleEnum.STAFF_GM_ACADEMIC,
                RoleEnum.STAFF_GM_QUALITY, RoleEnum.STAFF_GM_MANAGEMENT, RoleEnum.CM_HEAD]}>
                <div className="mx-4">
                    <Breadcrumb className="mx-2 mb-3">
                        <Breadcrumb.Item icon={HiHome}>
                            MyHexagone
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t("menu.users")}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <UsersList />
                <UserManagement selectedUserDrawer={selectedUserDrawer} />
            </RestrictedAccess>
        </div>
    );
}