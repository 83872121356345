export interface Campus {
    code: string,
    city: string
    country: string,
}

export const VERSAILLES = {
    code: "VER",
    country: "FR",
    city: "Versailles"
} as Campus;

export const CLERMONT_FERRAND = {
    code: "CLE",
    country: "FR",
    city: "Clermont-Ferrand"
} as Campus;

export const TUNIS = {
    code: "TUN",
    country: "TN",
    city: "Tunis"
} as Campus;

export const campuses = [VERSAILLES, CLERMONT_FERRAND, TUNIS] as Campus[]

export function getCampusByCode(code: string) {
    return campuses.find(c => c.code === code);
}

export function getCampusCityByCode(code: string) {
    const campus = getCampusByCode(code);
    return campus !== undefined ? campus.city : "";
}