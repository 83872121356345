import React, {Dispatch, SetStateAction, useState} from "react";
import Answer from "../../domain/Answer";
import AnswerModal from "./AnswerModal";
import {HiPencil} from "react-icons/hi2";

interface AnswerUpdateModalProps {
    subjectId: string;
    questionId: number;
    setAnswers: Dispatch<SetStateAction<Answer[]>>;
    updatableAnswer: Answer;
}

export default function AnswerUpdateModal({subjectId, questionId, setAnswers, updatableAnswer} : AnswerUpdateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full" onClick={() => setIsOpen(true)}>
                <HiPencil color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <AnswerModal isOpen={isOpen}
                         setIsOpen={setIsOpen}
                         subjectId={subjectId}
                         questionId={questionId}
                         setAnswers={setAnswers}
                         updatableAnswer={updatableAnswer} />
        </>
    );
}