import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import {RoleEnum} from "../../components/authentication/RoleEnum";
import SubjectsManagement from "./parts/SubjectsManagement";

export default function Subjects() {

    return (
        <RestrictedAccess authorizedRoles={[
            RoleEnum.STAFF_GM_IS,
            RoleEnum.STAFF_GM_ACADEMIC,
            RoleEnum.STAFF_GM_QUALITY,
            RoleEnum.STAFF_GM_MANAGEMENT
            ]}>
            <SubjectsManagement />
        </RestrictedAccess>
    );

}