import AbstractDrawer from "../../../components/AbstractDrawer";
import {useNavigate} from "react-router-dom";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {HiBeaker} from "react-icons/hi";
import LoadingComponent from "../../../components/LoadingComponent";
import Quiz, {QuizInstruction, QuizLight} from "../domain/Quiz";
import {getQuiz} from "../api/quizConnector";
import InstructionCard from "../components/InstructionCard";
import QuizUpdateModal from "../components/QuizModals/QuizUpdateModal";
import QuizDeleteModal from "../components/QuizModals/QuizDeleteModal";
import InstructionCreateModal from "../components/InstructionModals/InstructionCreateModal";

interface QuizManagementProps {
    selectedQuizDrawer: string;
    setQuizzes: Dispatch<SetStateAction<QuizLight[]>>;
    reloadQuizzes: () => void;
}

export default function QuizManagement({selectedQuizDrawer, setQuizzes, reloadQuizzes} : QuizManagementProps) {
    const navigate = useNavigate();
    const { t } = useTranslation("enrolmentTests");
    const [quiz, setQuiz] = useState<Quiz>();
    const [instructions, setInstructions] = useState<QuizInstruction[]>([]);
    const [isQuizLoading, setIsQuizLoading] = useState(true);
    const {throwError} = useAppContext();

    const reloadQuiz = useCallback((quizId: string) => {
        if (quizId !== "") {
            setIsQuizLoading(true);
            getQuiz(quizId)
                .then((quizAxiosResponse) => {
                    setIsQuizLoading(false);
                    setQuiz(quizAxiosResponse.data);
                })
                .catch(() => throwError(t("getQuizError")));
        }
    }, [throwError, t]);

    useEffect(() => {
        reloadQuiz(selectedQuizDrawer);
    }, [reloadQuiz, selectedQuizDrawer]);

    useEffect(() => {
        if (quiz) {
            setInstructions(quiz.instructions || []);
        }
    }, [quiz]);

    return (
        <AbstractDrawer shadow side="end" size="3xl" isOpen={selectedQuizDrawer !== ""}
                        setIsOpen={() => navigate("/enrolment-tests/quizzes")}>
            <LoadingComponent isLoading={isQuizLoading}>
                <>
                    <div className="items-center">
                        <div className="flex justify-between items-center">
                            <div className="inline-flex">
                                <HiBeaker size="40"/>&nbsp;
                                <h2 className="text-3xl">{quiz?.title}</h2>
                            </div>
                            <InstructionCreateModal quizId={selectedQuizDrawer} setQuiz={setQuiz} instructions={instructions} />
                            <div className="flex items-center space-x-1">
                                <QuizUpdateModal updatableQuiz={quiz} setUpdatableQuiz={setQuiz} setQuizzes={setQuizzes} />
                                <QuizDeleteModal quizId={selectedQuizDrawer} reloadQuizzes={reloadQuizzes} />
                            </div>
                        </div>
                        {instructions !== undefined && instructions.length > 0 ? instructions.map(instruction =>
                            <InstructionCard key={instruction.subject.id} instruction={instruction} setInstructions={setInstructions} quizId={selectedQuizDrawer} />
                        ) : <span className="mt-4">{t("noInstructionsYet")}</span>}
                    </div>
                </>
            </LoadingComponent>
        </AbstractDrawer>
    )
}