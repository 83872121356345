import {Button, Modal} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import WriteInput from "../../../../components/Inputs/WriteInput";
import {createSubject, updateSubject} from "../../api/subjectConnector";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import Subject, {SubjectLight} from "../../domain/Subject";

interface SubjectModalProps {
    updatableSubject?: Subject;
    setUpdatableSubject?: Dispatch<SetStateAction<Subject | undefined>>;
    setSubjects?: Dispatch<SetStateAction<SubjectLight[]>>
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    reloadSubjects?: () => void;
}

export default function SubjectModal({updatableSubject, setUpdatableSubject, setSubjects, isOpen, setIsOpen, reloadSubjects} : SubjectModalProps) {
    const {t} = useTranslation("enrolmentTests");
    const [label, setLabel] = useState("");
    const {throwError} = useAppContext();

    useEffect(() => {
        if (updatableSubject !== undefined) {
            setLabel(updatableSubject.name);
        } else {
            setLabel("");
        }
    }, [updatableSubject]);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (updatableSubject) {
            updateSubject(updatableSubject.id, {
                name: label
            })
                .then(subjectAxiosResponse => {
                    setIsOpen(false);
                    if (reloadSubjects) {
                        reloadSubjects();
                    }
                    return setUpdatableSubject ? setUpdatableSubject(subjectAxiosResponse.data) : "";
                })
                .catch(() => throwError(t("updateSubjectError")));
        } else {
            createSubject({
                name: label
            })
                .then(subjectAxiosResponse => {
                    setIsOpen(false);
                    return setSubjects ? setSubjects(prevState => prevState.concat(subjectAxiosResponse.data)) : "";
                })
                .catch(() => throwError(t("createSubjectError")));
        }
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                {updatableSubject ? t("updateSubject") : t("createSubject")}
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submit} id="subjectForm">
                    <WriteInput value={label} setter={setLabel} label={"subjectLabel"} t={t} required placeholder="Maths" />
                </form>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="subjectForm">
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}