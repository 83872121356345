import React, {Dispatch, SetStateAction, useState} from "react";
import Subject from "../../domain/Subject";
import SubjectModal from "./SubjectModal";
import {HiPencil} from "react-icons/hi2";

interface SubjectUpdateModalProps {
    updatableSubject: Subject | undefined;
    setUpdatableSubject?: Dispatch<SetStateAction<Subject | undefined>>;
    reloadSubjects: () => void;
}

export default function SubjectUpdateModal({updatableSubject, setUpdatableSubject, reloadSubjects} : SubjectUpdateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button className="p-2 hover:bg-hexagone-darker-light-grey active:hover:bg-hexagone-purple rounded-full" onClick={() => setIsOpen(true)}>
                <HiPencil size="20" className="text-hexagone-purple active:text-hexagone-light-grey"/>
            </button>
            <SubjectModal isOpen={isOpen} setIsOpen={setIsOpen} setUpdatableSubject={setUpdatableSubject} updatableSubject={updatableSubject} reloadSubjects={reloadSubjects} />
        </>
    );
}