import {Breadcrumb, Button, Card, List, ListItem, Tooltip} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import LoadingComponent from "../../../components/LoadingComponent";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {getSubjects} from "../api/subjectConnector";
import {SubjectLight} from "../domain/Subject";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {HiPlus, HiWrenchScrewdriver} from "react-icons/hi2";
import SearchBar from "../../../components/SearchBar";
import SubjectCreateModal from "../components/SubjectModals/Modals/SubjectCreateModal";
import {useNavigate, useParams} from "react-router-dom";
import SubjectManagement from "./SubjectManagement";
import AssessmentCreateModal from "../components/AssessmentModals/AssessmentCreateModal";
import SubjectTitle from "../components/SubjectModals/SubjectTitle";
import ShowIf from "../../../components/ConditionalShow/ShowIf";
import {RoleEnum} from "../../../components/authentication/RoleEnum";

export default function SubjectsManagement() {
    const [loading, setLoading] = React.useState(true);
    const {t} = useTranslation("assessments");
    const [subjectsMap, setSubjectsMap] = React.useState<Map<string, SubjectLight[]>>(new Map());
    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const {throwError, roles} = useAppContext();
    const {subjectId} = useParams();
    const navigate = useNavigate();

    const reloadSubjects = useCallback(() => {
        setLoading(true);
        getSubjects().then((subjectsAxiosResponse) => {
            setLoading(false);
            setSubjectsMap(new Map(Object.entries(subjectsAxiosResponse.data)));
        }).catch(() => throwError("getSubjectsError"));
    }, [throwError]);

    useEffect(() => {
        reloadSubjects();
    }, [reloadSubjects]);

    return (
        <div className="mx-4">
            <Breadcrumb className="mx-2 mb-3">
                <Breadcrumb.Item icon={HiHome}>
                    MyHexagone
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.assessments", {ns: "common"})}</Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.assessments.subjects", {ns: "common"})}</Breadcrumb.Item>
            </Breadcrumb>
            <LoadingComponent isLoading={loading}>
                <div className="flex">
                    <Button onClick={() => setCreateModalOpen(true)} color="success">
                        <HiPlus className="h-5 w-5"/>
                    </Button>
                    <SearchBar submit={() => {
                    }} placeholder={t("searchSubjectByTitleOrECTS")}/>
                </div>
                <List unstyled>
                    {Array.from(subjectsMap.entries()).map(([ectsCode, subjects]) => (
                        <ListItem key={ectsCode}>
                            <div className="flex gap-2">
                                <h3 className="font-bold text-xl my-4">{ectsCode}</h3>
                                {/*<button
                                    className="flex p-2 h-fit text-hexagone-light-grey self-center bg-green-700 hover:bg-green-800 rounded-full">
                                    <HiPlus size="15"/>
                                </button>*/}
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                {subjects.map((subject) => (
                                    <Card key={subject.id}>
                                        <div className="flex justify-between">
                                            <div>
                                                <SubjectTitle variant="h4" title={subject?.title}
                                                              isPublished={subject?.isPublished}/>
                                                <span
                                                    className="rounded-3xl border-2 p-1.5 italic">{t(subject.type)}</span>
                                            </div>
                                            <div>
                                                <AssessmentCreateModal subjectId={subject.id.toString()}/>
                                                <ShowIf
                                                    condition={roles.includes(RoleEnum.STAFF_GM_ACADEMIC) || !subject.isPublished}>
                                                    <Tooltip content={t("edit")}>
                                                        <Button className="" color="primary" pill
                                                                onClick={() => navigate(`/assessments/subjects/${subject.id}`)}>
                                                            <HiWrenchScrewdriver className="h-8 w-8"/>
                                                        </Button>
                                                    </Tooltip>
                                                </ShowIf>
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </ListItem>
                    ))}
                </List>
            </LoadingComponent>
            <SubjectCreateModal isOpen={createModalOpen} setIsOpen={setCreateModalOpen}
                                reloadSubjects={reloadSubjects}/>
            <SubjectManagement selectedSubject={subjectId || ""}/>
        </div>
    );
}