import {ReactElement, ReactNode} from "react";
import {CustomFlowbiteTheme, Dropdown} from "flowbite-react";

export default function AbstractDropdown({button, content, header, footer, className, color, arrowIcon, renderTrigger}: {
    button?: ReactNode,
    content: ReactNode,
    header?: ReactNode,
    footer?: ReactNode,
    className?: string,
    color?: "primary" | "secondary" | "light",
    arrowIcon?: boolean,
    renderTrigger?: () => ReactElement
}) {

    const dropdownTheme: CustomFlowbiteTheme['dropdown'] = {
        inlineWrapper: "flew items-center"
    };

    return (
        <Dropdown className={className} label={button} arrowIcon={arrowIcon ?? false} theme={dropdownTheme} renderTrigger={renderTrigger} color={color ?? "primary"}>
            {header && (
                <>
                    <Dropdown.Item>
                        {header}
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                </>
            )}
            {content}
            {footer && (
                <>
                    <Dropdown.Divider/>
                    <Dropdown.Item>
                        {footer}
                    </Dropdown.Item>
                </>
            )}

        </Dropdown>


    );
}