import MDEditor, {MDEditorProps} from "@uiw/react-md-editor";
import React from "react";
import {Label} from "flowbite-react";
import {TFunction} from "i18next";

interface MarkdownEditorProps extends MDEditorProps {
    label: string;
    t: TFunction;
}

export default function MarkdownEditor({label, t, ...props}: MarkdownEditorProps) {

    return (
        <>
            <Label htmlFor={label} value={t(label)} />
            <MDEditor id={label} {...props} />
        </>

    );
}