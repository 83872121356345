import axios, {AxiosResponse} from "axios";
import Quiz from "../domain/Quiz";

export function getQuiz(quizId: any): Promise<AxiosResponse<Quiz>> {
    const url = window._env_.ENROLMENT_API_URL + `quizzes/${quizId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getQuizzes(): Promise<AxiosResponse<Quiz[]>> {
    const url = window._env_.ENROLMENT_API_URL + `quizzes`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function createQuiz(body: any): Promise<AxiosResponse<Quiz>> {
    const url = window._env_.ENROLMENT_API_URL + `quizzes`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateQuiz(quizId: any, body: any): Promise<AxiosResponse<Quiz>> {
    const url = window._env_.ENROLMENT_API_URL + `quizzes/${quizId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}

export function addInstruction(quizId: any, body: any): Promise<AxiosResponse<Quiz>> {
    const url = window._env_.ENROLMENT_API_URL + `quizzes/${quizId}/instructions`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.put(url, body, config);
}

export function deleteInstruction(quizId: any, subjectId: any) {
    const url = window._env_.ENROLMENT_API_URL + `quizzes/${quizId}/instructions/subject/${subjectId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}

export function deleteQuiz(quizId: any) {
    const url = window._env_.ENROLMENT_API_URL + `quizzes/${quizId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}