import {ApplicationCategory} from "../../domain/Application";
import {useTranslation} from "react-i18next";

export default function LauncherCategoryTab({category, isActive, setActive}: {
    category: ApplicationCategory, isActive: boolean, setActive: () => void
}) {
    const {t} = useTranslation("dashboard");

    return (
        <li className="mr-2">
            <button onClick={setActive}
                    className={`inline-block px-4 py-3 rounded-lg ${isActive ? "text-white bg-hexagone-purple active" : "hover:text-hexagone-grey hover:bg-grey-100 dark:hover:bg-grey-800 dark:hover:text-white"}`}
                    aria-current="page">{t("category." + category.name)}
            </button>
        </li>
    );
}