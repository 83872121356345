import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import Question from "../../domain/Question";
import {Button, Modal} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import {useTranslation} from "react-i18next";
import {QuestionTypeEnum} from "../../domain/QuestionTypeEnum";
import QuestionForm from "./QuestionForm";

interface QuestionModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setQuestions?: Dispatch<SetStateAction<Question[]>>;
    updatableQuestion?: Question;
    handleUpdate?: (updatableQuestion: Question, type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) => void;
    handleCreate?: (type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) => void;
}

export default function QuestionModal({isOpen, setIsOpen, setQuestions, updatableQuestion, handleCreate, handleUpdate}: QuestionModalProps) {
    const {t} = useTranslation("assessments");
    const [type, setType] = useState(QuestionTypeEnum.CHECK_ONE.valueOf());
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (updatableQuestion) {
            setTime(updatableQuestion.time);
            setTitle(updatableQuestion.title);
            setDescription(updatableQuestion.description);
            setType(updatableQuestion.type);
        }
    }, [updatableQuestion]);

    function handleClose() {
        setIsOpen(false);
        setType(QuestionTypeEnum.CHECK_ONE.valueOf());
        setTitle("");
        setDescription("");
        setTime(0);
    }

    function handleSubmit() {
        if (updatableQuestion && handleUpdate) {
            handleUpdate(updatableQuestion, type, title, description, time, handleClose, setQuestions);
        } else {
            if (handleCreate) {
                handleCreate(type, title, description, time, handleClose, setQuestions);
            }
        }
    }

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        handleSubmit();
    }

    return (
        <Modal show={isOpen} onClose={() => handleClose()}>
            <Modal.Header>
                {updatableQuestion ? t("updateQuestion") : t("createQuestion")}
            </Modal.Header>
            <Modal.Body>
                <QuestionForm type={type} setType={setType} description={description} setDescription={setDescription}
                              title={title} setTitle={setTitle} time={time} setTime={setTime} submit={submit}/>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="questionForm">
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );

}