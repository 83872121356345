import React, {Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState} from "react";
import {Button, Label, Modal} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import {useTranslation} from "react-i18next";
import LoadingComponent from "../../../../components/LoadingComponent";
import Promotion from "../../domain/Promotion";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {getPromotions} from "../../api/promotionConnector";
import Select from "react-select";
import {createAssessment} from "../../api/assessmentConnector";
import WriteInput from "../../../../components/Inputs/WriteInput";
import DoubleColumnGrid from "../../../../components/DoubleColumnGrid";

interface AssessmentModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    subjectId: string;
}

export default function AssessmentModal({isOpen, setIsOpen, subjectId}: AssessmentModalProps) {
    const {t} = useTranslation("assessments");
    const { throwError } = useAppContext();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSelectLoading, setIsSelectLoading] = useState(true);
    const [promotions, setPromotions] = useState<Promotion[]>([]);
    const [selectedPromotion, setSelectedPromotion] = useState<string>("");
    const [accessibilityStartDate, setAccessibilityStartDate] = useState('');
    const [accessibilityStartTime, setAccessibilityStartTime] = useState('');
    const [accessibilityEndDate, setAccessibilityEndDate] = useState('');
    const [accessibilityEndTime, setAccessibilityEndTime] = useState('');

    function submit(event: FormEvent<HTMLFormElement>) {
        setIsProcessing(true);
        const body = {
            subjectId: subjectId,
            promotionCode: selectedPromotion,
            startDate: new Date(accessibilityStartDate + "T" + accessibilityStartTime).toISOString(),
            endDate: new Date(accessibilityEndDate + "T" + accessibilityEndTime).toISOString()
        };
        createAssessment(body)
            .then(() => {
                setIsProcessing(false);
                setIsOpen(false);
            })
            .catch(() => {
                setIsProcessing(false);
                throwError(t("createAssessmentError"));
            })
        event.preventDefault();
    }

    function mapToValueLabel(promotion: Promotion) {
        return {
            value: promotion.code,
            label: promotion.label
        };
    }

    const promotionsOptions = promotions.map(mapToValueLabel)
        .sort((a, b) => a.label < b.label ? 1 : (a.label > b.label ? -1 : 0));

    const reloadPromotions = useCallback((subjectId: string) => {
        if (subjectId !== "") {
            setIsSelectLoading(true);
            getPromotions()
                .then((promotionsAxiosResponse) => {
                    setIsSelectLoading(false);
                    setPromotions(promotionsAxiosResponse.data);
                })
                .catch(() => throwError(t("getPromotionsError")));
        }
    }, [throwError, t]);

    useEffect(() => {
        reloadPromotions(subjectId);
    }, [reloadPromotions, subjectId]);

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                {t("createAssessment")}
            </Modal.Header>
            <Modal.Body className="overflow-visible">
                <form onSubmit={submit} id="assessmentForm">
                    <LoadingComponent isLoading={isSelectLoading}>
                        <>
                            <Label htmlFor="promotions" value={t("promotions") + "*"} />
                            <Select options={promotionsOptions}
                                    required
                                    placeholder={t("selectPromotions")}
                                    noOptionsMessage={() => t("noPromotionsAvailable")}
                                    onChange={(choices) => setSelectedPromotion(choices?.value ?? "")} />
                            <DoubleColumnGrid first={
                                <WriteInput label="accessibilityStartDate" type="date" value={accessibilityStartDate} setter={setAccessibilityStartDate} required t={t} />
                            } second={
                                <WriteInput label="accessibilityStartTime" type="time" value={accessibilityStartTime} setter={setAccessibilityStartTime} required t={t} />
                            } />
                            <DoubleColumnGrid first={
                                <WriteInput label="accessibilityEndDate" type="date" value={accessibilityEndDate} setter={setAccessibilityEndDate} required t={t} />
                            } second={
                                <WriteInput label="accessibilityEndTime" type="time" value={accessibilityEndTime} setter={setAccessibilityEndTime} required t={t} />
                            } />
                        </>
                    </LoadingComponent>
                </form>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="assessmentForm" isProcessing={isProcessing}>
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );

}