import {Button, Modal} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import WriteInput from "../../../../components/Inputs/WriteInput";
import {createQuestionBank, updateQuestionBank} from "../../api/questionBankConnector";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import QuestionBank, {QuestionBankLight} from "../../domain/QuestionBank";

interface QuestionBankModalProps {
    updatableQuestionBank?: QuestionBank;
    setUpdatableQuestionBank?: Dispatch<SetStateAction<QuestionBank | undefined>>;
    setQuestionBanks?: Dispatch<SetStateAction<QuestionBankLight[]>>
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    reloadQuestionBanks?: () => void;
}

export default function QuestionBankModal({updatableQuestionBank, setUpdatableQuestionBank, setQuestionBanks, isOpen, setIsOpen, reloadQuestionBanks} : QuestionBankModalProps) {
    const {t} = useTranslation("assessments");
    const [label, setLabel] = useState("");
    const [ectsCode, setEctsCode] = useState("");
    const {throwError} = useAppContext();

    useEffect(() => {
        if (updatableQuestionBank !== undefined) {
            setLabel(updatableQuestionBank.name);
            setEctsCode(updatableQuestionBank.ectsCode);
        } else {
            setLabel("");
            setEctsCode("");
        }
    }, [updatableQuestionBank]);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (updatableQuestionBank) {
            updateQuestionBank(updatableQuestionBank.id, {
                name: label,
                ectsCode
            })
                .then(questionBankAxiosResponse => {
                    setIsOpen(false);
                    if (reloadQuestionBanks) {
                        reloadQuestionBanks();
                    }
                    return setUpdatableQuestionBank ? setUpdatableQuestionBank(questionBankAxiosResponse.data) : "";
                })
                .catch(() => throwError(t("updateQuestionBankError")));
        } else {
            createQuestionBank({
                name: label,
                ectsCode
            })
                .then(questionBankAxiosResponse => {
                    setIsOpen(false);
                    return setQuestionBanks ? setQuestionBanks(prevState => prevState.concat(questionBankAxiosResponse.data)) : "";
                })
                .catch(() => throwError(t("createQuestionBankError")));
        }
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                {updatableQuestionBank ? t("updateQuestionBank") : t("createQuestionBank")}
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submit} id="questionBankForm">
                    <WriteInput value={label} setter={setLabel} label={"questionBankLabel"} t={t} required placeholder="Maths" />
                    <WriteInput value={ectsCode} setter={setEctsCode} label={"ectsCode"} t={t} required placeholder="1ABCD" />
                </form>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="questionBankForm">
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}