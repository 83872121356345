import {Card} from "flowbite-react";
import React, {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {QuizInstruction} from "../domain/Quiz";
import InstructionDeleteModal from "./InstructionModals/InstructionDeleteModal";
import ShowIf from "../../../components/ConditionalShow/ShowIf";

interface InstructionCardProps {
    instruction: QuizInstruction;
    setInstructions: Dispatch<SetStateAction<QuizInstruction[]>>;
    quizId: string;
    canBeUpdated: boolean;
}

export default function InstructionCard({instruction, setInstructions, quizId, canBeUpdated} : InstructionCardProps) {
    const { t } = useTranslation("assessments");

    return (
        <Card key={instruction.questionBank.id} className="my-4">
            <div className="flex justify-between">
                <div className="flex">
                    <h5 className="font-bold text-2xl">{instruction.questionBank.name}&nbsp;</h5>
                </div>
                <div className="space-x-1">
                    <ShowIf condition={canBeUpdated}>
                        <InstructionDeleteModal quizId={quizId} questionBankId={instruction.questionBank.id} setInstructions={setInstructions} />
                    </ShowIf>
                </div>
            </div>
            <div className="flex">
                <span className="rounded-3xl border-2 p-1">{instruction.quantity} {instruction.quantity > 1 ? t("questions") : t("question")}</span>
            </div>
        </Card>
    );
}