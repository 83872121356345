import React from "react";

export default function AbstractDrawer({children, isOpen, setIsOpen, side, size, shadow}: {
    children: React.ReactNode,
    isOpen: boolean,
    setIsOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void
    side?: "start" | "end",
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl",
    shadow?: boolean
}) {

    function getTranslationDirection() {
        if (isOpen) {
            if (side === "start") {
                return "-translate-x-0 rtl:translate-x-0";
            } else {
                return "translate-x-0 rtl:-translate-x-0";
            }
        } else {
            if (side === "start") {
                return "-translate-x-full rtl:translate-x-full";
            } else {
                return "translate-x-full rtl:-translate-x-full";
            }
        }
    }

    return (
        <main
            className={
                `fixed overflow-hidden z-10 bg-opacity-20 inset-0 ease-in-out transition-all delay-500 ` +
                (isOpen ? "transition-opacity duration-500 " : "transition-all ") +
                getTranslationDirection()
            }
        >
            <div className={`z-20 w-screen max-w-${size ?? "lg"} ${side === "start" ? "start-0" : "end-0"} absolute bg-hexagone-light-grey h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform ${getTranslationDirection()}`}>
                <div
                    className={`w-screen max-w-full pb-10 flex flex-col overflow-y-auto space-y-6 h-full p-4 `}>
                    {children}
                </div>
            </div>
            <section
                className={`w-screen h-full cursor-pointer ${shadow && `${isOpen ? "opacity-20" : "opacity-0"} bg-black duration-500`}`}
                onClick={() => {
                    setIsOpen(false);
                }}
            ></section>
        </main>
    );
}