import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {HiChevronRight, HiHome} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../components/AppContext/AppContext";
import LoadingComponent from "../../../components/LoadingComponent";
import {Breadcrumb, Button, Table} from "flowbite-react";
import {useNavigate, useParams} from "react-router-dom";
import {QuizLight} from "../domain/Quiz";
import {getQuizzes} from "../api/quizConnector";
import QuizManagement from "./QuizManagement";
import QuizCreateModal from "../components/QuizModals/QuizCreateModal";

interface QuizzesTableProps {
    quizzes: QuizLight[];
    setQuizzes: Dispatch<SetStateAction<QuizLight[]>>
    reloadQuizzes: () => void;
}

function QuizzesTable({quizzes, setQuizzes, reloadQuizzes} : QuizzesTableProps) {
    const { t } = useTranslation("enrolmentTests");
    const navigate = useNavigate();

    return <Table className="divide-y">
        <Table.Head>
            <Table.HeadCell>
                {t("quizTitle")}
            </Table.HeadCell>
            <Table.HeadCell>
                {t("time")}
            </Table.HeadCell>
            <Table.HeadCell>
                <div className="w-full flex justify-end">
                    <QuizCreateModal setQuizzes={setQuizzes} />
                </div>
            </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
            {quizzes.length > 0 ? quizzes.map((quiz) => (
                <Table.Row key={quiz.id}>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {quiz.title}
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap text-gray-900 dark:text-white">
                        {quiz.time}
                    </Table.Cell>
                    <Table.Cell className="flex justify-end py-2">
                        <Button color="primary" pill onClick={() => navigate("/enrolment-tests/quizzes/" + (quiz.id ?? ""))}>
                            <HiChevronRight className="h-5 w-5"/>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )) : <Table.Row><Table.Cell>{t("noSubjectsYet")}</Table.Cell></Table.Row>}
        </Table.Body>
    </Table>;
}

export default function QuizzesManagement() {
    const [isQuizzesLoading, setIsQuizzesLoading] = useState(true);
    const [selectedQuizDrawer, setSelectedQuizDrawer] = useState("");
    const [quizzes, setQuizzes] = useState<QuizLight[]>([]);
    const { t } = useTranslation();
    const { throwError } = useAppContext();
    const { quizId } = useParams();

    const reloadQuizzes = useCallback(() => {
        getQuizzes().then(quizzesAxiosResponse => {
            setQuizzes(quizzesAxiosResponse.data);
            setIsQuizzesLoading(false);
        }).catch(() => {
            throwError(t("getQuizzesError"));
        });
    }, [t, throwError]);

    useEffect(() => {
        reloadQuizzes();
    }, [reloadQuizzes]);

    useEffect(() => {
        setSelectedQuizDrawer(quizId ?? "");
    }, [quizId]);

    return (
        <div className="mx-4">
            <Breadcrumb className="mx-2 mb-3">
                <Breadcrumb.Item icon={HiHome}>
                    MyHexagone
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.enrolmentTests")}</Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.enrolmentTests.quizzes")}</Breadcrumb.Item>
            </Breadcrumb>
            <LoadingComponent isLoading={isQuizzesLoading}>
                <QuizzesTable quizzes={quizzes} setQuizzes={setQuizzes} reloadQuizzes={reloadQuizzes} />
            </LoadingComponent>
            <QuizManagement selectedQuizDrawer={selectedQuizDrawer} setQuizzes={setQuizzes} reloadQuizzes={reloadQuizzes} />
        </div>
    );
}