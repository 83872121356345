import React from "react";
import {useTranslation} from "react-i18next";
import WriteInput from "../../../../../components/Inputs/WriteInput";
import {Card, Label} from "flowbite-react";
import {SubjectTypeEnum} from "../../../domain/Subject";
import {MdConstruction, MdOutlineQuiz} from "react-icons/md";
import {BiSolidTrafficCone} from "react-icons/bi";
import ShowIf from "../../../../../components/ConditionalShow/ShowIf";

interface SubjectCreateFormParams {
    submit: (e: React.FormEvent<HTMLFormElement>) => void;
    title: string;
    setTitle: (value: (((prevState: string) => string) | string)) => void;
    ectsCode: string;
    setEctsCode: (value: (((prevState: string) => string) | string)) => void;
    subjectType: string;
    setSubjectType: (value: (((prevState: string) => string) | string)) => void;
    update?: boolean;
}

function getSubjectTypeIcon(type: string) {
    switch (type) {
        case SubjectTypeEnum.DUTY.toString(): return <MdConstruction size={40} />;
        case SubjectTypeEnum.QUIZ.toString(): return <MdOutlineQuiz size={40} />;
        default: return <BiSolidTrafficCone size={40} />;
    }
}

function isSubjectTypeDisabled(type: string) {
    switch (type) {
        case SubjectTypeEnum.DUTY.toString(): return false;
        case SubjectTypeEnum.QUIZ.toString(): return false;
        default: return true;
    }
}

export default function SubjectForm({submit, title, setTitle, ectsCode, setEctsCode, subjectType, setSubjectType, update}: SubjectCreateFormParams) {
    const { t } = useTranslation("assessments");

    return <form onSubmit={submit} id="subjectForm">
        <WriteInput value={title} setter={setTitle} label={"subjectTitle"} t={t} required/>
        <WriteInput value={ectsCode} setter={setEctsCode} label={"ectsCode"} t={t} disabled={update} required placeholder="2024-1ABCD"/>
        <ShowIf condition={!update}>
            <Label htmlFor="subjectType" value={t("subjectType") + "*"}/>
            <div id="subjectType" className="grid grid-cols-2 gap-2">
                {(Object.values(SubjectTypeEnum) as Array<keyof typeof SubjectTypeEnum>)
                    .map(type => (
                        <Card
                            className={`${type === subjectType && "bg-green-700 text-hexagone-light-grey"} ${isSubjectTypeDisabled(type) && "cursor-not-allowed bg-hexagone-darker-light-grey"}`}
                            key={type} onClick={() => !isSubjectTypeDisabled(type) && setSubjectType(type)}>
                            <div className="m-auto">
                                <div className="flex justify-center">{getSubjectTypeIcon(type)}</div>
                                <div>{t(type)}</div>
                            </div>
                        </Card>
                    ))}
            </div>
        </ShowIf>
    </form>;
}