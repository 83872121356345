import React from "react";

interface ShowIfNotEmptyProps {
    value: any | undefined,
    children: React.ReactNode
}

export default function ShowIfNotEmpty({value, children} : ShowIfNotEmptyProps) {
    return (
        <>
            {value ? children : ""}
        </>
    );
}