import React from "react";
import {RoleEnum} from "../authentication/RoleEnum";
import {useAppContext} from "../AppContext/AppContext";

interface ShowIfRolesProps {
    children: React.ReactNode,
    restrictedTo: RoleEnum[],
    elseElement?: React.ReactNode
}

export default function ShowIfRoles({restrictedTo, children, elseElement} : ShowIfRolesProps) {
    const {roles} = useAppContext();
    const restrictedToString = restrictedTo.map(a => a.toString());

    return <>{roles.some(e => restrictedToString.includes(e)) ? children : elseElement || ""}</>
}