import {FileInput} from "flowbite-react";
import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import Subject, {SubjectLight} from "../../domain/Subject";
import {getUpdateDutyContentLink, performUpdate} from "../../api/dutyConnector";

interface DutyFormParams {
    submit: (params: {event: React.FormEvent<HTMLFormElement>, postAction?: (subject: SubjectLight) => Promise<Subject | void>}) => void;
    reload: () => void;
}

export default function DutyForm({submit, reload} : Readonly<DutyFormParams>) {
    const { t } = useTranslation("assessments");
    const [file, setFile] = useState<File>();

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        submit({event: e, postAction: handleSubmitContent});
    }

    function handleSubmitContent(subject: SubjectLight): Promise<Subject | void> {
        if (file) {
            return getUpdateDutyContentLink(subject.id, file)
                .then((linkAxiosResponse) => {
                    setFile(undefined);
                    performUpdate(linkAxiosResponse.data, file).then(() => {
                        reload();
                    })
                })
        }
        return Promise.resolve();
    }

    function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);
    }

    return (
        <form onSubmit={handleSubmit} id="subjectContentForm">
            <FileInput id="file" helperText={t("dutyFileHelperText")} onChange={handleFileChange} />
        </form>
    );
}