import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import Question from "../../domain/Question";
import {Button, Modal} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import {useTranslation} from "react-i18next";
import {createQuestion, updateQuestion} from "../../api/questionConnector";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import QuestionTypeSelect from "./QuestionTypeSelect";
import {QuestionTypeEnum} from "../../domain/QuestionTypeEnum";
import WriteInput from "../../../../components/Inputs/WriteInput";
import MarkdownEditor from "../../../../components/Markdown/MarkdownEditor";

interface QuestionModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    subjectId: string;
    setQuestions?: Dispatch<SetStateAction<Question[]>>;
    updatableQuestion?: Question;
}

export default function QuestionModal({isOpen, setIsOpen, subjectId, setQuestions, updatableQuestion}: QuestionModalProps) {
    const {t} = useTranslation("enrolmentTests");
    const {throwError} = useAppContext();
    const [type, setType] = useState(QuestionTypeEnum.CHECK_ONE.valueOf());
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (updatableQuestion) {
            setTime(updatableQuestion.time);
            setTitle(updatableQuestion.title);
            setDescription(updatableQuestion.description);
            setType(updatableQuestion.type);
        }
    }, [updatableQuestion]);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (updatableQuestion) {
            updateQuestion(subjectId, updatableQuestion.id, {
                type,
                title,
                description,
                time
            }).then(questionAxiosResponse => {
                setIsOpen(false);
                if (setQuestions) {
                    setQuestions(prevState => {
                        let newState = [...prevState];
                        const questionIndex = newState.findIndex(q => q.id === questionAxiosResponse.data.id);

                        if (questionIndex !== -1) {
                            newState[questionIndex] = {...newState[questionIndex], ...questionAxiosResponse.data};
                        }
                        return newState;
                    })
                }
            }).catch(() => throwError(t("updateQuestionError")));
        } else {
            createQuestion(subjectId, {
                type,
                title,
                description,
                time
            }).then(questionAxiosResponse => {
                setIsOpen(false);
                if (setQuestions) {
                    setQuestions(prevState => prevState.concat(questionAxiosResponse.data));
                }
            }).catch(() => throwError(t("createQuestionError")));
        }
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                {updatableQuestion ? t("updateQuestion") : t("createQuestion")}
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submit} id="questionForm">
                    <QuestionTypeSelect type={type} setType={setType}  />
                    <WriteInput label="questionLabel" value={title} setter={setTitle} t={t} required/>
                    {type === QuestionTypeEnum.FILL_IN_THE_GAPS ? (
                        <WriteInput label="questionContent"
                                    required
                                    value={description}
                                    setter={setDescription}
                                    t={t}
                                    helperText={t("useUnderscoreForField")}/>
                    ) : (
                        <MarkdownEditor
                            t={t}
                            label="questionContentOptional"
                            value={description}
                            onChange={v => setDescription(v || "")}/>
                    )}
                    <WriteInput
                        type="number"
                        label="time" value={time}
                        setter={v => setTime(parseInt(v))} t={t}
                        required/>
                </form>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="questionForm">
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );

}