import axios, {AxiosResponse} from "axios";
import Question from "../domain/Question";

export function createQuestion(quizId: any, body: any): Promise<AxiosResponse<Question>> {
    const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/questions`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateQuestion(quizId: any, questionId: any, body: any): Promise<AxiosResponse<Question>> {
    const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/questions/${questionId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}

export function deleteQuestion(quizId: any, questionId: any) : Promise<AxiosResponse<void>> {
    const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/questions/${questionId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}