import {Button} from "flowbite-react";
import {HiCheck, HiXMark} from "react-icons/hi2";
import PhoneNumberField from "./PhoneNumberField";
import {formatDateToYYYYMMDD, parseDate} from "../../../components/utils/DateUtil";
import React, {FormEvent, useEffect, useState} from "react";
import {User} from "../../../domain/User";
import WriteInput from "../../../components/Inputs/WriteInput";
import GenderSelect from "./GenderSelect";
import CivilitySelect from "./CivilitySelect";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {useTranslation} from "react-i18next";
import {updateUser} from "../api/userConnector";

interface UpdateUserFormProps {
    setIsEditing: (newState: boolean) => void;
    user: User | undefined;
    doReload: () => void;
}

export default function UpdateUserForm({setIsEditing, user, doReload}: UpdateUserFormProps) {
    const { throwError } = useAppContext();
    const { t } = useTranslation("users");

    const [firstName, setFirstName] = useState("");
    const [usageFirstName, setUsageFirstName] = useState("");
    const [secondName, setSecondName] = useState("");
    const [thirdName, setThirdName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [birthCity, setBirthCity] = useState("");
    const [gender, setGender] = useState("");
    const [civility, setCivility] = useState("");
    const [nationality, setNationality] = useState("");
    const [phonePrefix, setPhonePrefix] = useState("33");
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        if (user !== undefined) {
            setFirstName(user.firstName);
            setUsageFirstName(user.usageFirstName);
            setSecondName(user.secondName);
            setThirdName(user.thirdName);
            setLastName(user.lastName);
            setBirthDate(formatDateToYYYYMMDD(user.birthDate));
            setBirthCity(user.birthCity);
            setGender(user.gender);
            setCivility(user.civility);
            setNationality(user.nationality);
            setPhonePrefix(user.phonePrefix);
            setPhoneNumber(user.phoneNumber);
        }
    }, [user]);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        updateUser(user?.id, {
            firstName: firstName,
            usageFirstName: usageFirstName,
            secondName: secondName,
            thirdName: thirdName,
            lastName: lastName,
            birthCity: birthCity,
            birthDate: parseDate(birthDate),
            civility: civility,
            gender: gender,
            nationality: nationality,
            phonePrefix: phonePrefix,
            phoneNumber: phoneNumber,
        }).then(() => {
            setIsEditing(false);
            doReload();
        }).catch(() => throwError(t("updateUserError")));
    }

    return (
        <form onSubmit={submit}>
            <Button onClick={() => setIsEditing(false)} color="failure" className="absolute end-12 top-0 rounded-none rounded-es-lg">
                <HiXMark />
            </Button>
            <Button type="submit" color="success" className="absolute end-0 top-0 rounded-none rounded-se-lg">
                <HiCheck />
            </Button>
            <div className="flex flex-col gap-4">
                <WriteInput value={firstName} setter={setFirstName} t={t} label={"firstName"} required placeholder="Michel" />
                <WriteInput value={usageFirstName} label={"usageFirstName"} setter={setUsageFirstName} t={t} placeholder="" />
                <WriteInput value={secondName} label={"secondName"} setter={setSecondName} t={t} placeholder="" />
                <WriteInput value={thirdName} label={"thirdName"} setter={setThirdName} t={t} placeholder="" />
                <WriteInput value={lastName} label={"lastName"} setter={setLastName} t={t} required placeholder="SARDOU" />
                <GenderSelect value={gender} setValue={setGender} />
                <CivilitySelect value={civility} setValue={setCivility} />
                <PhoneNumberField phoneNumber={phoneNumber} phonePrefix={phonePrefix} setPhoneNumber={setPhoneNumber} setPhonePrefix={setPhonePrefix} />
                <WriteInput value={birthDate} label="birthDate" setter={setBirthDate} t={t} placeholder="" type="date" />
                <WriteInput value={birthCity} label={"birthCity"} setter={setBirthCity} t={t} placeholder="" />
                <WriteInput value={nationality} label={"nationality"} setter={setNationality} t={t} placeholder="" />
            </div>
        </form>
    );
}