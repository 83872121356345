import axios, {AxiosResponse} from "axios";
import QuestionBank, {QuestionBankLight} from "../domain/QuestionBank";

export function getQuestionBank(id: any): Promise<AxiosResponse<QuestionBank>> {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks/${id}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getQuestionBanks(): Promise<AxiosResponse<QuestionBankLight[]>> {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function createQuestionBank(body: any): Promise<AxiosResponse<QuestionBankLight>> {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateQuestionBank(questionBankId: any, body: any): Promise<AxiosResponse<QuestionBank>> {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks/${questionBankId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}

export function deleteQuestionBank(questionBankId: any) {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks/${questionBankId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };

    return axios.delete(url, config);
}