import {useTranslation} from "react-i18next";
import Subject from "../../domain/Subject";
import { TbFolderDown } from "react-icons/tb";
import {getDutyContent} from "../../api/dutyConnector";
import {useAppContext} from "../../../../components/AppContext/AppContext";

interface DutyManagementProps {
    subject: Subject;
}

export default function DutyManagement({subject}: DutyManagementProps) {
    const { t } = useTranslation("assessments")
    const { throwError } = useAppContext();

    function handleGetContent() {
        getDutyContent(subject.id)
            .then(linkAxiosResponse => {
                window.open(linkAxiosResponse.data, "_blank");
            }).catch(() => throwError(t("getDutyContentError")));
    }

    return (
        <div className="flex justify-center p-2">
            <div onClick={handleGetContent} className="hover:bg-hexagone-darker-light-grey hover:cursor-pointer p-5 rounded-lg">
                <TbFolderDown size={150} className="text-hexagone-purple" />
                {t("downloadSubject")}
            </div>
        </div>
    );
}