import axios, {AxiosResponse} from "axios";
import {AssessmentInstance, AssessmentInstanceLight} from "../domain/Assessment";

export function getMyAssessments(): Promise<AxiosResponse<AssessmentInstance[]>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function accessQuiz(code: string): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${code}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function resetInstance(code: string): Promise<AxiosResponse<AssessmentInstanceLight>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${code}/reset`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, undefined, config);
}

export function restartInstance(code: string): Promise<AxiosResponse<AssessmentInstanceLight>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${code}/restart`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, undefined, config);
}

export function deleteInstance(code: string): Promise<AxiosResponse<void>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${code}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}