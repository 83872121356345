import React, {useState} from "react";
import {HiTrash} from "react-icons/hi2";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {deleteQuiz} from "../../api/quizConnector";
import {useNavigate} from "react-router-dom";

interface QuizDeleteModalProps {
    quizId: string;
    reloadQuizzes: () => void;
}

export default function QuizDeleteModal({quizId, reloadQuizzes} : QuizDeleteModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("enrolmentTests");
    const navigate = useNavigate();
    const {throwError} = useAppContext();

    function submit() {
        deleteQuiz(quizId)
            .then(() => {
                navigate("/enrolment-tests/quizzes");
                reloadQuizzes();
                setIsOpen(false);
            })
            .catch(() => throwError("deleteQuizError"));
    }

    return (
        <>
            <button
                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full" onClick={() => setIsOpen(true)}>
                <HiTrash color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <ModalHeader>
                    {t("deleteQuiz")}
                </ModalHeader>
                <ModalBody>
                    {t("confirmDeleteQuiz")}
                </ModalBody>
                <ModalFooter className="flex justify-between">
                    <Button color="primary" onClick={() => setIsOpen(false)}>
                        {t("cancel", {ns: "common"})}
                    </Button>
                    <Button color="failure" onClick={submit}>
                        {t("delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}