import {Sidebar} from "flowbite-react";
import React from "react";
import {RoleEnum} from "../../../authentication/RoleEnum";
import ShowIfRoles from "../../../ConditionalShow/ShowIfRoles";
import {useTranslation} from "react-i18next";
import {IconType} from "react-icons/lib";

export default function DrawerItemRestricted({icon, linkTo, label, restrictedTo, onClick} : {
    icon?: IconType, linkTo: string, label: string, restrictedTo: RoleEnum[], onClick: (link: string) => void
}) {
    const { t } = useTranslation();

    return (
        <ShowIfRoles restrictedTo={restrictedTo}>
            <Sidebar.Item
                icon={icon}
                className="hover:cursor-pointer"
                onClick={() => onClick(linkTo)}
            >
                {t("menu." + label)}
            </Sidebar.Item>
        </ShowIfRoles>
    )

}