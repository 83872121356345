import React, {Dispatch, SetStateAction, useState} from "react";
import QuestionBank from "../../domain/QuestionBank";
import QuestionBankModal from "./QuestionBankModal";
import {HiPencil} from "react-icons/hi2";

interface SubjectUpdateModalProps {
    updatableSubject: QuestionBank | undefined;
    setUpdatableSubject?: Dispatch<SetStateAction<QuestionBank | undefined>>;
    reloadSubjects: () => void;
}

export default function QuestionBankUpdateModal({updatableSubject, setUpdatableSubject, reloadSubjects} : SubjectUpdateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button className="p-2 hover:bg-hexagone-darker-light-grey active:hover:bg-hexagone-purple rounded-full" onClick={() => setIsOpen(true)}>
                <HiPencil size="20" className="text-hexagone-purple active:text-hexagone-light-grey"/>
            </button>
            <QuestionBankModal isOpen={isOpen} setIsOpen={setIsOpen} setUpdatableQuestionBank={setUpdatableSubject} updatableQuestionBank={updatableSubject} reloadQuestionBanks={reloadSubjects} />
        </>
    );
}