import {ApplicationCategory} from "../../domain/Application";
import LauncherItem from "../../modules/dashboard/components/LauncherItem";
import {useTranslation} from "react-i18next";

export default function LauncherCategoryContent({category, isActive}: {
    category: ApplicationCategory, isActive: boolean
}) {
    const {t} = useTranslation("dashboard");

    return (
        <div className={`my-6 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-4 ${!isActive && "hidden"}`}>

            {category.applications.map(app => <LauncherItem key={app.url} link={app.url} image={app.imageUrl}
                                                            title={app.title}
                                                            description={t("app." + app.code)}/>)}
        </div>
    );
}