import {Button} from "flowbite-react";
import {HiPlus} from "react-icons/hi2";
import {Dispatch, SetStateAction, useState} from "react";
import QuestionBankModal from "./QuestionBankModal";
import {QuestionBankLight} from "../../domain/QuestionBank";

interface SubjectCreateModalProps {
    setSubjects: Dispatch<SetStateAction<QuestionBankLight[]>>;
}

export default function QuestionBankCreateModal({setSubjects} : SubjectCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="w-full flex justify-end">
                <Button color="success" onClick={() => setIsOpen(true)}><HiPlus size="20" /></Button>
            </div>
            <QuestionBankModal isOpen={isOpen} setIsOpen={setIsOpen} setQuestionBanks={setSubjects} />
        </>
    );
}