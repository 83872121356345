import {AssessmentInstance, AssessmentLight, AssessmentStateEnum} from "../domain/Assessment";
import React, {useCallback, useEffect, useState} from "react";
import {isAfter, isToday, parseDate} from "../../../components/utils/DateUtil";
import {parseTimeToString} from "../../../components/utils/TimeUtil";
import {useTranslation} from "react-i18next";
import {HiMiniPlayPause, HiOutlineCalendarDays, HiPlay} from "react-icons/hi2";
import {accessQuiz} from "../api/assessmentInstanceConnector";
import {useAppContext} from "../../../components/AppContext/AppContext";
import ShowIf from "../../../components/ConditionalShow/ShowIf";
import {SubjectTypeEnum} from "../domain/Subject";
import MyAssessmentInstanceContent from "../parts/AssessmentInstanceContent/MyAssessmentInstanceContent";
import { FaCheckCircle } from "react-icons/fa";

interface MyAssessmentInstanceCardProps {
    assessmentInstance: AssessmentInstance;
    inProgress?: boolean;
    reload: () => void;
}

interface MyAssessmentInstanceCardButtonProps {
    assessmentInstance: AssessmentInstance;
    canBeLaunched?: boolean;
    accessAssessment: () => void;
}

function MyAssessmentInstanceCardButton({assessmentInstance, canBeLaunched, accessAssessment}: Readonly<MyAssessmentInstanceCardButtonProps>) {

    if (assessmentInstance.state === AssessmentStateEnum.DONE_UPDATABLE) {
        return (
            <button className="text-center min-w-[6%] cursor-pointer" onClick={accessAssessment}>
                <span className="inline-flex">
                    <FaCheckCircle className="h-8 w-8" />
                </span>
                <p>Rendu effectué</p>
            </button>
        );
    } else  if (canBeLaunched && assessmentInstance.state === AssessmentStateEnum.STARTED) {
        return (
            <button className="text-center min-w-[6%] cursor-pointer" onClick={accessAssessment}>
                <span className="inline-flex">
                    <HiMiniPlayPause className="h-8 w-8" />
                </span>
                <p>Poursuivre</p>
            </button>
        );
    } else if (canBeLaunched) {
        return (
            <button className="text-center min-w-[6%] cursor-pointer" onClick={accessAssessment}>
                <span className="inline-flex">
                    <HiPlay className="h-8 w-8" />
                </span>
                <p>Démarrer</p>
            </button>
        );
    } else {
        return (
            <div className="text-center min-w-[6%]">
                <span className="inline-flex">
                    <HiOutlineCalendarDays className="h-8 w-8" />
                </span>
                <p>Planifié</p>
            </div>
        );
    }

}

export default function MyAssessmentInstanceCard({assessmentInstance, inProgress, reload }: Readonly<MyAssessmentInstanceCardProps>) {
    const [canBeLaunched, setCanBeLaunched] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const { throwError } = useAppContext();
    const { t } = useTranslation("assessments");

    useEffect(() => {
        if (isAfter(new Date(), assessmentInstance.assessment.startDate) && isAfter(assessmentInstance.assessment.endDate, new Date()) &&
            (assessmentInstance.state === AssessmentStateEnum.READY || assessmentInstance.state === AssessmentStateEnum.STARTED || assessmentInstance.state === AssessmentStateEnum.DONE_UPDATABLE)) {
            setCanBeLaunched(true);
        } else {
            setCanBeLaunched(false);
        }
    }, [assessmentInstance.assessment.endDate, assessmentInstance.assessment.startDate, assessmentInstance.state]);

    function formatDisplayDateOrDateTime(date: Date, isEndDate: boolean) {
        if (isToday(date)) {
            return (
                <p className={`inline-flex ${isEndDate ? "text-red-700 text-2xl" : "text-3xl"}`}>
                    <ShowIf condition={isEndDate}>
                        Expire à&nbsp;
                    </ShowIf>
                    {parseTimeToString(date)}
                </p>
            );
        }
        return (
            <>
                <p className={`inline-flex ${isEndDate ? "text-red-700 text-2xl" : "text-3xl"}`}>
                    <ShowIf condition={isEndDate}>
                        Expire le&nbsp;
                    </ShowIf>
                    {date.getDate()}{inProgress && `${"/" + date.toLocaleString('default', { month: '2-digit' })}`}
                </p>
                <p>{parseTimeToString(date)}</p>
            </>
        )
    }

    function formatDisplayStartOrEndDate(assessment: AssessmentLight) {
        const parsedEndDate = parseDate(assessment.endDate);
        const parsedStartDate = parseDate(assessment.startDate);

        if (isAfter(new Date(), parsedStartDate)) {
            return formatDisplayDateOrDateTime(parsedEndDate, true);
        }
        return formatDisplayDateOrDateTime(parsedStartDate, false);
    }

    const accessAssessment = useCallback(() => {
        if (canBeLaunched) {
            if (assessmentInstance.assessment.subject.type === SubjectTypeEnum.QUIZ) {
                accessQuiz(assessmentInstance.code)
                    .then(accessKeyAxiosResponse => {
                        const url = window._env_.QUIZ_URL + "/" + accessKeyAxiosResponse.data;
                        window.open(url, "_blank", "noreferrer");
                    }).catch(() => throwError(t("getQuizAccessKeyError")));
            } else {
                setDrawerOpen(true);
            }
        }
    }, [canBeLaunched, assessmentInstance.code, assessmentInstance.assessment.subject.type, throwError, t]);

    const removeYearAndDash = useCallback( (input: string) => {
        const regex = /^\d{4}-/;
        return input.replace(regex, '');
    }, []);

    return (
        <div className="flex p-4 gap-8 items-center w-full">
            <ShowIf condition={assessmentInstance.assessment.subject.type !== SubjectTypeEnum.QUIZ}>
                <MyAssessmentInstanceContent isOpen={isDrawerOpen} setIsOpen={setDrawerOpen} instance={assessmentInstance} reload={reload} />
            </ShowIf>
            <div className="min-w-[12%] me-2">
                {formatDisplayStartOrEndDate(assessmentInstance.assessment)}
            </div>
            <p className="text-xl w-full">{removeYearAndDash(assessmentInstance.assessment.subject.ectsCode)} - {assessmentInstance.assessment.subject.title}</p>
            <MyAssessmentInstanceCardButton assessmentInstance={assessmentInstance} canBeLaunched={canBeLaunched} accessAssessment={accessAssessment} />
        </div>
    );
}