import {useTranslation} from "react-i18next";
import {FaApple, FaWindows, FaLinux, FaAndroid} from "react-icons/fa"
import {HiDownload} from "react-icons/hi"
import React, {ReactNode} from "react";

interface ToolCardProps {
    logo: ReactNode;
    content: ReactNode;
    button?: ReactNode;
    resourceLink?: string;
    className?: string;
}

function ToolCard({logo, content, button, resourceLink, className} : ToolCardProps) {
    return (
        <dl className={`rounded-lg grid items-center p-4 h-full ${className}`}>
            <div className="place-self-center self-start flex flex-col items-center">
                {logo}
            </div>
            <div className="my-2">
                {content}
            </div>
            {button ? (
                <a className="place-self-center self-end" href={resourceLink || ''}>
                    {button}
                </a>
            ): ""}
        </dl>
    );
}

export default function WiFi() {
    const { t } = useTranslation("tools");

    return (
        <div>
            <h1 className="mx-4 mb-2 font-extrabold text-xl self-start">
                {t("accessToWi-Fi")}
            </h1>
            <div className="flex flex-col-reverse md:grid md:grid-cols-3 md:gap-3 mb-2 p-5">
                <ToolCard
                    className="bg-orange-50"
                    logo={
                    <>
                        <dt className="w-8 h-8 text-orange-700 text-sm font-medium flex items-center justify-center mb-1"><FaWindows className="h-5 w-5" /></dt>
                        <dd className="text-orange-700 dark:text-orange-300 text-sm font-medium">Windows</dd>
                    </>}
                    content={
                    <ol className="list-decimal list-inside list-font-bold">
                        <li>{t("windows.step1")}</li>
                        <li>{t("windows.step2")}</li>
                        <li>{t("windows.step3")}</li>
                    </ol>}
                    button={
                    <button className="rounded-full bg-orange-100 text-orange-700 text-sm font-medium flex items-center justify-center mb-1 p-2">
                        Windows <HiDownload className="h-5 w-5" />
                    </button>}
                    resourceLink="/resources/wifi/windows/École Hexagone%20-%20Wi-Fi.zip"
                />
                <ToolCard
                    className="bg-teal-50"
                    logo={
                        <>
                            <dt className="w-8 h-8 text-teal-700 text-sm font-medium flex items-center justify-center mb-1"><FaApple className="h-5 w-5" /></dt>
                            <dd className="text-teal-700 dark:text-teal-300 text-sm font-medium">Mac</dd>
                        </>}
                    content={
                        <ol className="list-decimal list-inside list-font-bold my-2">
                            <li>{t("mac.step1")}</li>
                            <li>{t("mac.step2")}</li>
                            <li>{t("mac.step3")}</li>
                            <li>{t("mac.step4")}</li>
                            <li>{t("mac.step5")}</li>
                            <li>{t("mac.step6")}</li>
                        </ol>}
                    button={
                        <button className="rounded-full bg-teal-100 text-teal-700 text-sm font-medium flex items-center justify-center mb-1 p-2">
                            macOS <HiDownload className="h-5 w-5" />
                        </button>}
                    resourceLink="/resources/wifi/apple/École%20Hexagone%20-%20Wi-Fi.mobileconfig"
                />
                <ToolCard
                    className="bg-fuchsia-50"
                    logo={
                        <>
                            <dt className="w-8 h-8 text-fuchsia-700 text-sm font-medium flex items-center justify-center mb-1"><FaApple className="h-5 w-5" /></dt>
                            <dd className="text-fuchsia-700 text-sm font-medium">iOS / iPadOS</dd>
                        </>}
                    content={
                        <ol className="list-decimal list-inside my-2">
                            <li>{t("ios.step1")}</li>
                            <li>{t("ios.step2")}</li>
                            <li>{t("ios.step3")}</li>
                            <li>{t("ios.step4")}</li>
                            <li>{t("ios.step5")}</li>
                            <li>{t("ios.step6")}</li>
                        </ol>}
                    button={
                        <button className="rounded-full bg-fuchsia-100 text-fuchsia-700 text-sm font-medium flex items-center justify-center mb-1 p-2">
                            iOS / iPadOS <HiDownload className="h-5 w-5" />
                        </button>}
                    resourceLink="/resources/wifi/apple/École%20Hexagone%20-%20Wi-Fi.mobileconfig"
                />
                <ToolCard
                    className="bg-sky-50 col-span-3"
                    logo={
                        <>
                            <div className="flex">
                                <dt className="w-8 h-8 text-sky-700 dark:text-blue-300 text-sm font-medium flex items-center justify-center mb-1"><FaLinux className="h-5 w-5" /></dt>
                                /
                                <dt className="w-8 h-8 text-sky-700 dark:text-blue-300 text-sm font-medium flex items-center justify-center mb-1"><FaAndroid className="h-5 w-5" /></dt>
                            </div>
                            <dd className="text-sky-700 dark:text-sky-300 text-sm font-medium">Linux / Android</dd>
                        </>}
                    content={<>
                        <p>{t("other.step")}</p>
                        <ul className="list-disc list-inside my-6">
                            <li>{t("other.config1")}</li>
                            <li>{t("other.config2")}</li>
                            <li>{t("other.config3")}</li>
                        </ul>
                        <p>{t("other.help")}</p>
                    </>}
                />
            </div>
        </div>
    );
}