import {Button} from "flowbite-react";
import {HiPlus} from "react-icons/hi2";
import {Dispatch, SetStateAction, useState} from "react";
import SubjectModal from "./SubjectModal";
import {SubjectLight} from "../../domain/Subject";

interface SubjectCreateModalProps {
    setSubjects: Dispatch<SetStateAction<SubjectLight[]>>;
}

export default function SubjectCreateModal({setSubjects} : SubjectCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="w-full flex justify-end">
                <Button color="success" onClick={() => setIsOpen(true)}><HiPlus size="20" /></Button>
            </div>
            <SubjectModal isOpen={isOpen} setIsOpen={setIsOpen} setSubjects={setSubjects} />
        </>
    );
}