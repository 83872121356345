import {Label, Select} from "flowbite-react";
import React from "react";
import {useTranslation} from "react-i18next";

interface CivilitySelectProps {
    value: string,
    setValue: (newValue: string) => void;
}

export default function CivilitySelect({value, setValue} : CivilitySelectProps) {
    const { t } = useTranslation("users");

    return (
        <div>
            <Label htmlFor="civility" value={t("civility") + "*"} />
            <Select value={value}
                    onChange={(e) => setValue(e.target.value)}
                    id="civility"
                    required
            >
                <option disabled value="">
                    {t("selectCivility")}
                </option>
                <option value="M.">M.</option>
                <option value="Mme">Mme.</option>
                <option value="Dr">Dr.</option>
            </Select>
        </div>
    );
}