import {Button} from "flowbite-react";
import React, {Dispatch, SetStateAction, useState} from "react";
import Question from "../../domain/Question";
import {useTranslation} from "react-i18next";
import QuestionModal from "./QuestionModal";
import {HiPlus} from "react-icons/hi2";

interface QuestionsCreateModalProps {
    label: string;
    setQuestions: Dispatch<SetStateAction<Question[]>>;
    handleCreate: (type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) => void;
}

export default function QuestionCreateModal({label, setQuestions, handleCreate} : QuestionsCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation("assessments");

    return (
        <>
            <Button className="w-full" color="secondary" onClick={() => setIsOpen(true)}><HiPlus />&nbsp;{t(label)}</Button>
            <QuestionModal isOpen={isOpen} setIsOpen={setIsOpen} handleCreate={handleCreate} setQuestions={setQuestions} />
        </>
    );
}