interface InternationalPhonePrefixType {
    country: string;
    flag: string;
    prefix: string;
}

const InternationalPhonePrefix = [
    {
        country: 'Afghanistan',
        flag: '🇦🇫',
        prefix: '93',
    },
    {
        country: 'Albania',
        flag: '🇦🇱',
        prefix: '355',
    },
    {
        country: 'Algeria',
        flag: '🇩🇿',
        prefix: '213',
    },
    {
        country: 'Andorra',
        flag: '🇦🇩',
        prefix: '376',
    },
    {
        country: 'Angola',
        flag: '🇦🇴',
        prefix: '244',
    },
    {
        country: 'Antigua and Barbuda',
        flag: '🇦🇬',
        prefix: '1-268',
    },
    {
        country: 'Argentina',
        flag: '🇦🇷',
        prefix: '54',
    },
    {
        country: 'Armenia',
        flag: '🇦🇲',
        prefix: '374',
    },
    {
        country: 'Australia',
        flag: '🇦🇺',
        prefix: '61',
    },
    {
        country: 'Austria',
        flag: '🇦🇹',
        prefix: '43',
    },
    {
        country: 'Azerbaijan',
        flag: '🇦🇿',
        prefix: '994',
    },
    {
        country: 'Bahamas',
        flag: '🇧🇸',
        prefix: '1-242',
    },
    {
        country: 'Bahrain',
        flag: '🇧🇭',
        prefix: '973',
    },
    {
        country: 'Bangladesh',
        flag: '🇧🇩',
        prefix: '880',
    },
    {
        country: 'Barbados',
        flag: '🇧🇧',
        prefix: '1-246',
    },
    {
        country: 'Belarus',
        flag: '🇧🇾',
        prefix: '375',
    },
    {
        country: 'Belgium',
        flag: '🇧🇪',
        prefix: '32',
    },
    {
        country: 'Belize',
        flag: '🇧🇿',
        prefix: '501',
    },
    {
        country: 'Benin',
        flag: '🇧🇯',
        prefix: '229',
    },
    {
        country: 'Bhutan',
        flag: '🇧🇹',
        prefix: '975',
    },
    {
        country: 'Bolivia',
        flag: '🇧🇴',
        prefix: '591',
    },
    {
        country: 'Bosnia and Herzegovina',
        flag: '🇧🇦',
        prefix: '387',
    },
    {
        country: 'Botswana',
        flag: '🇧🇼',
        prefix: '267',
    },
    {
        country: 'Brazil',
        flag: '🇧🇷',
        prefix: '55',
    },
    {
        country: 'Brunei',
        flag: '🇧🇳',
        prefix: '673',
    },
    {
        country: 'Bulgaria',
        flag: '🇧🇬',
        prefix: '359',
    },
    {
        country: 'Burkina Faso',
        flag: '🇧🇫',
        prefix: '226',
    },
    {
        country: 'Burundi',
        flag: '🇧🇮',
        prefix: '257',
    },
    {
        country: 'Cabo Verde',
        flag: '🇨🇻',
        prefix: '238',
    },
    {
        country: 'Cambodia',
        flag: '🇰🇭',
        prefix: '855',
    },
    {
        country: 'Cameroon',
        flag: '🇨🇲',
        prefix: '237',
    },
    {
        country: 'Canada',
        flag: '🇨🇦',
        prefix: '1',
    },
    {
        country: 'Central African Republic',
        flag: '🇨🇫',
        prefix: '236',
    },
    {
        country: 'Chad',
        flag: '🇹🇩',
        prefix: '235',
    },
    {
        country: 'Chile',
        flag: '🇨🇱',
        prefix: '56',
    },
    {
        country: 'China',
        flag: '🇨🇳',
        prefix: '86',
    },
    {
        country: 'Colombia',
        flag: '🇨🇴',
        prefix: '57',
    },
    {
        country: 'Comoros',
        flag: '🇰🇲',
        prefix: '269',
    },
    {
        country: 'Congo',
        flag: '🇨🇩',
        prefix: '243',
    },
    {
        country: 'Costa Rica',
        flag: '🇨🇷',
        prefix: '506',
    },
    {
        country: 'Croatia',
        flag: '🇭🇷',
        prefix: '385',
    },
    {
        country: 'Cuba',
        flag: '🇨🇺',
        prefix: '53',
    },
    {
        country: 'Cyprus',
        flag: '🇨🇾',
        prefix: '357',
    },
    {
        country: 'Czech Republic',
        flag: '🇨🇿',
        prefix: '420',
    },
    {
        country: 'Denmark',
        flag: '🇩🇰',
        prefix: '45',
    },
    {
        country: 'Djibouti',
        flag: '🇩🇯',
        prefix: '253',
    },
    {
        country: 'Dominica',
        flag: '🇩🇲',
        prefix: '1-767',
    },
    {
        country: 'Dominican Republic',
        flag: '🇩🇴',
        prefix: '1-809',
    },
    {
        country: 'Ecuador',
        flag: '🇪🇨',
        prefix: '593',
    },
    {
        country: 'Egypt',
        flag: '🇪🇬',
        prefix: '20',
    },
    {
        country: 'El Salvador',
        flag: '🇸🇻',
        prefix: '503',
    },
    {
        country: 'Equatorial Guinea',
        flag: '🇬🇶',
        prefix: '240',
    },
    {
        country: 'Eritrea',
        flag: '🇪🇷',
        prefix: '291',
    },
    {
        country: 'Estonia',
        flag: '🇪🇪',
        prefix: '372',
    },
    {
        country: 'Eswatini',
        flag: '🇸🇿',
        prefix: '268',
    },
    {
        country: 'Ethiopia',
        flag: '🇪🇹',
        prefix: '251',
    },
    {
        country: 'Fiji',
        flag: '🇫🇯',
        prefix: '679',
    },
    {
        country: 'Finland',
        flag: '🇫🇮',
        prefix: '358',
    },
    {
        country: 'France',
        flag: '🇫🇷',
        prefix: '33',
    },
    {
        country: 'Gabon',
        flag: '🇬🇦',
        prefix: '241',
    },
    {
        country: 'Gambia',
        flag: '🇬🇲',
        prefix: '220',
    },
    {
        country: 'Georgia',
        flag: '🇬🇪',
        prefix: '995',
    },
    {
        country: 'Germany',
        flag: '🇩🇪',
        prefix: '49',
    },
    {
        country: 'Ghana',
        flag: '🇬🇭',
        prefix: '233',
    },
    {
        country: 'Greece',
        flag: '🇬🇷',
        prefix: '30',
    },
    {
        country: 'Grenada',
        flag: '🇬🇩',
        prefix: '1-473',
    },
    {
        country: 'Guatemala',
        flag: '🇬🇹',
        prefix: '502',
    },
    {
        country: 'Guinea',
        flag: '🇬🇳',
        prefix: '224',
    },
    {
        country: 'Guinea-Bissau',
        flag: '🇬🇼',
        prefix: '245',
    },
    {
        country: 'Guyana',
        flag: '🇬🇾',
        prefix: '592',
    },
    {
        country: 'Haiti',
        flag: '🇭🇹',
        prefix: '509',
    },
    {
        country: 'Honduras',
        flag: '🇭🇳',
        prefix: '504',
    },
    {
        country: 'Hungary',
        flag: '🇭🇺',
        prefix: '36',
    },
    {
        country: 'Iceland',
        flag: '🇮🇸',
        prefix: '354',
    },
    {
        country: 'India',
        flag: '🇮🇳',
        prefix: '91',
    },
    {
        country: 'Indonesia',
        flag: '🇮🇩',
        prefix: '62',
    },
    {
        country: 'Iran',
        flag: '🇮🇷',
        prefix: '98',
    },
    {
        country: 'Iraq',
        flag: '🇮🇶',
        prefix: '964',
    },
    {
        country: 'Ireland',
        flag: '🇮🇪',
        prefix: '353',
    },
    {
        country: 'Israel',
        flag: '🇮🇱',
        prefix: '972',
    },
    {
        country: 'Italy',
        flag: '🇮🇹',
        prefix: '39',
    },
    {
        country: 'Jamaica',
        flag: '🇯🇲',
        prefix: '1-876',
    },
    {
        country: 'Japan',
        flag: '🇯🇵',
        prefix: '81',
    },
    {
        country: 'Jordan',
        flag: '🇯🇴',
        prefix: '962',
    },
    {
        country: 'Kazakhstan',
        flag: '🇰🇿',
        prefix: '7',
    },
    {
        country: 'Kenya',
        flag: '🇰🇪',
        prefix: '254',
    },
    {
        country: 'Kiribati',
        flag: '🇰🇮',
        prefix: '686',
    },
    {
        country: 'Kuwait',
        flag: '🇰🇼',
        prefix: '965',
    },
    {
        country: 'Kyrgyzstan',
        flag: '🇰🇬',
        prefix: '996',
    },
    {
        country: 'Laos',
        flag: '🇱🇦',
        prefix: '856',
    },
    {
        country: 'Latvia',
        flag: '🇱🇻',
        prefix: '371',
    },
    {
        country: 'Lebanon',
        flag: '🇱🇧',
        prefix: '961',
    },
    {
        country: 'Lesotho',
        flag: '🇱🇸',
        prefix: '266',
    },
    {
        country: 'Liberia',
        flag: '🇱🇷',
        prefix: '231',
    },
    {
        country: 'Libya',
        flag: '🇱🇾',
        prefix: '218',
    },
    {
        country: 'Liechtenstein',
        flag: '🇱🇮',
        prefix: '423',
    },
    {
        country: 'Lithuania',
        flag: '🇱🇹',
        prefix: '370',
    },
    {
        country: 'Luxembourg',
        flag: '🇱🇺',
        prefix: '352',
    },
    {
        country: 'Madagascar',
        flag: '🇲🇬',
        prefix: '261',
    },
    {
        country: 'Malawi',
        flag: '🇲🇼',
        prefix: '265',
    },
    {
        country: 'Malaysia',
        flag: '🇲🇾',
        prefix: '60',
    },
    {
        country: 'Maldives',
        flag: '🇲🇻',
        prefix: '960',
    },
    {
        country: 'Mali',
        flag: '🇲🇱',
        prefix: '223',
    },
    {
        country: 'Malta',
        flag: '🇲🇹',
        prefix: '356',
    },
    {
        country: 'Marshall Islands',
        flag: '🇲🇭',
        prefix: '692',
    },
    {
        country: 'Mauritania',
        flag: '🇲🇷',
        prefix: '222',
    },
    {
        country: 'Mauritius',
        flag: '🇲🇺',
        prefix: '230',
    },
    {
        country: 'Mexico',
        flag: '🇲🇽',
        prefix: '52',
    },
    {
        country: 'Micronesia',
        flag: '🇫🇲',
        prefix: '691',
    },
    {
        country: 'Moldova',
        flag: '🇲🇩',
        prefix: '373',
    },
    {
        country: 'Monaco',
        flag: '🇲🇨',
        prefix: '377',
    },
    {
        country: 'Mongolia',
        flag: '🇲🇳',
        prefix: '976',
    },
    {
        country: 'Montenegro',
        flag: '🇲🇪',
        prefix: '382',
    },
    {
        country: 'Morocco',
        flag: '🇲🇦',
        prefix: '212',
    },
    {
        country: 'Mozambique',
        flag: '🇲🇿',
        prefix: '258',
    },
    {
        country: 'Myanmar',
        flag: '🇲🇲',
        prefix: '95',
    },
    {
        country: 'Namibia',
        flag: '🇳🇦',
        prefix: '264',
    },
    {
        country: 'Nauru',
        flag: '🇳🇷',
        prefix: '674',
    },
    {
        country: 'Nepal',
        flag: '🇳🇵',
        prefix: '977',
    },
    {
        country: 'Netherlands',
        flag: '🇳🇱',
        prefix: '31',
    },
    {
        country: 'New Zealand',
        flag: '🇳🇿',
        prefix: '64',
    },
    {
        country: 'Nicaragua',
        flag: '🇳🇮',
        prefix: '505',
    },
    {
        country: 'Niger',
        flag: '🇳🇪',
        prefix: '227',
    },
    {
        country: 'Nigeria',
        flag: '🇳🇬',
        prefix: '234',
    },
    {
        country: 'North Korea',
        flag: '🇰🇵',
        prefix: '850',
    },
    {
        country: 'North Macedonia',
        flag: '🇲🇰',
        prefix: '389',
    },
    {
        country: 'Norway',
        flag: '🇳🇴',
        prefix: '47',
    },
    {
        country: 'Oman',
        flag: '🇴🇲',
        prefix: '968',
    },
    {
        country: 'Pakistan',
        flag: '🇵🇰',
        prefix: '92',
    },
    {
        country: 'Palau',
        flag: '🇵🇼',
        prefix: '680',
    },
    {
        country: 'Palestine',
        flag: '🇵🇸',
        prefix: '970',
    },
    {
        country: 'Panama',
        flag: '🇵🇦',
        prefix: '507',
    },
    {
        country: 'Papua New Guinea',
        flag: '🇵🇬',
        prefix: '675',
    },
    {
        country: 'Paraguay',
        flag: '🇵🇾',
        prefix: '595',
    },
    {
        country: 'Peru',
        flag: '🇵🇪',
        prefix: '51',
    },
    {
        country: 'Philippines',
        flag: '🇵🇭',
        prefix: '63',
    },
    {
        country: 'Poland',
        flag: '🇵🇱',
        prefix: '48',
    },
    {
        country: 'Portugal',
        flag: '🇵🇹',
        prefix: '351',
    },
    {
        country: 'Qatar',
        flag: '🇶🇦',
        prefix: '974',
    },
    {
        country: 'Romania',
        flag: '🇷🇴',
        prefix: '40',
    },
    {
        country: 'Russia',
        flag: '🇷🇺',
        prefix: '7',
    },
    {
        country: 'Rwanda',
        flag: '🇷🇼',
        prefix: '250',
    },
    {
        country: 'Saint Kitts and Nevis',
        flag: '🇰🇳',
        prefix: '1-869',
    },
    {
        country: 'Saint Lucia',
        flag: '🇱🇨',
        prefix: '1-758',
    },
    {
        country: 'Saint Vincent and the Grenadines',
        flag: '🇻🇨',
        prefix: '1-784',
    },
    {
        country: 'Samoa',
        flag: '🇼🇸',
        prefix: '685',
    },
    {
        country: 'San Marino',
        flag: '🇸🇲',
        prefix: '378',
    },
    {
        country: 'Sao Tome and Principe',
        flag: '🇸🇹',
        prefix: '239',
    },
    {
        country: 'Saudi Arabia',
        flag: '🇸🇦',
        prefix: '966',
    },
    {
        country: 'Senegal',
        flag: '🇸🇳',
        prefix: '221',
    },
    {
        country: 'Serbia',
        flag: '🇷🇸',
        prefix: '381',
    },
    {
        country: 'Seychelles',
        flag: '🇸🇨',
        prefix: '248',
    },
    {
        country: 'Sierra Leone',
        flag: '🇸🇱',
        prefix: '232',
    },
    {
        country: 'Singapore',
        flag: '🇸🇬',
        prefix: '65',
    },
    {
        country: 'Slovakia',
        flag: '🇸🇰',
        prefix: '421',
    },
    {
        country: 'Slovenia',
        flag: '🇸🇮',
        prefix: '386',
    },
    {
        country: 'Solomon Islands',
        flag: '🇸🇧',
        prefix: '677',
    },
    {
        country: 'Somalia',
        flag: '🇸🇴',
        prefix: '252',
    },
    {
        country: 'South Africa',
        flag: '🇿🇦',
        prefix: '27',
    },
    {
        country: 'South Korea',
        flag: '🇰🇷',
        prefix: '82',
    },
    {
        country: 'South Sudan',
        flag: '🇸🇸',
        prefix: '211',
    },
    {
        country: 'Spain',
        flag: '🇪🇸',
        prefix: '34',
    },
    {
        country: 'Sri Lanka',
        flag: '🇱🇰',
        prefix: '94',
    },
    {
        country: 'Sudan',
        flag: '🇸🇩',
        prefix: '249',
    },
    {
        country: 'Suriname',
        flag: '🇸🇷',
        prefix: '597',
    },
    {
        country: 'Swaziland',
        flag: '🇸🇿',
        prefix: '268',
    },
    {
        country: 'Sweden',
        flag: '🇸🇪',
        prefix: '46',
    },
    {
        country: 'Switzerland',
        flag: '🇨🇭',
        prefix: '41',
    },
    {
        country: 'Syria',
        flag: '🇸🇾',
        prefix: '963',
    },
    {
        country: 'Taiwan',
        flag: '🇹🇼',
        prefix: '886',
    },
    {
        country: 'Tajikistan',
        flag: '🇹🇯',
        prefix: '992',
    },
    {
        country: 'Tanzania',
        flag: '🇹🇿',
        prefix: '255',
    },
    {
        country: 'Thailand',
        flag: '🇹🇭',
        prefix: '66',
    },
    {
        country: 'Timor-Leste',
        flag: '🇹🇱',
        prefix: '670',
    },
    {
        country: 'Togo',
        flag: '🇹🇬',
        prefix: '228',
    },
    {
        country: 'Tonga',
        flag: '🇹🇴',
        prefix: '676',
    },
    {
        country: 'Trinidad and Tobago',
        flag: '🇹🇹',
        prefix: '1-868',
    },
    {
        country: 'Tunisia',
        flag: '🇹🇳',
        prefix: '216',
    },
    {
        country: 'Turkey',
        flag: '🇹🇷',
        prefix: '90',
    },
    {
        country: 'Turkmenistan',
        flag: '🇹🇲',
        prefix: '993',
    },
    {
        country: 'Tuvalu',
        flag: '🇹🇻',
        prefix: '688',
    },
    {
        country: 'Uganda',
        flag: '🇺🇬',
        prefix: '256',
    },
    {
        country: 'Ukraine',
        flag: '🇺🇦',
        prefix: '380',
    },
    {
        country: 'United Arab Emirates',
        flag: '🇦🇪',
        prefix: '971',
    },
    {
        country: 'United Kingdom',
        flag: '🇬🇧',
        prefix: '44',
    },
    {
        country: 'United States',
        flag: '🇺🇸',
        prefix: '1',
    },
    {
        country: 'Uruguay',
        flag: '🇺🇾',
        prefix: '598',
    },
    {
        country: 'Uzbekistan',
        flag: '🇺🇿',
        prefix: '998',
    },
    {
        country: 'Vanuatu',
        flag: '🇻🇺',
        prefix: '678',
    },
    {
        country: 'Vatican City',
        flag: '🇻🇦',
        prefix: '39',
    },
    {
        country: 'Venezuela',
        flag: '🇻🇪',
        prefix: '58',
    },
    {
        country: 'Vietnam',
        flag: '🇻🇳',
        prefix: '84',
    },
    {
        country: 'Yemen',
        flag: '🇾🇪',
        prefix: '967',
    },
    {
        country: 'Zambia',
        flag: '🇿🇲',
        prefix: '260',
    },
    {
        country: 'Zimbabwe',
        flag: '🇿🇼',
        prefix: '263',
    },
] as InternationalPhonePrefixType[];

function findByPrefix(prefix: string) {
    return InternationalPhonePrefix.find(p => p.prefix === prefix);
}

export {InternationalPhonePrefix, findByPrefix};