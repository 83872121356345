import {RoleEnum} from "../../components/authentication/RoleEnum";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import SubjectsManagement from "./pages/SubjectsManagement";

export default function QuestionsBank() {

    return (
        <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS]}>
            <SubjectsManagement />
        </RestrictedAccess>
    );

}