import AbstractDrawer from "../../../components/AbstractDrawer";
import {useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {getSubject} from "../api/subjectConnector";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {HiBookOpen} from "react-icons/hi";
import Subject from "../domain/Subject";
import LoadingComponent from "../../../components/LoadingComponent";
import SubjectUpdateModal from "../components/SubjectModals/SubjectUpdateModal";
import SubjectDeleteModal from "../components/SubjectModals/SubjectDeleteModal";
import QuestionCreateModal from "../components/QuestionModals/QuestionCreateModal";
import Question from "../domain/Question";
import QuestionCard from "../components/QuestionCard";

interface SubjectManagementProps {
    selectedSubjectDrawer: string;
    reloadSubjects: () => void;
}

export default function SubjectManagement({selectedSubjectDrawer, reloadSubjects} : SubjectManagementProps) {
    const navigate = useNavigate();
    const { t } = useTranslation("enrolmentTests");
    const [subject, setSubject] = useState<Subject>();
    const [questions, setQuestions] = useState<Question[]>([]);
    const [isSubjectLoading, setIsSubjectLoading] = useState(true);
    const {throwError} = useAppContext();

    const reloadSubject = useCallback((subjectId: string) => {
        if (subjectId !== "") {
            setIsSubjectLoading(true);
            getSubject(subjectId)
                .then((subjectAxiosResponse) => {
                    setIsSubjectLoading(false);
                    setSubject(subjectAxiosResponse.data);
                })
                .catch(() => throwError(t("getSubjectError")));
        }
    }, [throwError, t]);

    useEffect(() => {
        reloadSubject(selectedSubjectDrawer);
    }, [reloadSubject, selectedSubjectDrawer]);

    useEffect(() => {
        if (subject) {
            setQuestions(subject.questions || []);
        }
    }, [subject]);

    return (
        <AbstractDrawer shadow side="end" size="3xl" isOpen={selectedSubjectDrawer !== ""}
                        setIsOpen={() => navigate("/enrolment-tests/questions-bank")}>
            <LoadingComponent isLoading={isSubjectLoading}>
                <>
                    <div className="items-center">
                        <div className="flex justify-between items-center">
                            <div className="inline-flex">
                                <HiBookOpen size="40"/>&nbsp;
                                <h2 className="text-3xl">{subject?.name}</h2>
                            </div>
                            <QuestionCreateModal setQuestions={setQuestions} subjectId={selectedSubjectDrawer} />
                            <div className="flex items-center space-x-1">
                                <SubjectUpdateModal updatableSubject={subject} setUpdatableSubject={setSubject} reloadSubjects={reloadSubjects} />
                                <SubjectDeleteModal subjectId={selectedSubjectDrawer} reloadSubjects={reloadSubjects} />
                            </div>
                        </div>
                        {questions !== undefined && questions.length > 0 ? questions.map(question =>
                            <QuestionCard key={question.id} question={question} subjectId={selectedSubjectDrawer} setQuestions={setQuestions} />
                        ) : <span className="mt-4">{t("noQuestionsYet")}</span>}
                    </div>
                </>
            </LoadingComponent>
        </AbstractDrawer>
    )
}