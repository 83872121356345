import axios, {AxiosProgressEvent, AxiosResponse} from "axios";

export function getDutyContent(subjectId: any): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${subjectId}/duties`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getMyDutyContent(instanceCode: any): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${instanceCode}/duties`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getDutyResponse(instanceCode: any): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${instanceCode}/duties/responses`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getUpdateDutyContentLink(subjectId: any, file: File): Promise<AxiosResponse<string>> {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${subjectId}/duties?fileName=${file.name}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, {}, config);
}

export function getUpdateMyDutyResponseLink(instanceCode: any, file: File) {
    const url = window._env_.ASSESSMENTS_API_URL + `assessments/instances/${instanceCode}/duties/responses?fileName=${file.name}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, {}, config);
}

export function performUpdate(link: string, file: File, onUploadProgress?: (progress: AxiosProgressEvent) => void) {
    const config = {
        onUploadProgress,
        headers: {
            "Content-Type": file.type,
        }
    }
    return axios.put(link, file, config);
}