import {useTranslation} from "react-i18next";
import {Pagination, Select} from "flowbite-react";
import React from "react";

interface PaginationParamsProps {
    pageSize: number;
    setPageSize: (value: (((prevState: number) => number) | number)) => void;
    setPageNumber: (value: (((prevState: number) => number) | number)) => void;
    pageNumber: number;
    totalPages: number;
}

export default function PaginationParams({pageSize, setPageSize, setPageNumber, pageNumber, totalPages}: PaginationParamsProps) {
    const { t } = useTranslation();

    return <div className="flex justify-between flex-wrap items-center">
        <div className="flex items-center mx-auto md:mx-0">
            {t("elementsByPage", {ns: "common"})}&nbsp;
            <div className="me-1">
                <Select value={pageSize}
                        sizing="sm"
                        onChange={(e) => {
                            setPageSize(parseInt(e.target.value));
                            setPageNumber(0);
                        }}
                        id="pageSize"
                        required
                >
                    <option value={10}>
                        10
                    </option>
                    <option value={20}>
                        20
                    </option>
                    <option value={50}>
                        50
                    </option>
                    <option value={100}>
                        100
                    </option>
                </Select>
            </div>
        </div>
        <Pagination currentPage={pageNumber + 1}
                    onPageChange={(e) => {
                        setPageNumber(e - 1);
                    }}
                    showIcons
                    nextLabel={t("next", {ns: "common"}) || ""}
                    previousLabel={t("previous", {ns: "common"}) || ""}
                    totalPages={totalPages === 0 ? 1 : totalPages}/>
    </div>;
}