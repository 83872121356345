import {Button, Tooltip} from "flowbite-react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {HiCalendarDays} from "react-icons/hi2";
import AssessmentModal from "./AssessmentModal";

interface AssessmentCreateModalProps {
    subjectId: string;
}

export default function AssessmentCreateModal({subjectId} : AssessmentCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation("assessments");

    return (
        <>
            <Tooltip content={t("schedule")}>
                <Button className="" color="primary" pill onClick={() => setIsOpen(true)}>
                    <HiCalendarDays className="h-8 w-8"/>
                </Button>
            </Tooltip>
            <AssessmentModal isOpen={isOpen} setIsOpen={setIsOpen} subjectId={subjectId} />
        </>
    );
}