import {Button, Table} from "flowbite-react";
import {HiTrash, HiStop} from "react-icons/hi2";
import React from "react";
import UserAssertion from "../../../domain/UserAssertion";
import {useTranslation} from "react-i18next";
import {getCampusCityByCode} from "../../../domain/Campus";
import {parseDateToString} from "../../../components/utils/DateUtil";
import ShowIf from "../../../components/ConditionalShow/ShowIf";

interface AssertionsTableProps {
    assertions: UserAssertion[]
    removeAssertion: (index: number) => void;
    canRemove?: (assertion: UserAssertion) => boolean;
    canEnd?: (assertion: UserAssertion) => boolean;
}

export default function AssertionsTable({assertions, removeAssertion, canRemove, canEnd} : AssertionsTableProps) {
    const { t } = useTranslation("users");

    return (
        <Table>
            <Table.Head>
                <Table.HeadCell>
                    {t("assertionType")}
                </Table.HeadCell>
                <Table.HeadCell>
                    {t("role")}
                </Table.HeadCell>
                <Table.HeadCell>
                    {t("campus")}
                </Table.HeadCell>
                <Table.HeadCell>
                    {t("period")}
                </Table.HeadCell>
                <Table.HeadCell>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {assertions.map((assertion, index) => (
                    <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell
                            className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {t(assertion.assertion, {ns: "common"})}
                        </Table.Cell>
                        <Table.Cell>
                            {assertion.value}
                        </Table.Cell>
                        <Table.Cell>
                            {getCampusCityByCode(assertion.campusCode || "")}
                        </Table.Cell>
                        <Table.Cell>
                            {parseDateToString(assertion.startDate)} {parseDateToString(assertion.endDate)}
                        </Table.Cell>
                        <Table.Cell>
                            <ShowIf condition={canEnd ? canEnd(assertion) : false}>
                                <Button pill outline color="none"
                                        onClick={() => removeAssertion(index)}>
                                    <HiStop className="h-4 w-4"/>
                                </Button>
                            </ShowIf>
                            <ShowIf condition={canRemove ? canRemove(assertion) : true}>
                                <Button pill outline color="none"
                                        onClick={() => removeAssertion(index)}>
                                    <HiTrash className="h-4 w-4"/>
                                </Button>
                            </ShowIf>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}