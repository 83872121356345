import {RoleEnum} from "../../components/authentication/RoleEnum";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import AssessmentsManagement from "./parts/AssessmentsManagement";

export default function Assessments() {
    return (
        <RestrictedAccess authorizedRoles={[
            RoleEnum.STAFF_GM_IS,
            RoleEnum.STAFF_GM_ACADEMIC,
            RoleEnum.CM_HEAD,
            RoleEnum.STAFF_GM_QUALITY,
            RoleEnum.STAFF_GM_MANAGEMENT
            ]}>
            <AssessmentsManagement />
        </RestrictedAccess>
    );
}