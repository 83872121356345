import axios, {AxiosResponse} from "axios";
import Subject, {SubjectLight} from "../domain/Subject";

export function getSubject(subjectId: any): Promise<AxiosResponse<Subject>> {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getSubjects(): Promise<AxiosResponse<SubjectLight[]>> {
    const url = window._env_.ENROLMENT_API_URL + `subjects`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function createSubject(body: any): Promise<AxiosResponse<SubjectLight>> {
    const url = window._env_.ENROLMENT_API_URL + `subjects`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateSubject(subjectId: any, body: any): Promise<AxiosResponse<Subject>> {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}

export function deleteSubject(subjectId: any) {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };

    return axios.delete(url, config);
}