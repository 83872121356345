import UserAssertion from "../domain/UserAssertion";
import axios, {AxiosResponse} from "axios";

export function addAssertion(userId: any, assertion: UserAssertion) : Promise<AxiosResponse<UserAssertion[]>> {
    const url = window._env_.USERS_API_URL + `users/${userId}/assertions`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, assertion, config);
}

export function removeOrEndAssertion(userId: any, assertion: UserAssertion) : Promise<AxiosResponse<UserAssertion[]>> {
    const params = buildAssertionParams(assertion);
    const url = window._env_.USERS_API_URL + `users/${userId}/assertions?${params.join("&")}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}

function buildAssertionParams(assertion: UserAssertion) {
    const params : string[] = [];
    if (assertion.assertion !== undefined) {
        params.push("assertion=" + assertion.assertion);
    }
    if (assertion.value !== undefined) {
        params.push("value=" + assertion.value);
    }
    if (assertion.campusCode !== undefined) {
        params.push("campusCode=" + assertion.campusCode);
    }
    if (assertion.startDate !== undefined) {
        params.push("startDate=" + assertion.startDate);
    }
    if (assertion.endDate !== undefined) {
        params.push("endDate=" + assertion.endDate);
    }
    return params;
}