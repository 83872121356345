import axios, {AxiosResponse} from "axios";
import Question from "../domain/Question";

export function createQuestion(subjectId: any, body: any): Promise<AxiosResponse<Question>> {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}/questions`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateQuestion(subjectId: any, questionId: any, body: any): Promise<AxiosResponse<Question>> {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}/questions/${questionId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}

export function deleteQuestion(subjectId: any, questionId: any) {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}/questions/${questionId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}