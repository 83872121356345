import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {HiChevronRight, HiHome} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {getQuestionBanks} from "../api/questionBankConnector";
import LoadingComponent from "../../../components/LoadingComponent";
import {Breadcrumb, Button, Table} from "flowbite-react";
import {useNavigate, useParams} from "react-router-dom";
import QuestionBankManagement from "./QuestionBankManagement";
import {QuestionBankLight} from "../domain/QuestionBank";
import QuestionBankCreateModal from "../components/QuestionBankModals/QuestionBankCreateModal";

interface SubjectsTableProps {
    subjects: QuestionBankLight[];
    setSubjects: Dispatch<SetStateAction<QuestionBankLight[]>>
}

function SubjectsTable({subjects, setSubjects} : SubjectsTableProps) {
    const { t } = useTranslation("assessments");
    const navigate = useNavigate();

    return <Table className="divide-y">
        <Table.Head>
            <Table.HeadCell>
                {t("ectsCode")}
            </Table.HeadCell>
            <Table.HeadCell>
                {t("questionBankLabel")}
            </Table.HeadCell>
            <Table.HeadCell>
                <div className="w-full flex justify-end">
                    <QuestionBankCreateModal setSubjects={setSubjects} />
                </div>
            </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
            {subjects.length > 0 ? subjects.map((subject) => (
                <Table.Row key={subject.id}>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {subject.ectsCode}
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap text-gray-900 dark:text-white">
                        {subject.name}
                    </Table.Cell>
                    <Table.Cell className="flex justify-end py-2">
                        <Button color="primary" pill onClick={() => navigate("/assessments/questions-bank/" + (subject.id ?? ""))}>
                            <HiChevronRight className="h-5 w-5"/>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )) : <Table.Row><Table.Cell>{t("noQuestionBanksYet")}</Table.Cell></Table.Row>}
        </Table.Body>
    </Table>;
}

export default function QuestionBanksManagement() {
    const [isSubjectsLoading, setIsSubjectsLoading] = useState(true);
    const [selectedSubjectDrawer, setSelectedSubjectDrawer] = useState("");
    const [subjects, setSubjects] = useState<QuestionBankLight[]>([]);
    const { t } = useTranslation();
    const { throwError } = useAppContext();
    const { subjectId } = useParams();

    const reloadSubjects = useCallback(() => {
        getQuestionBanks().then(subjectsAxiosResponse => {
            setSubjects(subjectsAxiosResponse.data);
            setIsSubjectsLoading(false);
        }).catch(() => {
            throwError(t("getSubjectError"));
        });
    }, [t, throwError]);

    useEffect(() => {
        reloadSubjects();
    }, [reloadSubjects]);

    useEffect(() => {
        setSelectedSubjectDrawer(subjectId ?? "");
    }, [subjectId]);

    return (
        <div className="mx-4">
            <Breadcrumb className="mx-2 mb-3">
                <Breadcrumb.Item icon={HiHome}>
                    MyHexagone
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.assessments")}</Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.assessments.questionsBank")}</Breadcrumb.Item>
            </Breadcrumb>
            <LoadingComponent isLoading={isSubjectsLoading}>
                <SubjectsTable subjects={subjects} setSubjects={setSubjects} />
            </LoadingComponent>
            <QuestionBankManagement selectedSubjectDrawer={selectedSubjectDrawer} reloadSubjects={reloadSubjects} />
        </div>
    );
}