import axios, {AxiosResponse} from "axios";
import {User} from "../domain/User";
import Paginated from "../../../domain/Paginated";

export function getUser(id: string): Promise<AxiosResponse<User>> {
    const url = window._env_.USERS_API_URL + `users/` + id;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getUsersPaginated(page: number, size: number, searchCriteria: string): Promise<AxiosResponse<Paginated<User>>> {
    const url = window._env_.USERS_API_URL + `users?page=${page}&size=${size}${searchCriteria !== "" ? "&search=" + searchCriteria : ""}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function createUser(body: User): Promise<AxiosResponse<User>> {
    const url = window._env_.USERS_API_URL + `users`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateUser(id: any, body: any): Promise<AxiosResponse<User>> {
    const url = window._env_.USERS_API_URL + `users/${id}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}