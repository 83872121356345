import React, {Dispatch, FormEvent, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import QuestionTypeSelect from "./QuestionTypeSelect";
import WriteInput from "../../../../components/Inputs/WriteInput";
import MarkdownEditor from "../../../../components/Markdown/MarkdownEditor";

interface QuestionFormProps {
    submit: (event: FormEvent<HTMLFormElement>) => void;
    type: string;
    setType: Dispatch<SetStateAction<string>>;
    title: string;
    setTitle: Dispatch<SetStateAction<string>>,
    description: string;
    setDescription: Dispatch<SetStateAction<string>>,
    time: number;
    setTime: Dispatch<SetStateAction<number>>
}

export default function QuestionForm({submit, type, setType, title, setTitle, description, setDescription, time, setTime}: QuestionFormProps) {
    const {t} = useTranslation("assessments");

    return (
            <form onSubmit={submit} id="questionForm">
                <QuestionTypeSelect type={type} setType={setType}  />
                <WriteInput label="questionLabel" value={title} setter={setTitle} t={t} required />
                <MarkdownEditor
                    t={t}
                    label="questionContentOptional"
                    value={description}
                    onChange={v => setDescription(v || "")}/>
                <WriteInput
                    type="number"
                    label="time" value={time}
                    helperText={t("zeroValueQuestionIsConsideredAsUnlimitedTime")}
                    setter={v => setTime(parseInt(v))} t={t}
                    min={0}
                    required/>
            </form>
    );

}