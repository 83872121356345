import {Card} from "flowbite-react";
import MarkdownPreview from "../../../components/Markdown/MarkdownPreview";
import AnswerAccordion from "./AnswerAccordion";
import React, {Dispatch, SetStateAction} from "react";
import Question from "../domain/Question";
import {useTranslation} from "react-i18next";
import QuestionUpdateModal from "./QuestionModals/QuestionUpdateModal";
import QuestionDeleteModal from "./QuestionModals/QuestionDeleteModal";
import Answer from "../domain/Answer";

interface QuestionCardProps {
    question: Question;
    setQuestions: Dispatch<SetStateAction<Question[]>>;
    parentId: string;
    handleUpdate: (updatableQuestion: Question, type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) => void;
    handleDelete: (questionId: string, setQuestions: Dispatch<SetStateAction<Question[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) => void;
    handleAnswerUpdate: (parentId: string, updatableAnswer: Answer, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) => void;
    handleAnswerCreate: (parentId: string, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) => void;
    handleAnswerDelete: (parentId: string, answerId: number, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) => void;
}

export default function QuestionCard({question, setQuestions, handleUpdate, handleDelete, handleAnswerCreate, handleAnswerUpdate, handleAnswerDelete} : QuestionCardProps) {
    const { t } = useTranslation("assessments");

    return (
        <Card key={question.id} className="my-4">
            <div className="flex justify-between">
                <div className="flex">
                    <h5 className="font-bold text-2xl">{question.title}&nbsp;</h5>
                </div>
                <div className="inline-flex space-x-1">
                    <QuestionUpdateModal updatableQuestion={question} setQuestions={setQuestions} handleUpdate={handleUpdate} />
                    <QuestionDeleteModal setQuestions={setQuestions} handleDelete={handleDelete} questionId={question.id} />
                </div>
            </div>
            <MarkdownPreview source={question.description}/>
            <div className="flex mb-2">
                {question.time > 0 ?
                    <span className="rounded-3xl border-2 p-1">{question.time} {t("seconds")}</span> : ""}
                <span className="rounded-3xl border-2 p-1">{t(question.type.toString())}</span>
            </div>
            {question.type !== "OPEN" ? (
                <AnswerAccordion question={question} handleCreate={handleAnswerCreate} handleUpdate={handleAnswerUpdate} handleDelete={handleAnswerDelete} />
            ) : ""}
        </Card>
    );
}