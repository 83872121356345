import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enCommon from "./en/common.json";
import enDashboard from "./en/dashboard.json";
import enUsers from "./en/users.json";
import enTools from "./en/tools.json";
import enAssessments from "./en/assessments.json";
import arCommon from "./ar/common.json";
import arDashboard from "./ar/dashboard.json";
import arUsers from "./ar/users.json";
import arTools from "./ar/tools.json"
import frCommon from "./fr/common.json";
import frDashboard from "./fr/dashboard.json";
import frUsers from "./fr/users.json";
import frTools from "./fr/tools.json"
import frEnrolmentTests from "./fr/enrolment-tests.json";
import frAssessments from "./fr/assessments.json";

export const defaultNS = "common";

i18n.use(initReactI18next).init({
    lng: navigator.language.slice(0, 2),
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            assessments: enAssessments,
            common: enCommon,
            dashboard: enDashboard,
            users : enUsers,
            tools: enTools,
        },
        fr: {
            common: frCommon,
            dashboard: frDashboard,
            users: frUsers,
            tools: frTools,
            enrolmentTests: frEnrolmentTests,
            assessments: frAssessments,
        },
        ar: {
            common: arCommon,
            dashboard: arDashboard,
            users: arUsers,
            tools: arTools,
        }
    },
    defaultNS: defaultNS
});

export default i18n;