export enum RoleEnum {
    TEACHER = "TEACHER",
    STAFF = "STAFF",
    STAFF_GM = "STAFF/GM",
    STAFF_GM_IS = "STAFF/GM/IS",
    STAFF_GM_ACADEMIC = "STAFF/GM/ACADEMIC",
    STAFF_GM_QUALITY = "STAFF/GM/QUALITY",
    STAFF_GM_MANAGEMENT = "STAFF/GM/MANAGEMENT",
    STUDENT = "STUDENT",
    CM_HEAD = "CM_HEAD",
}