import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "flowbite-react";
import React, {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {restartInstance} from "../../api/assessmentInstanceConnector";
import {useAppContext} from "../../../../components/AppContext/AppContext";

interface AssessmentInstanceModalProps {
    code: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    reload: () => void;
}

export default function AssessmentInstanceRestartModal({code, isOpen, setIsOpen, reload}: AssessmentInstanceModalProps) {
    const { t } = useTranslation("assessments");
    const { throwError } = useAppContext();

    function submit() {
        restartInstance(code).then(reload).catch(() => throwError(t("restartInstanceError")));
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <ModalHeader>
                {t("restartAssessmentInstance")}
            </ModalHeader>
            <ModalBody>
                {t("confirmRestartAssessmentInstance")}
            </ModalBody>
            <ModalFooter className="flex justify-between">
                <Button color="primary" onClick={() => setIsOpen(false)}>
                    {t("cancel", {ns: "common"})}
                </Button>
                <Button color="secondary" onClick={submit}>
                    {t("restart")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}