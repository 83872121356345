import {Quiz, QuizInstruction} from "../../domain/Quiz";
import QuestionCard from "../../components/QuestionCard";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import Question from "../../domain/Question";
import {createQuestion, deleteQuestion, updateQuestion} from "../../api/questionConnector";
import Answer from "../../domain/Answer";
import {createAnswer, deleteAnswer, updateAnswer} from "../../api/answerConnector";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import QuestionCreateModal from "../../components/QuestionModals/QuestionCreateModal";
import ShowIf from "../../../../components/ConditionalShow/ShowIf";
import InstructionCreateModal from "../../components/InstructionModals/InstructionCreateModal";
import InstructionCard from "../../components/InstructionCard";

interface QuizManagementProps {
    quiz: Quiz;
    canBeUpdated: boolean;
}

export default function QuizManagement({quiz, canBeUpdated}: QuizManagementProps) {
    const [questions, setQuestions] = React.useState<Question[]>([]);
    const [instructions, setInstructions] = React.useState<QuizInstruction[]>([]);
    const { t } = useTranslation("assessments");
    const { throwError } = useAppContext();

    useEffect(() => {
        if (quiz && quiz.questions) {
            setQuestions(quiz.questions);
        }
        if (quiz && quiz.instructions) {
            setInstructions(quiz.instructions);
        }
    }, [quiz]);

    function handleQuestionUpdate(updatableQuestion: Question, type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) {
        updateQuestion(quiz.id, updatableQuestion.id, {
            type,
            title,
            description,
            time
        }).then(questionAxiosResponse => {
            handleClose();
            if (setQuestions) {
                setQuestions(prevState => {
                    let newState = [...prevState];
                    const questionIndex = newState.findIndex(q => q.id === questionAxiosResponse.data.id);

                    if (questionIndex !== -1) {
                        newState[questionIndex] = {...newState[questionIndex], ...questionAxiosResponse.data};
                    }
                    return newState;
                });
            }
        }).catch(() => throwError(t("updateQuestionError")));
    }

    function handleQuestionCreate(type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) {
        createQuestion(quiz.id, {
            type,
            title,
            description,
            time
        }).then(questionAxiosResponse => {
            handleClose();
            if (setQuestions) {
                setQuestions(prevState => prevState.concat(questionAxiosResponse.data));
            }
        }).catch(() => throwError(t("createQuestionError")));
    }

    function handleQuestionDelete(questionId: string, setQuestions: Dispatch<SetStateAction<Question[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) {
        deleteQuestion(quiz.id, questionId)
            .then(() => {
                setQuestions(prevState => {
                    let newState = [...prevState];
                    const questionIndex = newState.findIndex(q => q.id === questionId);

                    if (questionIndex !== -1) {
                        newState.splice(questionIndex, 1);
                    }
                    return newState;
                });
                setIsOpen(false);
            })
            .catch(() => throwError(t("deleteQuestionError")));
    }

    function handleAnswerUpdate(questionId: string, updatableAnswer: Answer, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) {
        updateAnswer(quiz.id, questionId, updatableAnswer?.id, {
            content, isValid
        }).then(answerAxiosResponse => {
            setIsOpen(false);
            setAnswers(prevState => {
                let newState = [...prevState];
                const answerIndex = newState.findIndex(a => a.id === answerAxiosResponse.data.id);

                if (answerIndex !== -1) {
                    newState[answerIndex] = {...newState[answerIndex], ...answerAxiosResponse.data};
                }
                return newState;
            });
            setIsValid(false);
            setContent("");
        }).catch(() => throwError(t("updateAnswerError")));
    }

    function handleAnswerCreate(questionId: string, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) {
        createAnswer(quiz.id, questionId, {
            content, isValid
        }).then(answerAxiosResponse => {
            setIsOpen(false);
            setAnswers(prevState => prevState.concat(answerAxiosResponse.data));
            setIsValid(false);
            setContent("");
        }).catch(() => throwError(t("createAnswerError")));
    }

    function handleAnswerDelete(questionId: string, answerId: number, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) {
        deleteAnswer(quiz.id, questionId, answerId)
            .then(() => {
                setAnswers(prevState => {
                    let newState = [...prevState];
                    const answerIndex = newState.findIndex(a => a.id === answerId);

                    if (answerIndex !== -1) {
                        newState.splice(answerIndex, 1);
                    }
                    return newState;
                });
                setIsOpen(false);
            })
            .catch(() => throwError(t("deleteAnswerError")));
    }

    return (
        <div>
            <div className="my-4">
                <ShowIf condition={quiz.time !== undefined && quiz.time !== null && quiz.time > 0}>
                    <span
                        className="rounded-3xl border-2 p-1.5 italic">{t("time") + " : " + quiz.time / 60 + " " + t("minutes")}</span>
                </ShowIf>
                <ShowIf condition={quiz.alerts !== undefined && quiz.alerts !== null && quiz.alerts >= 0}>
                    <span
                        className="rounded-3xl border-2 p-1.5 italic">{t("antiCheatActivated") + " (" + quiz.alerts + " " + t("alerts") + ")"}</span>
                </ShowIf>
            </div>
            <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700"/>
            <span className="inline-flex w-full items-center flex-wrap sm:flex-nowrap">
                <h3 className="w-full text-lg font-semibold">{t("questionBanks")}</h3>
                <ShowIf condition={canBeUpdated}>
                    <InstructionCreateModal quizId={quiz.id} instructions={instructions} setInstructions={setInstructions} />
                </ShowIf>
            </span>
            <div className="px-2">
                {instructions.length > 0 ? instructions.map(instruction =>
                    <InstructionCard key={instruction.questionBank.id} instruction={instruction}
                                     setInstructions={setInstructions} quizId={quiz.id} canBeUpdated={canBeUpdated} />
                ) : <span className="mt-4">{t("noQuizInstructionsYet")}</span>}
            </div>
            <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700"/>
            <span className="inline-flex w-full items-center flex-wrap sm:flex-nowrap">
                <h3 className="w-full text-lg font-semibold">{t("manualQuestions")}</h3>
                <ShowIf condition={canBeUpdated}>
                    <QuestionCreateModal label={"addManualQuestion"} setQuestions={setQuestions}
                                         handleCreate={handleQuestionCreate} />
                </ShowIf>
            </span>
            <div className="px-2">
                {questions.length > 0 ? questions.map(question =>
                    <QuestionCard key={question.id} question={question} setQuestions={setQuestions} parentId={quiz.id}
                                  handleUpdate={handleQuestionUpdate} handleDelete={handleQuestionDelete}
                                  handleAnswerCreate={handleAnswerCreate} handleAnswerUpdate={handleAnswerUpdate}
                                  handleAnswerDelete={handleAnswerDelete}/>
                ) : <span className="mt-4">{t("noQuizQuestionsYet")}</span>}
            </div>
        </div>
    );
}