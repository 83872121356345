import {Quiz} from "./Quiz";
import Duty from "./Duty";

export default interface Subject {
    id: number;
    title: string;
    ectsCode: string;
    authorName: string;
    authorUsername: string;
    isPublished: boolean;
    quiz: Quiz;
    duty: Duty;
}

export interface SubjectLight {
    id: number;
    title: string;
    ectsCode: string;
    authorName: string;
    authorUsername: string;
    isPublished: boolean;
    type: SubjectTypeEnum;
}

export enum SubjectTypeEnum {
    QUIZ= "QUIZ",
    DUTY = "DUTY",
}