import React from "react";
import {useAppContext} from "../../AppContext/AppContext";
import {Button} from "flowbite-react";
import {HiOutlineMenu} from "react-icons/hi";
import {useNavigate} from "react-router-dom";

interface NavbarProps {
    setDrawerOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
}

export default function Navbar({setDrawerOpen} : NavbarProps) {
    const {fullName} = useAppContext();
    const navigate = useNavigate();

    function goToHome(event : any) {
        event.preventDefault();
        navigate("/");
    }

    return (
        <nav
            className="mx-auto max-w-screen-xl bg-hexagone-purple border-gray-200 text-hexagone-light-grey rounded-lg">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <Button color="primary" onClick={() => setDrawerOpen(true)}>
                    <HiOutlineMenu size="20"/>
                </Button>
                <a href="/" onClick={goToHome} className="flex items-center">
                    <img
                        src="/logo-small.svg"
                        className="me-3 h-9"
                        alt="École Hexagone logo"
                    />
                    <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                        MyHexagone
                    </span>
                </a>
                <div className="flex">
                    <div className="hidden md:flex mx-2 self-center">
                        {fullName}
                    </div>
                </div>
            </div>
        </nav>
    );
}