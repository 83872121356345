import {HiCheck, HiPlus} from "react-icons/hi2";
import {Button, Label, Modal, Select} from "flowbite-react";
import React, {FormEvent, useState} from "react";
import UserAssertion, {Assertion, findAssertion} from "../../../domain/UserAssertion";
import {useTranslation} from "react-i18next";
import {Campus, campuses, getCampusByCode, VERSAILLES} from "../../../domain/Campus";
import {useAppContext} from "../../../components/AppContext/AppContext";

interface AddAssertionModalProps {
    addAssertion: (assertion: UserAssertion) => Promise<unknown>;
    className?: string;
}

export default function AddAssertionModal({addAssertion, className}: AddAssertionModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation("users");
    const {throwError} = useAppContext();

    const [assertionType, setAssertionType] = useState<Assertion>(Assertion.STUDENT);
    const [campus, setCampus] = useState<Campus>(VERSAILLES);
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 8, 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear() + 1, 9, 31));
    const [role] = useState("");

    function getAcademicYearOptions() {
        let initialYear = new Date().getFullYear() - 1
        let options = [];
        for (let i = 0; i < 3; i++) {
            options.push(initialYear + i);
        }
        return options;
    }

    function setDateFromAcademicYear(year: number) {
        let startDate = new Date();
        let endDate = new Date();
        startDate.setFullYear(year, 8, 1);
        endDate.setFullYear(year + 1, 9, 31);
        setStartDate(startDate);
        setEndDate(endDate);
    }

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        addAssertion({
            assertion: assertionType,
            campusCode: campus.code,
            value: role,
            startDate: startDate,
            endDate: endDate
        }).then(() => setIsOpen(false))
            .catch((e) => throwError(e.message));
    }

    return (
        <div className={"me-2 " + className}>
            <Button color="light" fullSized onClick={() => setIsOpen(true)}>
                <p>{t("addAssertion")}</p>
                <HiPlus className="ml-2 h-5 w-5"/>
            </Button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <Modal.Header>
                    {t("addAssertion")}
                </Modal.Header>
                <Modal.Body className="space-y-4">
                    <form onSubmit={submit} id="assertionForm">
                        <div>
                            <Label htmlFor="assertionType" value="Type d'assignation*"/>
                            <Select value={assertionType}
                                    onChange={(e) => setAssertionType(findAssertion(e.target.value))}
                                    id="assertionType"
                                    required
                            >
                                <option disabled value="">
                                    Sélectionnez un type d'assignation
                                </option>
                                {Object.values(Assertion).map(assertion => (
                                    <option key={assertion} value={assertion}
                                            disabled={assertion === Assertion.STAFF}>{t(assertion, {ns: "common"})}</option>
                                ))}
                            </Select>
                        </div>
                        {(assertionType === Assertion.STUDENT || assertionType === Assertion.TEACHER) && (
                            <div>
                                <Label htmlFor="campus" value="Campus d'assignation*"/>
                                <div>
                                    <Select value={campus.code}
                                            onChange={(e) => setCampus(getCampusByCode(e.target.value) || VERSAILLES)}
                                            id="campus"
                                            required
                                    >
                                        <option disabled value="">
                                            Sélectionnez un campus
                                        </option>
                                        {campuses.map(campus => (
                                            <option key={campus.code} value={campus.code}>
                                                {campus.country + "/" + campus.city}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        )}
                        {(assertionType === Assertion.STUDENT || assertionType === Assertion.TEACHER) && (
                            <div>
                                <Label htmlFor="year" value="Année académique*"/>
                                <div>
                                    <Select value={startDate.getFullYear()}
                                            onChange={(e) => setDateFromAcademicYear(parseInt(e.target.value))}
                                            id="year"
                                            required
                                    >
                                        <option disabled value={0}>
                                            Sélectionnez une année académique
                                        </option>
                                        {getAcademicYearOptions().map(year => (
                                            <option key={year} value={year}>
                                                {year} - {year + 1}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer className="justify-end">
                    <Button type="submit" color="success" form="assertionForm">
                        <p>Ajouter l'assignation</p>
                        <HiCheck className="ml-2 h-5 w-5"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}