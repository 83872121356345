import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {Button, Modal} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import WriteInput from "../../../../components/Inputs/WriteInput";
import Quiz, {QuizLight} from "../../domain/Quiz";
import {createQuiz, updateQuiz} from "../../api/quizConnector";

interface QuizModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setQuizzes?: Dispatch<SetStateAction<QuizLight[]>>;
    setUpdatableQuiz?: Dispatch<SetStateAction<Quiz | undefined>>;
    updatableQuiz?: Quiz;
}

export default function QuizModal({isOpen, setIsOpen, setQuizzes, updatableQuiz, setUpdatableQuiz}: QuizModalProps) {
    const {t} = useTranslation("enrolmentTests");
    const {throwError} = useAppContext();
    const [title, setTitle] = useState("");
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (updatableQuiz) {
            setTime(updatableQuiz.time);
            setTitle(updatableQuiz.title);
        }
    }, [updatableQuiz]);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (updatableQuiz) {
            updateQuiz(updatableQuiz.id, {
                title,
                time
            }).then(quizAxiosResponse => {
                setIsOpen(false);
                if (setQuizzes) {
                    setQuizzes(prevState => {
                        let newState = [...prevState];
                        const questionIndex = newState.findIndex(q => q.id === quizAxiosResponse.data.id);

                        if (questionIndex !== -1) {
                            newState[questionIndex] = {...newState[questionIndex], ...quizAxiosResponse.data};
                        }
                        return newState;
                    })
                }
                if (setUpdatableQuiz) {
                    setUpdatableQuiz(quizAxiosResponse.data);
                }
            }).catch(() => throwError(t("updateQuizError")));
        } else {
            createQuiz({
                title,
                time
            }).then(quizAxiosResponse => {
                setIsOpen(false);
                if (setQuizzes) {
                    setQuizzes(prevState => prevState.concat(quizAxiosResponse.data));
                }
            }).catch(() => throwError(t("createQuizError")));
        }
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                {updatableQuiz ? t("updateQuiz") : t("createQuiz")}
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submit} id="quizForm">
                    <WriteInput label="quizTitle" value={title} setter={setTitle} t={t} required/>
                    <WriteInput
                        type="number"
                        label="time" value={time}
                        setter={v => setTime(parseInt(v))} t={t}
                        required/>
                </form>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="quizForm">
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );

}