import React from "react";
import {useAuthenticationContext} from "./AuthenticationContext";

export default function AuthenticatedPage({children}: { children: React.ReactNode }) {

    const {loggedIn} = useAuthenticationContext();

    return (
        <div>
            {loggedIn && children}
        </div>
    )

}