import axios, {AxiosResponse} from "axios";
import Answer from "../domain/Answer";

export function deleteAnswer(quizId: any, questionId: any, answerId: any): Promise<AxiosResponse<void>> {
    const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/questions/${questionId}/answers/${answerId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}

export function createAnswer(quizId: any, questionId: any, body: any): Promise<AxiosResponse<Answer>> {
 const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/questions/${questionId}/answers`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateAnswer(quizId: any, questionId: any, answerId: any, body: any): Promise<AxiosResponse<Answer>> {
    const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/questions/${questionId}/answers/${answerId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}