import React, {Dispatch, SetStateAction, useState} from "react";
import Question from "../../domain/Question";
import {HiPencil} from "react-icons/hi2";
import QuestionModal from "./QuestionModal";

interface QuestionUpdateModalProps {
    setQuestions: Dispatch<SetStateAction<Question[]>>;
    updatableQuestion: Question;
    handleUpdate: (updatableQuestion: Question, type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) => void;
}

export default function QuestionUpdateModal({setQuestions, updatableQuestion, handleUpdate} : QuestionUpdateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                onClick={() => setIsOpen(true)}>
                <HiPencil color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <QuestionModal isOpen={isOpen}
                           setIsOpen={setIsOpen}
                           setQuestions={setQuestions}
                           updatableQuestion={updatableQuestion}
                           handleUpdate={handleUpdate} />
        </>
    );
}