import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {Button, Modal, ToggleSwitch} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import WriteInput from "../../../../components/Inputs/WriteInput";
import Answer from "../../domain/Answer";
import {createAnswer, updateAnswer} from "../../api/answerConnector";

interface AnswerModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    subjectId: string;
    questionId: number;
    setAnswers: Dispatch<SetStateAction<Answer[]>>;
    updatableAnswer?: Answer;
}

export default function AnswerModal({isOpen, setIsOpen, subjectId, questionId, setAnswers, updatableAnswer}: AnswerModalProps) {
    const {t} = useTranslation("enrolmentTests");
    const {throwError} = useAppContext();
    const [content, setContent] = useState("");
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (updatableAnswer) {
            setContent(updatableAnswer.content);
            setIsValid(updatableAnswer.isValid);
        }
    }, [updatableAnswer]);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (updatableAnswer) {
            updateAnswer(subjectId, questionId, updatableAnswer?.id, {
                content, isValid
            }).then(answerAxiosResponse => {
                setIsOpen(false);
                setAnswers(prevState => {
                    let newState = [...prevState];
                    const answerIndex = newState.findIndex(a => a.id === answerAxiosResponse.data.id);

                    if (answerIndex !== -1) {
                        newState[answerIndex] = {...newState[answerIndex], ...answerAxiosResponse.data};
                    }
                    return newState;
                });
                setIsValid(false);
                setContent("");
            }).catch(() => throwError(t("updateAnswerError")));
        } else {
            createAnswer(subjectId, questionId, {
                content, isValid
            }).then(answerAxiosResponse => {
                setIsOpen(false);
                setAnswers(prevState => prevState.concat(answerAxiosResponse.data));
                setIsValid(false);
                setContent("");
            }).catch(() => throwError(t("createAnswerError")));
        }
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                {updatableAnswer ? t("updateAnswer") : t("createAnswer")}
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submit} id="answerForm">
                    <WriteInput label="answerContent" value={content} setter={setContent} t={t} required />
                    <ToggleSwitch color="success" className="mt-2" checked={isValid} label={t("isAnswerValid")} onChange={setIsValid} />
                </form>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="answerForm">
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );

}