import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import LoadingComponent from "../../../components/LoadingComponent";
import React, {useCallback, useEffect, useState} from "react";
import {getAssessments} from "../api/assessmentConnector";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {AssessmentLight} from "../domain/Assessment";
import AssessmentManagement from "./AssessmentManagement";
import {useParams} from "react-router-dom";
import {isAfter, isToday} from "../../../components/utils/DateUtil";
import AssessmentCard from "../components/AssessmentCard";

export default function AssessmentsManagement() {
    const { t } = useTranslation("assessments");
    const { throwError } = useAppContext();
    const [isAssessmentsLoading, setIsAssessmentsLoading] = useState(true);
    const [todayAssessments, setTodayAssessments] = useState<AssessmentLight[]>([]);
    const [pastAssessments, setPastAssessments] = useState<AssessmentLight[]>([]);
    const [upcomingAssessment, setUpcomingAssessments] = useState<AssessmentLight[]>([]);
    const { assessmentId } = useParams();

    const reloadAssessments = useCallback(() => {
        getAssessments().then(assessmentsAxiosResponse => {
            setTodayAssessments(assessmentsAxiosResponse.data.filter(assessment => {
                const now = new Date();
                const startToday = isToday(assessment.startDate);
                const endToday = isToday(assessment.endDate);
                return (startToday || endToday || (isAfter(now, assessment.startDate) && isAfter(assessment.endDate, now)));
            }));

            setPastAssessments(assessmentsAxiosResponse.data.filter(assessment => {
                return isAfter(new Date(), assessment.endDate) && !isToday(assessment.endDate);
            }));

            setUpcomingAssessments(assessmentsAxiosResponse.data.filter(assessment => {
                return isAfter(assessment.startDate, new Date()) && !isToday(assessment.startDate);
            }));
            setIsAssessmentsLoading(false);
        }).catch(() => {
            throwError(t("getSubjectError"));
        });
    }, [t, throwError])

    useEffect(() => {
        reloadAssessments();
    }, [reloadAssessments]);

    return (
        <div className="mx-4">
            <Breadcrumb className="mx-2 mb-3">
                <Breadcrumb.Item icon={HiHome}>
                    MyHexagone
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.assessments", {ns: "common"})}</Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.assessments.assessments", {ns: "common"})}</Breadcrumb.Item>
            </Breadcrumb>
            <LoadingComponent isLoading={isAssessmentsLoading}>
                <div>
                    <h3 className="text-lg font-bold mb-2 mt-4">{t("today")}</h3>
                    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-2">
                        {todayAssessments.length > 0 ? todayAssessments.map(assessment => <AssessmentCard assessment={assessment} key={assessment.id} />) : t("noAssessmentYet")}
                    </div>
                    <div className="grid md:grid-cols-2 gap-4">
                        <div>
                            <h3 className="text-lg font-bold mb-2 mt-4">{t("past")}</h3>
                            <div className="grid gap-2">
                                {pastAssessments.length > 0 ? pastAssessments.map(assessment => <AssessmentCard assessment={assessment} key={assessment.id} />) : t("noAssessmentYet")}
                            </div>
                        </div>
                        <div>
                            <h3 className="text-lg font-bold mb-2 mt-4">{t("upcoming")}</h3>
                            <div className="grid gap-2">
                                {upcomingAssessment.length > 0 ? upcomingAssessment.map(assessment => <AssessmentCard assessment={assessment} key={assessment.id} />) : t("noAssessmentYet")}
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingComponent>
            <AssessmentManagement selectedAssessmentDrawer={assessmentId || ""} />
        </div>
    );
}