import UserAssertion from "../../../domain/UserAssertion";
import {compareDate} from "../../../components/utils/DateUtil";
import {User} from "../../../domain/User";

/**
 * Compare two assertion
 * Return true if assertions are equals, false otherwise.
 * @param assertion
 * @param assertionToCompare
 * @return boolean
 */
export function compareAssertion(assertion: UserAssertion, assertionToCompare: UserAssertion): boolean {
    return (assertionToCompare.assertion === assertion.assertion &&
        assertionToCompare.campusCode === assertion.campusCode &&
        assertionToCompare.value === assertion.value &&
        compareDate(assertionToCompare.endDate, assertion.endDate) &&
        compareDate(assertionToCompare.startDate, assertion.startDate));
}

export function isRestricted(user: User | undefined) {
    return user?.assertions && user?.assertions.filter(a => a.assertion === 'STAFF').length > 0;
}