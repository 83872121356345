import axios, {AxiosResponse} from "axios";
import {QuizInstruction} from "../domain/Quiz";

export function addInstruction(quizId: any, body: any): Promise<AxiosResponse<QuizInstruction>> {
    const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/instructions`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function deleteInstruction(quizId: any, questionBankId: any) {
    const url = window._env_.ASSESSMENTS_API_URL + `quizzes/${quizId}/instructions?questionBankId=${questionBankId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}