import React, {useEffect, useState} from "react";
import WriteInput from "../../../../components/Inputs/WriteInput";
import {useTranslation} from "react-i18next";
import {QuizLight} from "../../domain/Quiz";
import {ToggleSwitch} from "flowbite-react";
import ShowIf from "../../../../components/ConditionalShow/ShowIf";

interface QuizFormParams {
    submit: (params: {event: React.FormEvent<HTMLFormElement>, quiz?: QuizLight}) => void;
    initialTime?: number;
    initialAlerts?: number;
}

export default function QuizForm({submit, initialTime, initialAlerts}: Readonly<QuizFormParams>) {
    const { t } = useTranslation("assessments");
    const [isTimeLimited, setIsTimeLimited] = useState(false);
    const [isAntiCheatActivated, setIsAntiCheatActivated] = useState(true);
    const [time, setTime] = useState(60);
    const [alerts, setAlerts] = useState(0);

    useEffect(() => {
        if (initialTime) {
            if (initialTime > 0) {
                setIsTimeLimited(true);
                setTime(initialTime);
            } else {
                setIsTimeLimited(false);
            }
        }
        if (initialAlerts) {
            if (initialAlerts > -1) {
                setIsAntiCheatActivated(true);
                setAlerts(initialAlerts);
            } else {
                setIsAntiCheatActivated(false);
            }
        }
    }, [initialTime, initialAlerts]);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        submit({event: e, quiz: {time: isTimeLimited ? time : 0, alerts: isAntiCheatActivated ? alerts : -1}});
    }

    return <form onSubmit={handleSubmit} id="subjectContentForm">
        <ToggleSwitch label={t("isTimeLimited")} checked={isTimeLimited} onChange={setIsTimeLimited} />
        <ShowIf condition={isTimeLimited}>
            <WriteInput
                type="number"
                label="timeMinutes" value={time/60}
                setter={v => setTime(parseInt(v)*60)} t={t}
                min={1} />
        </ShowIf>
        <ToggleSwitch className="mt-2" label={t("isAntiCheatActivated")} checked={isAntiCheatActivated} onChange={setIsAntiCheatActivated} />
        <ShowIf condition={isAntiCheatActivated}>
            <WriteInput
                type="number"
                label="alertsBeforeLock" value={alerts}
                setter={v => setAlerts(parseInt(v))} t={t}
                min={0} />
        </ShowIf>
    </form>;
}