import {Card, Dropdown} from "flowbite-react";
import Assessment, {AssessmentInstanceLight, AssessmentStateEnum} from "../domain/Assessment";
import ShowIf from "../../../components/ConditionalShow/ShowIf";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {HiMiniPlayPause, HiOutlineEllipsisHorizontal, HiTrash} from "react-icons/hi2";
import AbstractDropdown from "../../../components/AbstractDropdown";
import AssessmentInstanceResetModal from "./AssessmentInstanceModals/AssessmentInstanceResetModal";
import AssessmentInstanceRestartModal from "./AssessmentInstanceModals/AssessmentInstanceRestartModal";
import AssessmentInstanceDeleteModal from "./AssessmentInstanceModals/AssessmentInstanceDeleteModal";
import {HiRefresh} from "react-icons/hi";
import {SubjectTypeEnum} from "../domain/Subject";
import {getDutyResponse} from "../api/dutyConnector";
import {useAppContext} from "../../../components/AppContext/AppContext";

interface AssessmentInstanceCardProps {
    instance: AssessmentInstanceLight;
    assessment: Assessment | undefined;
    reload: () => void;
}

export default function AssessmentInstanceCard({instance, reload, assessment}: AssessmentInstanceCardProps) {
    const [isResetModalOpen, setIsResetOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteOpen] = useState(false);
    const [isRestartModalOpen, setIsRestartOpen] = useState(false);
    const { t } = useTranslation("assessments");
    const { throwError } = useAppContext();

    const accessQuiz = useCallback(() => {
        if (instance.state !== AssessmentStateEnum.PENDING) {
            const url = window._env_.ADMIN_QUIZ_URL + "/" + instance.id;
            window.open(url, "_blank", "noreferrer");
        }
    }, [instance.id, instance.state]);

    const accessDuty = useCallback(() => {
        if (instance.state === AssessmentStateEnum.DONE_UPDATABLE) {
            getDutyResponse(instance.code)
                .then(urlAxiosResponse => {
                    window.open(urlAxiosResponse.data, "_blank", "noreferrer");
                })
                .catch(() => throwError(t("getDutyResponseError")));
        }
    }, [instance.code, instance.state, throwError, t]);

    const accessAssessment = useCallback(() => {
        if (assessment) {
            if (assessment.subject.type === SubjectTypeEnum.QUIZ) {
                accessQuiz();
            } else if (assessment.subject.type === SubjectTypeEnum.DUTY) {
                accessDuty();
            }
        }
    }, [assessment, accessDuty, accessQuiz]);

    return (
        <div className="flex w-full">
            <Card
                className={`w-full ${(instance.state === AssessmentStateEnum.DONE || instance.state === AssessmentStateEnum.DONE_UPDATABLE) && "bg-green-600 hover:bg-green-700 text-white cursor-pointer"}
            ${instance.state === AssessmentStateEnum.NEED_CORRECTION && "bg-orange-600 hover:bg-orange-700 text-white cursor-pointer"}
            ${instance.state === AssessmentStateEnum.STARTED && "bg-blue-600 hover:bg-blue-700 text-white cursor-pointer"}
            ${instance.state === AssessmentStateEnum.EXPIRED && "bg-red-600 hover:bg-red-700 text-white cursor-pointer"}
            ${instance.state === AssessmentStateEnum.READY && "hover:bg-hexagone-light-grey cursor-pointer"}`}
                onClick={accessAssessment}
                key={instance.code}>
                <div className="flex items-center">
                    <p className="w-full">
                        {instance.student.firstName} {instance.student.lastName}
                    </p>
                    <div className="w-min">
                        <ShowIf condition={instance.state === AssessmentStateEnum.DONE}>
                            <span className="font-bold">{instance.score}/20</span>
                        </ShowIf>
                        <ShowIf condition={instance.state !== AssessmentStateEnum.DONE}>
                            <span>{t(instance.state)}</span>
                        </ShowIf>
                    </div>
                </div>
            </Card>
            <AssessmentInstanceResetModal code={instance.code} isOpen={isResetModalOpen} setIsOpen={setIsResetOpen} reload={reload} />
            <AssessmentInstanceRestartModal code={instance.code} isOpen={isRestartModalOpen} setIsOpen={setIsRestartOpen} reload={reload} />
            <AssessmentInstanceDeleteModal code={instance.code} isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteOpen} reload={reload} />
            <AbstractDropdown renderTrigger={() =>
                <button className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full">
                    <HiOutlineEllipsisHorizontal color={"rgba(0,0,0,0.66)"} size="20" />
                </button>
            } content={
                <>
                    <ShowIf condition={assessment?.subject.type === SubjectTypeEnum.QUIZ}>
                        <Dropdown.Item onClick={() => setIsResetOpen(true)}>
                            <HiRefresh />&nbsp;{t("reset")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsRestartOpen(true)}>
                            <HiMiniPlayPause />&nbsp;{t("restart")}
                        </Dropdown.Item>
                    </ShowIf>
                    <Dropdown.Item onClick={() => setIsDeleteOpen(true)}>
                        <HiTrash />&nbsp;{t("delete")}
                    </Dropdown.Item>
                </>
            } />
        </div>
    );
}