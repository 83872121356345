export function parseDate(date: Date | string | undefined) {
    return new Date(date ?? new Date());
}

export function parseDateToString(date: Date | undefined) {
    if (!date) {
        return "";
    }
    return parseDate(date).toLocaleDateString();
}

export function parseDateToFullString(date: Date | undefined) {
    if (!date) {
        return "";
    }
    let parsedDate = parseDate(date);
    return parsedDate.toLocaleDateString() + " - " + parsedDate.toLocaleTimeString();
}

export function formatDateToYYYYMMDD(date: Date) {
    let parsedDate = parseDate(date);
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const day = String(parsedDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function compareDate(date: Date, dateToCompare: Date) : boolean {
    const date1 = parseDate(date);
    const date2 = parseDate(dateToCompare);
    return date1.getUTCDay() === date2.getUTCDay();
}

export function isToday(someDate: Date) {
    const date = parseDate(someDate);
    const today = new Date();
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
}

export function isAfter(first: Date, second: Date) {
    const firstDate = parseDate(first);
    const secondDate = parseDate(second);
    return firstDate > secondDate;
}