import axios, {AxiosResponse} from "axios";
import Subject, {SubjectLight} from "../domain/Subject";

type SubjectLightMap = {
    [key: string]: SubjectLight[];
};

export function getSubjects(): Promise<AxiosResponse<SubjectLightMap>> {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function getSubject(id: string): Promise<AxiosResponse<Subject>> {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${id}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}

export function createSubject(body: any) {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateSubject(subjectId: any, body: any) {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${subjectId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}

export function publishSubject(subjectId: string) {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${subjectId}/approval`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, undefined, config);
}

export function unpublishSubject(subjectId: string) {
    const url = window._env_.ASSESSMENTS_API_URL + `subjects/${subjectId}/approval`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}
