import React, {ReactNode} from "react";
import {Spinner} from "flowbite-react";

interface LoadingComponentProps {
    isLoading: boolean;
    children: ReactNode;
}

export default function LoadingComponent({isLoading, children} : LoadingComponentProps) {
    return <>
        {isLoading ? (
            <div className="text-center py-1 my-2">
                <Spinner size="xl" />
            </div>
        ) : (children)}
    </>
}