import React, {Dispatch, SetStateAction, useState} from "react";
import QuizModal from "./QuizModal";
import Quiz, {QuizLight} from "../../domain/Quiz";
import {HiPencil} from "react-icons/hi2";

interface QuestionsCreateModalProps {
    setQuizzes: Dispatch<SetStateAction<QuizLight[]>>;
    setUpdatableQuiz: Dispatch<SetStateAction<Quiz | undefined>>;
    updatableQuiz: Quiz | undefined;
}

export default function QuizUpdateModal({setQuizzes, updatableQuiz, setUpdatableQuiz} : QuestionsCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                onClick={() => setIsOpen(true)}>
                <HiPencil color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <QuizModal isOpen={isOpen} setIsOpen={setIsOpen} setQuizzes={setQuizzes} updatableQuiz={updatableQuiz} setUpdatableQuiz={setUpdatableQuiz} />
        </>
    );
}