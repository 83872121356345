import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import LoadingComponent from "../../../components/LoadingComponent";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getMyAssessments} from "../api/assessmentInstanceConnector";
import {AssessmentInstance, AssessmentStateEnum} from "../domain/Assessment";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {isAfter, isToday, parseDate} from "../../../components/utils/DateUtil";
import MyAssessmentInstanceCard from "../components/MyAssessmentInstanceCard";

function compareAssessmentInstances(instance1: AssessmentInstance, instance2: AssessmentInstance): number {
    const currentDate = new Date();
    const startDate1 = new Date(instance1.assessment.startDate);
    const endDate1 = new Date(instance1.assessment.endDate);
    const startDate2 = new Date(instance2.assessment.startDate);
    const endDate2 = new Date(instance2.assessment.endDate);

    const effectiveStartDate1 = startDate1 <= currentDate ? endDate1 : startDate1;
    const effectiveStartDate2 = startDate2 <= currentDate ? endDate2 : startDate2;

    if (currentDate < effectiveStartDate1 && currentDate < effectiveStartDate2) {
        return effectiveStartDate1 < effectiveStartDate2 ? -1 : 1;
    } else if (currentDate > effectiveStartDate1 && currentDate > effectiveStartDate2) {
        return effectiveStartDate1 < effectiveStartDate2 ? -1 : 1;
    } else {
        return 0;
    }
}

interface AssessmentInstanceMonthCardProps {
    month: string,
    assessmentInstances: AssessmentInstance[];
    reload: () => void;
}

function AssessmentInstanceMonthCard({month, assessmentInstances, reload} : Readonly<AssessmentInstanceMonthCardProps>) {

    function formatMonth(monthYear: string) {
        const [month, year] = monthYear.split('/');
        const date = new Date();
        date.setMonth(parseInt(month));
        let monthName = date.toLocaleString('default', { month: 'long' });
        return `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${year}`;
    }

    return (
        <div key={month} className="divide-y">
            <h3 className="text-lg font-bold p-4 bg-white">{formatMonth(month)}</h3>
            {assessmentInstances.sort(compareAssessmentInstances).map(assessmentInstance => <MyAssessmentInstanceCard key={assessmentInstance.code} assessmentInstance={assessmentInstance} reload={reload} />)}
        </div>
    );
}

export default function MyAssessmentsManagement() {
    const { t } = useTranslation("assessments");
    const { throwError } = useAppContext();
    const [isAssessmentsLoading, setIsAssessmentsLoading] = useState(true);
    const [assessmentMap, setAssessmentMap] = useState<Map<string, AssessmentInstance[]>>(new Map<string, AssessmentInstance[]>());
    const [todayAssessments, setTodayAssessmentInstances] = useState<AssessmentInstance[]>([]);

    const reloadAssessment = useCallback(() => {
        setIsAssessmentsLoading(true);
        getMyAssessments()
            .then(assessmentsAxiosResponse => {
                setTodayAssessmentInstances(assessmentsAxiosResponse.data.filter(instance => {
                    const now = new Date();
                    const startToday = isToday(instance.assessment.startDate);
                    return (startToday || (isAfter(now, instance.assessment.startDate) && isAfter(instance.assessment.endDate, now))) && (instance.state === AssessmentStateEnum.READY || instance.state === AssessmentStateEnum.STARTED || instance.state === AssessmentStateEnum.DONE_UPDATABLE);
                }));
                setAssessmentMap(groupAssessmentsByMonth(assessmentsAxiosResponse.data));
                setIsAssessmentsLoading(false);
            })
            .catch(() => throwError(t("getAssessmentsError")));
    }, [t, throwError]);

    function groupAssessmentsByMonth(assessmentInstances: AssessmentInstance[]): Map<string, AssessmentInstance[]> {
        const groupedAssessments = new Map<string, AssessmentInstance[]>();
        const currentDate = new Date();

        assessmentInstances.forEach(assessmentInstance => {
            const { startDate, endDate } = assessmentInstance.assessment;
            const parsedStartDate = parseDate(startDate);
            const monthYear = parsedStartDate.getMonth() + "/" + parsedStartDate.getFullYear();
            const startToday = isToday(assessmentInstance.assessment.startDate);
            const isAssessmentToday = (startToday || (isAfter(currentDate, assessmentInstance.assessment.startDate) && isAfter(endDate, currentDate)))
                && (assessmentInstance.state === AssessmentStateEnum.READY || assessmentInstance.state === AssessmentStateEnum.STARTED);

            if (!isAssessmentToday && !isAfter(currentDate, endDate) && (assessmentInstance.state === AssessmentStateEnum.READY || assessmentInstance.state === AssessmentStateEnum.STARTED)) {
                if (groupedAssessments.has(monthYear)) {
                    groupedAssessments.get(monthYear)!.push(assessmentInstance);
                } else {
                    groupedAssessments.set(monthYear, [assessmentInstance]);
                }
            }
        });

        return groupedAssessments;
    }

    const sortedMonths = Array.from(assessmentMap.keys()).sort((a, b) => {
        return new Date(a).getUTCMilliseconds() - new Date(b).getUTCMilliseconds();
    });

    useEffect(() => {
        reloadAssessment();
    }, [reloadAssessment]);

    return (
        <div className="mx-4">
            <Breadcrumb className="mx-2 mb-3">
                <Breadcrumb.Item icon={HiHome}>
                    MyHexagone
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.my.assessments", {ns: "common"})}</Breadcrumb.Item>
            </Breadcrumb>
            <LoadingComponent isLoading={isAssessmentsLoading}>
                <div>
                    <div className="divide-y">
                        <h3 className="text-lg font-bold p-4 bg-white">{t("today")}</h3>
                        <div className="divide-y">
                            {todayAssessments && todayAssessments.length > 0 ? todayAssessments.sort(compareAssessmentInstances).map(instance => <MyAssessmentInstanceCard key={instance.code} assessmentInstance={instance} inProgress reload={reloadAssessment} />) : <p className="py-4">{t("noAssessmentYet")}</p>}
                        </div>
                    </div>
                    <div>
                        {sortedMonths.map(month => <AssessmentInstanceMonthCard key={month} month={month} assessmentInstances={assessmentMap.get(month) ?? []} reload={reloadAssessment} />)}
                    </div>
                </div>
            </LoadingComponent>
        </div>
    );
}