import {Button} from "flowbite-react";
import React, {Dispatch, SetStateAction, useState} from "react";
import {useTranslation} from "react-i18next";
import Answer from "../../domain/Answer";
import AnswerModal from "./AnswerModal";

interface AnswerCreateModalProps {
    subjectId: string;
    questionId: number;
    setAnswers: Dispatch<SetStateAction<Answer[]>>;
}

export default function AnswerCreateModal({subjectId, questionId, setAnswers} : AnswerCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation("enrolmentTests");

    return (
        <>
            <Button color="success" onClick={() => setIsOpen(true)}>{t("addAnswer")}</Button>
            <AnswerModal isOpen={isOpen}
                         setIsOpen={setIsOpen}
                         subjectId={subjectId}
                         questionId={questionId}
                         setAnswers={setAnswers} />
        </>
    );
}