import Navbar from "./Navbar/Navbar";
import Drawer from "./Drawer/Drawer";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ToastLauncher from "../Toast/ToastLauncher";
import {useAppContext} from "../AppContext/AppContext";
import {Outlet} from "react-router-dom";

export default function Layout() {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [dir, setDir] = useState("ltr");
    const {i18n} = useTranslation();
    const {toastStatus, toastMessage, toastVariant, setToastStatus} = useAppContext();

    useEffect(() => {
        if (i18n.language === "ar") {
            setDir("rtl");
        } else {
            setDir("ltr");
        }
    }, [i18n.language])

    return (
        <div dir={dir}>
            <div className="m-4">
                <Navbar setDrawerOpen={setDrawerOpen} />
                <Drawer setDrawerOpen={setDrawerOpen} isDrawerOpen={isDrawerOpen}/>
            </div>
            <div className="max-w-screen-xl mx-auto">
                <Outlet />
            </div>
            <ToastLauncher toastStatus={toastStatus}
                           toastMessage={toastMessage}
                           toastVariant={toastVariant}
                           setToastStatus={setToastStatus}/>
        </div>
    );
}