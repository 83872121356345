import {Label, Select} from "flowbite-react";
import React, {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {QuestionTypeEnum} from "../../domain/QuestionTypeEnum";

interface QuestionTypeSelectProps {
    type: string;
    setType: Dispatch<SetStateAction<string>>;
}

export default function QuestionTypeSelect({type, setType} : QuestionTypeSelectProps) {
    const { t } = useTranslation("assessments");

    return (
        <div>
            <Label htmlFor="type" value={t("questionType")}/>
            <div>
                <Select value={type}
                        onChange={(e) => setType(e.target.value)}
                        id="type"
                        required
                >
                    <option disabled value="">
                        {t("selectQuestionType")}
                    </option>
                    {(Object.values(QuestionTypeEnum) as Array<keyof typeof QuestionTypeEnum>)
                        .filter(value => typeof QuestionTypeEnum[value] === "string")
                        .map((key) => {
                            return (
                                <option key={key} value={key}>
                                    {t(key)}
                                </option>
                            );
                        })
                    }
                </Select>
            </div>
        </div>
    );
}