import React, {Dispatch, SetStateAction, useState} from "react";
import {HiTrash} from "react-icons/hi2";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {QuizInstruction} from "../../domain/Quiz";
import {deleteInstruction} from "../../api/quizConnector";

interface InstructionDeleteModalProps {
    quizId: string;
    questionBankId: number;
    setInstructions: Dispatch<SetStateAction<QuizInstruction[]>>;
}

export default function InstructionDeleteModal({quizId, questionBankId, setInstructions} : InstructionDeleteModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("assessments");
    const {throwError} = useAppContext();

    function submit() {
        deleteInstruction(quizId, questionBankId)
            .then(() => {
                setInstructions(prevState => {
                    let newState = [...prevState];
                    const instructionIndex = newState.findIndex(q => q.questionBank.id === questionBankId);

                    if (instructionIndex !== -1) {
                        newState.splice(instructionIndex, 1);
                    }
                    return newState;
                })
                setIsOpen(false);
            })
            .catch(() => throwError(t("deleteInstructionError")));
    }

    return (
        <>
            <button
                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full" onClick={() => setIsOpen(true)}>
                <HiTrash color={"rgba(0,0,0,0.66)"} size="20"/>
            </button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <ModalHeader>
                    {t("deleteInstruction")}
                </ModalHeader>
                <ModalBody>
                    {t("confirmDeleteInstruction")}
                </ModalBody>
                <ModalFooter className="flex justify-between">
                    <Button color="primary" onClick={() => setIsOpen(false)}>
                        {t("cancel", {ns: "common"})}
                    </Button>
                    <Button color="failure" onClick={submit}>
                        {t("delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}