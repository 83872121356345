import axios, {AxiosResponse} from "axios";
import Promotion from "../domain/Promotion";

export function getPromotions(): Promise<AxiosResponse<Promotion[]>> {
    const url = window._env_.ASSESSMENTS_API_URL + `promotions`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}