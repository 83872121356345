import {Label, Select} from "flowbite-react";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {QuestionBankLight} from "../../domain/QuestionBank";
import {getQuestionBanks} from "../../api/questionBankConnector";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {QuizInstruction} from "../../domain/Quiz";

interface SubjectSelectProps {
    selectedQuestionBank: QuestionBankLight;
    instructions: QuizInstruction[];
    setQuestionBank: Dispatch<SetStateAction<QuestionBankLight>>;
}

export default function QuestionBankSelect({selectedQuestionBank, setQuestionBank, instructions} : SubjectSelectProps) {
    const { t } = useTranslation("assessments");
    const [questionBanks, setQuestionBanks] = useState<QuestionBankLight[]>([]);
    const { throwError } = useAppContext();

    useEffect(() => {
        getQuestionBanks().then(subjectsAxiosResponse => {
            setQuestionBanks(subjectsAxiosResponse.data);
        }).catch(() => throwError("getSubjectsError"));
    }, [throwError]);

    return (
        <div>
            <Label htmlFor="type" value={t("questionBank")} />
            <div>
                <Select value={selectedQuestionBank?.id}
                        onChange={(e) => setQuestionBank(questionBanks.find(s => s.id === parseInt(e.target.value)) || selectedQuestionBank)}
                        id="questionBank"
                        required
                >
                    <option disabled value={0}>
                        {t("selectQuestionBank")}
                    </option>
                    {questionBanks.filter(q => !instructions.map(i => i.questionBank.id).includes(q.id)).map(questionBank => (
                        <option key={questionBank.id} value={questionBank.id}>
                            {questionBank.name} - {questionBank.questionCount} {t("questions")}
                        </option>
                    ))}
                </Select>
            </div>
        </div>
    );
}