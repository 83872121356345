import AppLauncher from "../../components/AppLauncher/AppLauncher";
import {useAppContext} from "../../components/AppContext/AppContext";
import {useTranslation} from "react-i18next";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import React from "react";

export default function Dashboard() {
    const {fullName} = useAppContext();
    const {t} = useTranslation("dashboard");
    const firstName = fullName.split(" ")[0];

    return (
        <main className="flex flex-col items-center justify-between mx-2">
            <Breadcrumb className="mx-4 mb-3 self-start">
                <Breadcrumb.Item icon={HiHome}>
                    MyHexagone
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("menu.dashboard", {ns: "common"})}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="mx-4 mb-2 font-extrabold text-xl self-start">
                {t("hello", {ns: "common", firstName: firstName})}
            </h1>
            <div className="mb-4 mx-4 sm:text-center sm:text-lg self-start sm:self-center">
                <p>
                    {t("welcome.part1")}
                </p>
                <p>
                    {t("welcome.part2")}
                </p>
            </div>
            <AppLauncher/>
        </main>
    );
}