import axios, {AxiosResponse} from "axios";
import UserContext from "../../domain/UserContext";

export function getUserContext(): Promise<AxiosResponse<UserContext>> {
    const url = window._env_.USERS_API_URL + `users/context`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.get(url, config);
}