import {Button, Modal} from "flowbite-react";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import {useTranslation} from "react-i18next";
import {HiCheck} from "react-icons/hi2";
import {addInstruction} from "../../api/quizConnector";
import Quiz, {QuizInstruction} from "../../domain/Quiz";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {SubjectLight} from "../../domain/Subject";
import SubjectSelect from "./SubjectSelect";
import WriteInput from "../../../../components/Inputs/WriteInput";

interface InstructionCreateModalProps {
    quizId: string;
    instructions: QuizInstruction[];
    setQuiz: Dispatch<SetStateAction<Quiz | undefined>>;
}

export default function InstructionCreateModal({quizId, setQuiz, instructions} : InstructionCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [subject, setSubject] = useState<SubjectLight>({name: "", questionCount: 0, id: 0});
    const [questionNumber, setQuestionNumber] = useState(1);
    const { t } = useTranslation("enrolmentTests");
    const { throwError } = useAppContext();

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        addInstruction(quizId, {
            subject, quantity: questionNumber
        }).then(instructionAxiosResponse => {
            setQuiz(instructionAxiosResponse.data);
            setIsOpen(false);
        }).catch(() => throwError(t("addInstructionError")));
    }

    return (
        <>
            <Button color="secondary" onClick={() => setIsOpen(true)}>{t("addInstruction")}</Button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <Modal.Header>
                    {t("addInstruction")}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submit} id="instructionForm">
                        <SubjectSelect selectedSubject={subject} setSubject={setSubject}  instructions={instructions}/>
                        <WriteInput label="questionNumber" value={questionNumber} setter={(v) => setQuestionNumber(parseInt(v))} max={subject?.questionCount !== undefined ? subject.questionCount : 0} min={subject?.questionCount !== undefined ? 1 : 0} type="number" t={t} />
                    </form>
                </Modal.Body>
                <Modal.Footer className="justify-end">
                    <Button type="submit" color="success" form="instructionForm">
                        <p>{t("validate", {ns: "common"})}</p>
                        <HiCheck className="ml-2 h-5 w-5"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}