import React from "react";

interface ShowIfProps {
    children: React.ReactNode,
    condition: boolean;
    elseShow: React.ReactNode
}

export default function ShowIfElse({condition, children, elseShow} : ShowIfProps) {
    return <>{condition ? children : elseShow}</>
}