import Keycloak from "keycloak-js";
import {useCallback, useEffect, useState} from "react";
import {createContainer} from "unstated-next";
import {KEYCLOAK_CONFIG} from "./authConfig";

const kc = new Keycloak(KEYCLOAK_CONFIG);

interface AuthenticationContextValue {
    loggedIn: boolean;
    error: boolean;
    logout: () => void;
}

export const useAuthenticationContextProvider = (): AuthenticationContextValue => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState(false);

    const delay = useCallback(() => {
        kc.updateToken(65).then(() => {
            localStorage.setItem("token", kc.token + "");
            localStorage.setItem("refresh-token", kc.refreshToken + "");
            setTimeout(function () {
                delay();
            }, 65000);
        });
    }, []);

    useEffect(() => {
        kc.init({onLoad: "login-required"}).then(() => {
            localStorage.setItem("token", kc.token + "");
            localStorage.setItem("refresh-token", kc.refreshToken + "");
            setLoggedIn(true);

            setTimeout(function () {
                delay();
            }, 65000);
        }).catch(err => {
            setLoggedIn(false);
            setError(err);
        });
    }, [delay]);

    function logout() {
        kc.logout();
    }

    return {loggedIn, error, logout};
};

export const AuthenticationContextContainer = createContainer(useAuthenticationContextProvider);

export const useAuthenticationContext = () => {
    return AuthenticationContextContainer.useContainer();
}