import axios, {AxiosResponse} from "axios";
import Answer from "../domain/Answer";

export function deleteAnswer(subjectId: any, questionId: any, answerId: any) {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}/questions/${questionId}/answers/${answerId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}

export function createAnswer(subjectId: any, questionId: any, body: any): Promise<AxiosResponse<Answer>> {
 const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}/questions/${questionId}/answers`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateAnswer(subjectId: any, questionId: any, answerId: any, body: any): Promise<AxiosResponse<Answer>> {
    const url = window._env_.ENROLMENT_API_URL + `subjects/${subjectId}/questions/${questionId}/answers/${answerId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}