import {Label, TextInput} from "flowbite-react";
import React from "react";
import {TFunction} from "i18next";

interface WriteInputProps {
    label: string,
    value: string | number,
    setter: (value: any) => void,
    placeholder?: string,
    required?: boolean,
    type?: "text" | "date" | "time" | "number"
    t: TFunction,
    helperText?: string;
    max?: number;
    min?: number;
    disabled?: boolean;
}

export default function WriteInput({label, value, setter, placeholder, required, type, t, helperText, min, max, disabled} : WriteInputProps) {
    
    return (
        <div>
            <Label htmlFor={label} value={t(label) + (required ? "*" : "")} />
            <TextInput
                helperText={helperText}
                type={type ?? "text"}
                max={max}
                min={min}
                value={value}
                onChange={(e) => setter(e.target.value)}
                id={label}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
            />
        </div>
    );
}