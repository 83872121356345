import {useNavigate} from "react-router-dom";
import {AssessmentLight} from "../domain/Assessment";
import {Card, Progress} from "flowbite-react";
import {HiClock} from "react-icons/hi2";
import {HiChevronRight} from "react-icons/hi";
import React, {useCallback} from "react";
import {parseDateToString} from "../../../components/utils/DateUtil";
import {parseTimeToString} from "../../../components/utils/TimeUtil";

interface AssessmentCardProps {
    assessment: AssessmentLight
}

export default function AssessmentCard({ assessment } : AssessmentCardProps) {
    const navigate = useNavigate();

    const formatDateIfExists = useCallback((date: Date) => {
        if (date) {
            return parseDateToString(date) + " " + parseTimeToString(date);
        }
    }, [])

    const formatAssessmentStatusLabel = useCallback(() => {
        if (assessment.doneNumbers && assessment.doneNumbers > 0) {
            return `${assessment.doneNumbers} / ${assessment.instanceNumbers}`;
        }
        return "";
    }, [assessment.doneNumbers, assessment.instanceNumbers])

    return (
        <Card className="cursor-pointer" onClick={() => navigate(`/assessments/${assessment.id}`)}>
            <div className="flex items-center mb-2">
                <div className="w-full">
                    <h3 className="text-lg font-medium">{assessment.subject.title}</h3>
                    <p className="flex items-center"><HiClock/>&nbsp;{formatDateIfExists(assessment.startDate)}</p>
                </div>
                <HiChevronRight className="h-10 w-10"/>
            </div>
            <Progress color={`${assessment.doneNumbers === assessment.instanceNumbers ? "green" : "yellow"}`}
                      progress={(assessment.doneNumbers / assessment.instanceNumbers) * 100 || 0}
                      textLabel={formatAssessmentStatusLabel()} labelText size="lg" />
        </Card>
    );
}