import {RoleEnum} from "../../components/authentication/RoleEnum";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import QuizzesManagement from "./pages/QuizzesManagement";

export default function Quizzes() {

    return (
        <RestrictedAccess authorizedRoles={[RoleEnum.STAFF_GM_IS]}>
            <QuizzesManagement />
        </RestrictedAccess>
    );

}