import {Button, Modal} from "flowbite-react";
import {useTranslation} from "react-i18next";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import Subject, {SubjectLight, SubjectTypeEnum} from "../../../domain/Subject";
import {HiChevronLeft, HiChevronRight} from "react-icons/hi";
import SubjectForm from "./SubjectForm";
import ShowIf from "../../../../../components/ConditionalShow/ShowIf";
import QuizForm from "../../QuizModals/QuizForm";
import ShowIfElse from "../../../../../components/ConditionalShow/ShowIfElse";
import {HiCheck} from "react-icons/hi2";
import {QuizLight} from "../../../domain/Quiz";
import {createSubject} from "../../../api/subjectConnector";
import {useAppContext} from "../../../../../components/AppContext/AppContext";
import DutyForm from "../../DutyModals/DutyForm";

interface SubjectCreateModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    reloadSubjects: () => void;
}

export default function SubjectCreateModal({ isOpen, setIsOpen, reloadSubjects }: SubjectCreateModalProps) {
    const { t } = useTranslation("assessments");
    const [title, setTitle] = useState("");
    const [ectsCode, setEctsCode] = useState("");
    const [subjectType, setSubjectType] = useState<string>(SubjectTypeEnum.QUIZ.toString());
    const [step, setStep] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const { throwError } = useAppContext();

    function handleNext(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setStep(1);
    }

    function submit({event, quiz, postAction}: {event: FormEvent<HTMLFormElement>, quiz?: QuizLight, postAction?: (subject: SubjectLight) => Promise<Subject | void>}) {
        event.preventDefault();
        setIsProcessing(true);
        createSubject({title, ectsCode, quiz, type: subjectType}).then((subjectAxiosResponse) => {
            if (postAction) {
                postAction(subjectAxiosResponse.data)
                    .then(() => {
                        reloadSubjects();
                        setIsOpen(false);
                        setIsProcessing(true);
                    });
            } else {
                reloadSubjects();
                setIsOpen(false);
                setIsProcessing(true);
            }
        }).catch(() => throwError(t("createSubjectError")));
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>{t("createSubject")}</Modal.Header>
            <Modal.Body>
                <ShowIf condition={step === 0}>
                    <SubjectForm
                        ectsCode={ectsCode}
                        subjectType={subjectType}
                        title={title}
                        setEctsCode={setEctsCode}
                        setSubjectType={setSubjectType}
                        setTitle={setTitle}
                        submit={handleNext}/>
                </ShowIf>
                <ShowIf condition={step === 1}>
                    <ShowIf condition={subjectType === SubjectTypeEnum.QUIZ}>
                        <QuizForm submit={submit} />
                    </ShowIf>
                    <ShowIf condition={subjectType === SubjectTypeEnum.DUTY}>
                        <DutyForm submit={submit} reload={reloadSubjects} />
                    </ShowIf>
                </ShowIf>
            </Modal.Body>
            <ShowIfElse condition={step === 0} elseShow={(
                <Modal.Footer className="justify-between">
                    <Button color="primary" onClick={e => {
                        e.preventDefault();
                        setStep(0);
                    }}>
                        <HiChevronLeft className="me-2 h-5 w-5"/>
                        <p>{t("previous", {ns: "common"})}</p>
                    </Button>
                    <Button type="submit" color="success" form="subjectContentForm" isProcessing={isProcessing}>
                        <p>{t("validate", {ns: "common"})}</p>
                        <HiCheck className="ms-2 h-5 w-5"/>
                    </Button>
                </Modal.Footer>
            )}>
                <Modal.Footer className="justify-end">
                    <Button type="submit" color="success" form="subjectForm">
                        <p>{t("next", {ns: "common"})}</p>
                        <HiChevronRight className="ms-2 h-5 w-5"/>
                    </Button>
                </Modal.Footer>
            </ShowIfElse>
        </Modal>
    );
}