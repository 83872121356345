import {RoleEnum} from "../../components/authentication/RoleEnum";
import RestrictedAccess from "../../components/authentication/RestrictedAccess";
import QuestionBanksManagement from "./parts/QuestionBanksManagement";

export default function QuestionsBank() {

    return (
        <RestrictedAccess authorizedRoles={[
            RoleEnum.STAFF_GM_IS,
            RoleEnum.STAFF_GM_ACADEMIC,
            RoleEnum.STAFF_GM_QUALITY,
            RoleEnum.STAFF_GM_MANAGEMENT
            ]}>
            <QuestionBanksManagement />
        </RestrictedAccess>
    );

}