import {CustomFlowbiteTheme, Label, Select, TextInput} from "flowbite-react";
import {InternationalPhonePrefix} from "../../../domain/InternationalPhonePrefix";
import React from "react";
import {useTranslation} from "react-i18next";

interface PhoneNumberFieldProps {
    phoneNumber: string;
    phonePrefix: string;
    setPhoneNumber?: (number: string) => void;
    setPhonePrefix?: (prefix: string) => void;
    readOnly? : boolean,
    className?: string,
}

const selectPrefix: CustomFlowbiteTheme['select'] = {
    field: {
        select: {
            base: "w-24 !rounded-e-none",
        }
    }
};
const inputPhoneNumber: CustomFlowbiteTheme['textInput'] = {
    addon: "bg-gray-200 border border-gray-300 text-gray-900 text-sm dark:border-l-gray-700 block p-2.5 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
    field: {
        input: {
            base: "rounded-s-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-lg border-l-gray-100 dark:border-l-gray-700 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
        }
    }
};

export default function PhoneNumberField({phoneNumber, phonePrefix, setPhoneNumber, setPhonePrefix, readOnly, className} : PhoneNumberFieldProps) {
    const { t } = useTranslation("users");

    return (
        <div className={className}>
            <Label htmlFor="phoneNumber" value={t("phoneNumber") + (readOnly ? "" : "*")} />
            <div className="flex">
                <Select disabled={readOnly} theme={selectPrefix} onChange={(e) => setPhonePrefix ? setPhonePrefix(e.target.value) : void(0)} value={phonePrefix}>
                    {InternationalPhonePrefix.map((prefix, index) => (
                        <option key={index} value={prefix.prefix} prefix={prefix.flag}>
                            {prefix.country} {prefix.flag}
                        </option>
                    ))}
                </Select>
                <TextInput
                    disabled={readOnly}
                    addon={"+" + phonePrefix}
                    className="w-full"
                    theme={inputPhoneNumber}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber ? setPhoneNumber(e.target.value) : void(0)}
                    id="phoneNumber"
                    placeholder="102030405"
                    required
                />
            </div>
        </div>

    );
}