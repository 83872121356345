import React, {useState} from "react";
import {HiTrash} from "react-icons/hi2";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {deleteQuestionBank} from "../../api/questionBankConnector";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../../../../components/AppContext/AppContext";

interface SubjectDeleteModalProps {
    subjectId: string;
    reloadSubjects: () => void;
}

export default function QuestionBankDeleteModal({subjectId, reloadSubjects} : SubjectDeleteModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("assessments");
    const navigate = useNavigate();
    const {throwError} = useAppContext();

    function submit() {
        deleteQuestionBank(subjectId)
            .then(() => {
                navigate("/assessments/questions-bank");
                reloadSubjects();
                setIsOpen(false);
            })
            .catch(() => throwError("deleteSubjectError"));
    }

    return (
        <>
            <button className="p-2 hover:bg-hexagone-darker-light-grey active:hover:bg-hexagone-purple rounded-full" onClick={() => setIsOpen(true)}>
                <HiTrash size="20" className="text-hexagone-purple active:text-hexagone-light-grey" />
            </button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <ModalHeader>
                    {t("deleteSubject")}
                </ModalHeader>
                <ModalBody>
                    {t("confirmDeleteSubject")}
                </ModalBody>
                <ModalFooter className="flex justify-between">
                    <Button color="primary" onClick={() => setIsOpen(false)}>
                        {t("cancel", {ns: "common"})}
                    </Button>
                    <Button color="failure" onClick={submit}>
                        {t("delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}