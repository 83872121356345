import AssertionsTable from "../AssertionsTable";
import React, {useEffect, useState} from "react";
import {User} from "../../../../domain/User";
import UserAssertion from "../../../../domain/UserAssertion";
import {parseDate} from "../../../../components/utils/DateUtil";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {Card} from "flowbite-react";
import AddAssertionModal from "../AddAssertionModal";
import {compareAssertion, isRestricted} from "../AssertionUtil";
import {isAdministrator} from "../../../../components/authentication/RoleUtil";
import {addAssertion, removeOrEndAssertion} from "../../api/userAssertionsConnector";

interface AssertionsTabProps {
    user?: User;
}

export default function AssertionsTab({user} : AssertionsTabProps) {
    const { t } = useTranslation("users");
    const { throwError, roles } = useAppContext();
    const [userAssertions, setUserAssertions] = useState<UserAssertion[]>([]);

    useEffect(() => {
        setUserAssertions(user?.assertions || []);
    }, [user]);

    function canEndAssertion(assertion: UserAssertion) {
        if (isRestricted(user) && !isAdministrator(roles)) {
            return false;
        }
        return parseDate(assertion.startDate) <= new Date() && parseDate(assertion.endDate) >= new Date();
    }

    function canRemoveAssertion(assertion: UserAssertion) {
        if (isRestricted(user) && !isAdministrator(roles)) {
            return false;
        }
        return parseDate(assertion.startDate) > new Date();
    }

    function doAddAssertion(assertion: UserAssertion) {
        return new Promise<void>((resolve, reject) => {
            if (userAssertions.filter(existingAssertion => compareAssertion(assertion, existingAssertion)).length > 0) {
                reject(new Error(t("addSameAssertionError") || ""))
            } else if (assertion.assertion === 'STUDENT' && !user?.birthDate) {
                reject(new Error(t("birthDateRequiredError") || ""))
            } else {
                addAssertion(user?.id, assertion)
                    .then(userAssertionsAxiosResponse => setUserAssertions(userAssertionsAxiosResponse.data))
                    .catch(() => throwError(t("addAssertionError")));
                resolve();
            }
        });
    }

    function doRemoveAssertion(index: number) {
        const assertionToRemove = userAssertions[index];
        if (assertionToRemove) {
            removeOrEndAssertion(user?.id, assertionToRemove)
                .then(userAssertionsAxiosResponse => setUserAssertions(userAssertionsAxiosResponse.data))
                .catch(() => throwError(t("removeAssertionError")));
        }
    }

    return (
        <Card className="overflow-x-auto" theme={{root:{children: "flex h-full flex-col justify-center"}}}>
            <AssertionsTable assertions={userAssertions} removeAssertion={doRemoveAssertion} canEnd={canEndAssertion} canRemove={canRemoveAssertion} />
            <AddAssertionModal className="m-2" addAssertion={doAddAssertion} />
        </Card>
    );
}