import {Button} from "flowbite-react";
import React, {Dispatch, SetStateAction, useState} from "react";
import QuestionModal from "./QuestionModal";
import Question from "../../domain/Question";
import {useTranslation} from "react-i18next";

interface QuestionsCreateModalProps {
    setQuestions: Dispatch<SetStateAction<Question[]>>;
    subjectId: string;
}

export default function QuestionCreateModal({setQuestions, subjectId} : QuestionsCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation("enrolmentTests");

    return (
        <>
            <Button color="secondary" onClick={() => setIsOpen(true)}>{t("addQuestion")}</Button>
            <QuestionModal isOpen={isOpen} setIsOpen={setIsOpen} subjectId={subjectId} setQuestions={setQuestions} />
        </>
    );
}