import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {Button, Modal, ToggleSwitch} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import {useTranslation} from "react-i18next";
import WriteInput from "../../../../components/Inputs/WriteInput";
import Answer from "../../domain/Answer";

interface AnswerModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setAnswers: Dispatch<SetStateAction<Answer[]>>;
    updatableAnswer?: Answer;
    handleUpdate?: (updatableAnswer: Answer, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) => void;
    handleCreate?: (content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) => void;
}

export default function AnswerModal({isOpen, setIsOpen, setAnswers, updatableAnswer, handleUpdate, handleCreate}: AnswerModalProps) {
    const {t} = useTranslation("assessments");
    const [content, setContent] = useState("");
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (updatableAnswer) {
            setContent(updatableAnswer.content);
            setIsValid(updatableAnswer.isValid);
        }
    }, [updatableAnswer]);

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (updatableAnswer && handleUpdate) {
            handleUpdate(updatableAnswer, content, isValid, setIsOpen, setAnswers, setIsValid, setContent);
        } else {
            if (handleCreate) {
                handleCreate(content, isValid, setIsOpen, setAnswers, setIsValid, setContent);
            }
        }
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>
                {updatableAnswer ? t("updateAnswer") : t("createAnswer")}
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submit} id="answerForm">
                    <WriteInput label="answerContent" value={content} setter={setContent} t={t} required />
                    <ToggleSwitch color="success" className="mt-2" checked={isValid} label={t("isAnswerValid")} onChange={setIsValid} />
                </form>
            </Modal.Body>
            <Modal.Footer className="justify-end">
                <Button type="submit" color="success" form="answerForm">
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ml-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );

}