import {HiRefresh, HiX} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import LoadingComponent from "../../../components/LoadingComponent";
import React, {useCallback, useEffect, useState} from "react";
import {useAppContext} from "../../../components/AppContext/AppContext";
import Assessment from "../domain/Assessment";
import AbstractDrawer from "../../../components/AbstractDrawer";
import {useNavigate} from "react-router-dom";
import {getAssessment} from "../api/assessmentConnector";
import {parseDateToString} from "../../../components/utils/DateUtil";
import {parseTimeToString} from "../../../components/utils/TimeUtil";
import AssessmentInstanceCard from "../components/AssessmentInstanceCard";

interface AssessmentManagementProps {
    selectedAssessmentDrawer: string;
}

export default function AssessmentManagement({selectedAssessmentDrawer} : AssessmentManagementProps) {
    const { t } = useTranslation("assessments");
    const navigate = useNavigate();
    const { throwError } = useAppContext();
    const [isAssessmentLoading, setIsAssessmentLoading] = useState(false);
    const [assessment, setAssessment] = useState<Assessment>();

    const reloadAssessment = useCallback((assessmentId: string) => {
        if (assessmentId !== "") {
            setIsAssessmentLoading(true);
            getAssessment(assessmentId)
                .then(assessmentAxiosResponse => {
                    setAssessment(assessmentAxiosResponse.data);
                    setIsAssessmentLoading(false);
                }).catch(() => throwError(t("getAssessmentError")));
        }
    }, [t, throwError]);

    useEffect(() => {
        reloadAssessment(selectedAssessmentDrawer);
    }, [selectedAssessmentDrawer, reloadAssessment]);

    return (
        <AbstractDrawer shadow side="end" size="3xl" isOpen={selectedAssessmentDrawer !== ""}
                        setIsOpen={() => navigate("/assessments")}>
            <LoadingComponent isLoading={isAssessmentLoading}>
                <div className="items-center">
                    <div className="flex justify-between items-center">
                        <button
                            className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                            onClick={() => navigate("/assessments")}>
                            <HiX color={"rgba(0,0,0,0.66)"} size="20"/>
                        </button>
                        <div className="inline-flex w-full">
                            <h2 className="text-3xl mx-auto">{assessment?.subject.title}</h2>
                        </div>
                        <button
                            className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                            onClick={() => reloadAssessment(selectedAssessmentDrawer)}>
                            <HiRefresh color={"rgba(0,0,0,0.66)"} size="20" />
                        </button>
                    </div>
                    <p>{t("accessibleFrom")} {parseDateToString(assessment?.startDate)} {t("at")} {parseTimeToString(assessment?.startDate)}</p>
                    <p>{t("until")} {parseDateToString(assessment?.endDate)} {t("at")} {parseTimeToString(assessment?.endDate)}</p>
                    <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
                    <div className="grid gap-2">
                        {assessment && assessment.instances && assessment.instances.length > 0 ? assessment.instances
                            .sort((a, b) => a.student.lastName.localeCompare(b.student.lastName))
                            .map(instance => <AssessmentInstanceCard key={instance.id} instance={instance} reload={() => reloadAssessment(selectedAssessmentDrawer)} assessment={assessment} />) :
                            <span className="mt-4">{t("noInstances")}</span>}
                    </div>
                </div>
            </LoadingComponent>
        </AbstractDrawer>
    );
}