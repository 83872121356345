import axios, {AxiosResponse} from "axios";
import Question from "../domain/Question";

export function createQuestion(questionBankId: any, body: any): Promise<AxiosResponse<Question>> {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks/${questionBankId}/questions`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.post(url, body, config);
}

export function updateQuestion(questionBankId: any, questionId: any, body: any): Promise<AxiosResponse<Question>> {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks/${questionBankId}/questions/${questionId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.patch(url, body, config);
}

export function deleteQuestion(questionBankId: any, questionId: any) {
    const url = window._env_.ASSESSMENTS_API_URL + `question-banks/${questionBankId}/questions/${questionId}`;
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
    return axios.delete(url, config);
}