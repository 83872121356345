import {Accordion} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {AccordionContent} from "flowbite-react/lib/esm/components/Accordion/AccordionContent";
import Question from "../domain/Question";
import React, {useEffect, useState} from "react";
import ShowIf from "../../../components/ConditionalShow/ShowIf";
import AnswerCreateModal from "./AnswerModals/AnswerCreateModal";
import Answer from "../domain/Answer";
import AnswerUpdateModal from "./AnswerModals/AnswerUpdateModal";
import AnswerDeleteModal from "./AnswerModals/AnswerDeleteModal";

interface AnswerAccordionProps {
    subjectId: string;
    question: Question;
}

export default function AnswerAccordion({question, subjectId} : AnswerAccordionProps) {
    const {t} = useTranslation("enrolmentTests");
    const [answers, setAnswers] = useState<Answer[]>([]);

    useEffect(() => {
        if (question) {
            setAnswers(question.answers || []);
        }
    }, [question]);

    return (
        <Accordion collapseAll>
            <Accordion.Panel>
                <Accordion.Title>{t("answers")}</Accordion.Title>
                <AccordionContent>
                    <ShowIf condition={answers !== undefined && answers.length > 0}>
                        {answers.map(answer => (
                            <div key={answer.id}>
                                <div className="inline-flex w-full justify-between mt-2">
                                    <div>
                                        <h6 className={answer.isValid ? "text-green-600" : "text-red-600"}>{answer.content}</h6>
                                    </div>
                                    <div>
                                        <AnswerUpdateModal subjectId={subjectId} questionId={question.id} setAnswers={setAnswers} updatableAnswer={answer} />
                                        <AnswerDeleteModal subjectId={subjectId} questionId={question.id} answerId={answer.id} setAnswers={setAnswers} />
                                    </div>
                                </div>
                                <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700" />
                            </div>
                        ))}
                    </ShowIf>
                    <div className="w-full flex justify-center mt-2">
                        <AnswerCreateModal subjectId={subjectId} questionId={question.id} setAnswers={setAnswers} />
                    </div>
                </AccordionContent>
            </Accordion.Panel>
        </Accordion>
    );

}