import React, {useCallback, useEffect, useState} from "react";
import {useAppContext} from "../../../components/AppContext/AppContext";
import AbstractDrawer from "../../../components/AbstractDrawer";
import {useNavigate} from "react-router-dom";
import {HiOutlineMail, HiIdentification} from "react-icons/hi"
import {HiKey, HiUserCircle} from "react-icons/hi2"
import {PiNumberTwo} from "react-icons/pi"
import {Card, Tabs} from "flowbite-react";
import {User} from "../../../domain/User";
import ShowIfNotEmpty from "../../../components/ConditionalShow/ShowIfNotEmpty";
import AssertionsTab from "../components/UserManagementTabs/AssertionsTab";
import PersonalInformationTab from "../components/UserManagementTabs/PersonalInformationTab";
import {useTranslation} from "react-i18next";
import LoadingComponent from "../../../components/LoadingComponent";
import {getUser} from "../api/userConnector";

interface UserManagementProps {
    selectedUserDrawer: string;
}

export default function UserManagement({selectedUserDrawer}: UserManagementProps) {
    const { t } = useTranslation("users");
    const [user, setUser] = useState<User>();
    const {throwError} = useAppContext();
    const navigate = useNavigate();
    const [isUserLoading, setUserLoading] = useState(true);

    const reloadUser = useCallback((userId: string) => {
        if (userId !== "") {
            setUserLoading(true);
            getUser(userId)
                .then((userAxiosResponse) => {
                    setUserLoading(false);
                    setUser(userAxiosResponse.data);
                })
                .catch(() => throwError(t("getUserError")));
        }
    }, [throwError, t]);

    useEffect(() => {
        reloadUser(selectedUserDrawer);
    }, [reloadUser, selectedUserDrawer]);

    return (
        <AbstractDrawer shadow side="end" size="3xl" isOpen={selectedUserDrawer !== ""}
                        setIsOpen={() => navigate("/users")}>
            <div className="flex items-center justify-between">
                <div className="inline-flex">
                    <HiUserCircle className="h-7 w-7"/>&nbsp;
                    <h2 className="text-xl">{user?.usageFirstName || user?.firstName} {user?.lastName}</h2>
                </div>
                {/*<div>*/}
                {/*    <AbstractDropdown color="secondary" arrowIcon button="Actions" content={*/}
                {/*        <>*/}
                {/*            <Dropdown.Item>*/}
                {/*                <p className="text-base">Réinitialiser le mot de passe</p>*/}
                {/*            </Dropdown.Item>*/}
                {/*            <Dropdown.Item>*/}
                {/*                <p className="text-base">Supprimer</p>*/}
                {/*            </Dropdown.Item>*/}
                {/*        </>*/}
                {/*    } />*/}
                {/*</div>*/}
            </div>
            <Card>
                <div className="inline-flex items-center">
                    <HiKey className="h-5 w-5"/>&nbsp;{user?.username}
                </div>
                <div className="inline-flex items-center">
                    <HiOutlineMail className="h-5 w-5"/>&nbsp;{user?.email}
                </div>
                <ShowIfNotEmpty value={user?.secondEmail}>
                    <div className="inline-flex items-center">
                        <HiOutlineMail className="h-5 w-5"/><PiNumberTwo className="h-3 w-3" />&nbsp;{user?.secondEmail}
                    </div>
                </ShowIfNotEmpty>
            </Card>
            <Tabs aria-label="User tabs" color="primary">
                <Tabs.Item active icon={HiUserCircle} title={t("personalInformation")}>
                    <LoadingComponent isLoading={isUserLoading}>
                        <PersonalInformationTab user={user} doReload={() => reloadUser(selectedUserDrawer)} />
                    </LoadingComponent>
                </Tabs.Item>
                <Tabs.Item icon={HiIdentification} title={t("assertions")}>
                    <LoadingComponent isLoading={isUserLoading}>
                        <AssertionsTab user={user} />
                    </LoadingComponent>
                </Tabs.Item>
            </Tabs>
        </AbstractDrawer>
    )

}