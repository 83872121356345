import AbstractDrawer from "../../../components/AbstractDrawer";
import {useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {getSubject, publishSubject, unpublishSubject} from "../api/subjectConnector";
import Subject from "../domain/Subject";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {useTranslation} from "react-i18next";
import {HiX} from "react-icons/hi";
import LoadingComponent from "../../../components/LoadingComponent";
import SubjectContentManagement from "./SubjectContentManagement/SubjectContentManagement";
import {HiPencil} from "react-icons/hi2";
import {Button, Tooltip} from "flowbite-react";
import SubjectUpdateModal from "../components/SubjectModals/Modals/SubjectUpdateModal";
import ShowIf from "../../../components/ConditionalShow/ShowIf";
import SubjectTitle from "../components/SubjectModals/SubjectTitle";
import ShowIfRoles from "../../../components/ConditionalShow/ShowIfRoles";
import {RoleEnum} from "../../../components/authentication/RoleEnum";
import ShowIfElse from "../../../components/ConditionalShow/ShowIfElse";

interface SubjectManagementProps {
    selectedSubject: string;
}

export default function SubjectManagement({ selectedSubject }: SubjectManagementProps) {
    const navigate = useNavigate();
    const [subject, setSubject] = useState<Subject>();
    const [loading, setIsLoading] = useState(true);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
    const { throwError, roles } = useAppContext();
    const { t } = useTranslation("assessments");

    const reloadSubject = useCallback((subjectId: string) => {
        setIsLoading(true);
        getSubject(subjectId).then(subjectAxiosResponse => {
            setSubject(subjectAxiosResponse.data);
            setIsLoading(false);
        }).catch(() => throwError(t("getSubjectError")));
    }, [t, throwError]);

    const approveAndPublishSubject = useCallback(() => {
        publishSubject(selectedSubject).then(() => {
            reloadSubject(selectedSubject);
        }).catch(() => throwError(t("publishSubjectError")));
    }, [selectedSubject, reloadSubject, throwError, t]);

    const disapproveAndUnpublishSubject = useCallback(() => {
        unpublishSubject(selectedSubject).then(() => {
            reloadSubject(selectedSubject);
        }).catch(() => throwError(t("unpublishSubjectError")));
    }, [selectedSubject, reloadSubject, throwError, t]);

    useEffect(() => {
        if (selectedSubject !== "") {
            reloadSubject(selectedSubject);
        }
    }, [selectedSubject, reloadSubject]);

    const canBeUpdated = subject !== undefined && (roles.includes(RoleEnum.STAFF_GM_ACADEMIC) || !subject.isPublished);

    return (
        <AbstractDrawer shadow side="end" size="3xl" isOpen={selectedSubject !== ""}
                        setIsOpen={() => navigate("/assessments/subjects")}>
            <LoadingComponent isLoading={loading}>
                <div className="items-center">
                    <div className="flex mb-2 sm:mb-0">
                        <div className="inline-flex justify-between items-center w-full">
                            <button
                                className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                                onClick={() => navigate("/assessments/subjects")}>
                                <HiX color={"rgba(0,0,0,0.66)"} size="20"/>
                            </button>
                            <SubjectTitle variant="h2" title={subject?.title} isPublished={subject?.isPublished} />
                            <ShowIf condition={canBeUpdated}>
                                <Tooltip content={t("updateSubjectParams")}>
                                    <button
                                        onClick={() => setIsModalUpdateOpen(true)}
                                        className="p-2 ms-2 hover:bg-hexagone-darker-light-grey active:hover:bg-hexagone-purple rounded-full mx-auto">
                                        <HiPencil size="20" className="text-hexagone-purple active:text-hexagone-light-grey"/>
                                    </button>
                                </Tooltip>
                            </ShowIf>
                        </div>
                    </div>
                    <SubjectContentManagement subject={subject} canBeUpdated={canBeUpdated} />
                    <ShowIfElse condition={subject?.isPublished === false} elseShow={(
                        <ShowIfRoles restrictedTo={[RoleEnum.STAFF_GM_ACADEMIC]}>
                            <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700"/>
                            <Tooltip content={t("unpublishSubject.description")}>
                                <Button onClick={disapproveAndUnpublishSubject} fullSized color="failure">{t("unpublishSubject")}</Button>
                            </Tooltip>
                        </ShowIfRoles>
                    )}>
                        <ShowIfRoles restrictedTo={[RoleEnum.STAFF_GM_ACADEMIC]}>
                            <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700"/>
                            <Tooltip content={t("approveAndPublish.description")}>
                                <Button onClick={approveAndPublishSubject} fullSized color="success">{t("approveAndPublish")}</Button>
                            </Tooltip>
                        </ShowIfRoles>
                    </ShowIfElse>
                </div>
            </LoadingComponent>
            <SubjectUpdateModal isOpen={isModalUpdateOpen} setIsOpen={setIsModalUpdateOpen} setSubject={setSubject} subject={subject} reload={() => reloadSubject(selectedSubject)} />
        </AbstractDrawer>
    );
}