import React from "react";
import {useAppContext} from "../AppContext/AppContext";
import {RoleEnum} from "./RoleEnum";
import {Card} from "flowbite-react";
import {useTranslation} from "react-i18next";

interface RestrictedPageProps {
    children: React.ReactNode;
    authorizedRoles: RoleEnum[];
}

export default function RestrictedAccess({children, authorizedRoles}: RestrictedPageProps) {
    const { t } = useTranslation();

    const {roles} = useAppContext();
    const authorizedRolesString = authorizedRoles.map(a => a.toString());

    return (
        <div>
            {roles.some(e => authorizedRolesString.includes(e)) ? children : (
                <Card>{t("forbiddenAccess")}</Card>
            )}
        </div>
    )

}