import AbstractDrawer from "../../../components/AbstractDrawer";
import {useNavigate} from "react-router-dom";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {getQuestionBank} from "../api/questionBankConnector";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../components/AppContext/AppContext";
import {HiX} from "react-icons/hi";
import QuestionBank from "../domain/QuestionBank";
import LoadingComponent from "../../../components/LoadingComponent";
import QuestionBankUpdateModal from "../components/QuestionBankModals/QuestionBankUpdateModal";
import QuestionBankDeleteModal from "../components/QuestionBankModals/QuestionBankDeleteModal";
import QuestionCreateModal from "../components/QuestionModals/QuestionCreateModal";
import Question from "../domain/Question";
import QuestionCard from "../components/QuestionCard";
import {createQuestion, deleteQuestion, updateQuestion} from "../api/questionBankQuestionConnector";
import Answer from "../domain/Answer";
import {createAnswer, deleteAnswer, updateAnswer} from "../api/questionBankAnswerConnector";

interface SubjectManagementProps {
    selectedSubjectDrawer: string;
    reloadSubjects: () => void;
}

export default function QuestionBankManagement({selectedSubjectDrawer, reloadSubjects} : SubjectManagementProps) {
    const navigate = useNavigate();
    const { t } = useTranslation("assessments");
    const [subject, setSubject] = useState<QuestionBank>();
    const [questions, setQuestions] = useState<Question[]>([]);
    const [isSubjectLoading, setIsSubjectLoading] = useState(true);
    const {throwError} = useAppContext();

    const reloadSubject = useCallback((subjectId: string) => {
        if (subjectId !== "") {
            setIsSubjectLoading(true);
            getQuestionBank(subjectId)
                .then((subjectAxiosResponse) => {
                    setIsSubjectLoading(false);
                    setSubject(subjectAxiosResponse.data);
                })
                .catch(() => throwError(t("getSubjectError")));
        }
    }, [throwError, t]);

    function handleQuestionUpdate(updatableQuestion: Question, type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) {
        updateQuestion(selectedSubjectDrawer, updatableQuestion.id, {
            type,
            title,
            description,
            time
        }).then(questionAxiosResponse => {
            handleClose();
            if (setQuestions) {
                setQuestions(prevState => {
                    let newState = [...prevState];
                    const questionIndex = newState.findIndex(q => q.id === questionAxiosResponse.data.id);

                    if (questionIndex !== -1) {
                        newState[questionIndex] = {...newState[questionIndex], ...questionAxiosResponse.data};
                    }
                    return newState;
                });
            }
        }).catch(() => throwError(t("updateQuestionError")));
    }

    function handleQuestionCreate(type: string, title: string, description: string, time: number, handleClose: () => void, setQuestions: Dispatch<SetStateAction<Question[]>> | undefined) {
        createQuestion(selectedSubjectDrawer, {
            type,
            title,
            description,
            time
        }).then(questionAxiosResponse => {
            handleClose();
            if (setQuestions) {
                setQuestions(prevState => prevState.concat(questionAxiosResponse.data));
            }
        }).catch(() => throwError(t("createQuestionError")));
    }

    function handleQuestionDelete(questionId: string, setQuestions: Dispatch<SetStateAction<Question[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) {
        deleteQuestion(selectedSubjectDrawer, questionId)
            .then(() => {
                setQuestions(prevState => {
                    let newState = [...prevState];
                    const questionIndex = newState.findIndex(q => q.id === questionId);

                    if (questionIndex !== -1) {
                        newState.splice(questionIndex, 1);
                    }
                    return newState;
                });
                setIsOpen(false);
            })
            .catch(() => throwError("deleteQuestionError"));
    }

    function handleAnswerUpdate(questionId: string, updatableAnswer: Answer, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) {
        updateAnswer(selectedSubjectDrawer, questionId, updatableAnswer?.id, {
            content, isValid
        }).then(answerAxiosResponse => {
            setIsOpen(false);
            setAnswers(prevState => {
                let newState = [...prevState];
                const answerIndex = newState.findIndex(a => a.id === answerAxiosResponse.data.id);

                if (answerIndex !== -1) {
                    newState[answerIndex] = {...newState[answerIndex], ...answerAxiosResponse.data};
                }
                return newState;
            });
            setIsValid(false);
            setContent("");
        }).catch(() => throwError(t("updateAnswerError")));
    }

    function handleAnswerCreate(questionId: string, content: string, isValid: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsValid: Dispatch<SetStateAction<boolean>>, setContent: Dispatch<SetStateAction<string>>) {
        createAnswer(selectedSubjectDrawer, questionId, {
            content, isValid
        }).then(answerAxiosResponse => {
            setIsOpen(false);
            setAnswers(prevState => prevState.concat(answerAxiosResponse.data));
            setIsValid(false);
            setContent("");
        }).catch(() => throwError(t("createAnswerError")));
    }

    function handleAnswerDelete(questionId: string, answerId: number, setAnswers: Dispatch<SetStateAction<Answer[]>>, setIsOpen: Dispatch<SetStateAction<boolean>>) {
        deleteAnswer(selectedSubjectDrawer, questionId, answerId)
            .then(() => {
                setAnswers(prevState => {
                    let newState = [...prevState];
                    const answerIndex = newState.findIndex(a => a.id === answerId);

                    if (answerIndex !== -1) {
                        newState.splice(answerIndex, 1);
                    }
                    return newState;
                });
                setIsOpen(false);
            })
            .catch(() => throwError("deleteAnswerError"));
    }

    useEffect(() => {
        reloadSubject(selectedSubjectDrawer);
    }, [reloadSubject, selectedSubjectDrawer]);

    useEffect(() => {
        if (subject) {
            setQuestions(subject.questions || []);
        }
    }, [subject]);

    return (
        <AbstractDrawer shadow side="end" size="3xl" isOpen={selectedSubjectDrawer !== ""}
                        setIsOpen={() => navigate("/assessments/questions-bank")}>
            <LoadingComponent isLoading={isSubjectLoading}>
                <div className="items-center">
                    <div className="flex justify-between items-center">
                        <button
                            className="p-2 hover:bg-hexagone-light-grey active:hover:bg-hexagone-darker-light-grey rounded-full"
                            onClick={() => navigate("/assessments/questions-bank")}>
                            <HiX color={"rgba(0,0,0,0.66)"} size="20"/>
                        </button>
                        <div className="inline-flex">
                            <h2 className="text-3xl">{subject?.name}</h2>
                        </div>
                        <div className="flex items-center space-x-1">
                            <QuestionBankUpdateModal updatableSubject={subject} setUpdatableSubject={setSubject}
                                                     reloadSubjects={reloadSubjects}/>
                            <QuestionBankDeleteModal subjectId={selectedSubjectDrawer} reloadSubjects={reloadSubjects}/>
                        </div>
                    </div>
                    <div className="grid mt-2">
                        <QuestionCreateModal label={"addQuestion"} setQuestions={setQuestions} handleCreate={handleQuestionCreate}/>
                    </div>
                    <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"/>
                    {questions !== undefined && questions.length > 0 ? questions.map(question =>
                        <QuestionCard key={question.id} question={question} parentId={selectedSubjectDrawer}
                                      setQuestions={setQuestions} handleUpdate={handleQuestionUpdate}
                                      handleDelete={handleQuestionDelete} handleAnswerCreate={handleAnswerCreate}
                                      handleAnswerUpdate={handleAnswerUpdate} handleAnswerDelete={handleAnswerDelete}/>
                    ) : <span className="mt-4">{t("noQuestionBankQuestionsYet")}</span>}
                </div>
            </LoadingComponent>
        </AbstractDrawer>
    )
}