import Student from "./Student";
import {SubjectLight} from "./Subject";

export interface AssessmentLight {
    id: string;
    subject: SubjectLight;
    startDate: Date;
    endDate: Date;
    doneNumbers: number;
    instanceNumbers: number;
}

export default interface Assessment {
    id: string;
    subject: SubjectLight;
    startDate: Date;
    endDate: Date;
    instances: AssessmentInstanceLight[];
}

export enum AssessmentStateEnum {
    READY= "READY",
    STARTED= "STARTED",
    DONE= "DONE",
    DONE_UPDATABLE = "DONE_UPDATABLE",
    EXPIRED = "EXPIRED",
    NEED_CORRECTION = "NEED_CORRECTION",
    PENDING = "PENDING"
}

export interface AssessmentInstanceLight {
    id: string;
    code: string;
    student: Student;
    state: AssessmentStateEnum;
    score: number;
}

export interface AssessmentInstance {
    id: string;
    code: string;
    assessment: AssessmentLight;
    state: AssessmentStateEnum;
    finishedAt: Date;
}