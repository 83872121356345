import {Button, Modal} from "flowbite-react";
import {useTranslation} from "react-i18next";
import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import Subject, {SubjectLight, SubjectTypeEnum} from "../../../domain/Subject";
import SubjectForm from "./SubjectForm";
import ShowIf from "../../../../../components/ConditionalShow/ShowIf";
import QuizForm from "../../QuizModals/QuizForm";
import {HiCheck} from "react-icons/hi2";
import {QuizLight} from "../../../domain/Quiz";
import {useAppContext} from "../../../../../components/AppContext/AppContext";
import {updateSubject} from "../../../api/subjectConnector";
import DutyForm from "../../DutyModals/DutyForm";

interface SubjectCreateModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setSubject: Dispatch<SetStateAction<Subject | undefined>>;
    subject: Subject | undefined;
    reload: () => void;
}

export default function SubjectUpdateModal({ isOpen, setIsOpen, setSubject, subject, reload }: Readonly<SubjectCreateModalProps>) {
    const { t } = useTranslation("assessments");
    const [title, setTitle] = useState("");
    const [ectsCode, setEctsCode] = useState("");
    const [subjectType, setSubjectType] = useState<string>(SubjectTypeEnum.QUIZ);
    const [isProcessing, setIsProcessing] = useState(false);
    const { throwError } = useAppContext();

    useEffect(() => {
        if (subject) {
            setTitle(subject.title);
            setEctsCode(subject.ectsCode);
            if (subject.quiz) {
                setSubjectType(SubjectTypeEnum.QUIZ);
            } else {
                setSubjectType(SubjectTypeEnum.DUTY);
            }
        }
    }, [subject]);

    function submit({event, quiz, postAction}: {event: FormEvent<HTMLFormElement>, quiz?: QuizLight, postAction?: (subject: SubjectLight) => Promise<Subject | void>}) {
        event.preventDefault();
        setIsProcessing(true);
        updateSubject(subject?.id, {title, ectsCode, quiz}).then((subjectAxiosResponse) => {
            if (postAction) {
                postAction(subjectAxiosResponse.data)
                    .then(subjectPromise => {
                        if (typeof subjectPromise === "object") {
                            setSubject(subjectPromise);
                        }
                        setIsOpen(false);
                        setIsProcessing(false);
                    });
            } else {
                setSubject(subjectAxiosResponse.data);
                setIsOpen(false);
                setIsProcessing(false);
            }
        }).catch(() => throwError(t("updateSubjectError")));
    }

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>{t("updateSubject")}</Modal.Header>
            <Modal.Body>
                <SubjectForm
                    update
                    ectsCode={ectsCode}
                    subjectType={subjectType}
                    title={title}
                    setEctsCode={setEctsCode}
                    setSubjectType={setSubjectType}
                    setTitle={setTitle}
                    submit={() => undefined}/>
                <hr className="w-full my-3 border-gray-200 sm:mx-auto dark:border-gray-700"/>
                <ShowIf condition={subjectType === SubjectTypeEnum.QUIZ}>
                    <QuizForm initialTime={subject?.quiz?.time} initialAlerts={subject?.quiz?.alerts} submit={submit}/>
                </ShowIf>
                <ShowIf condition={subjectType === SubjectTypeEnum.DUTY}>
                    <DutyForm submit={submit} reload={reload} />
                </ShowIf>
            </Modal.Body>
            <Modal.Footer className="justify-between">
                <Button type="submit" color="success" form="subjectContentForm" isProcessing={isProcessing}>
                    <p>{t("validate", {ns: "common"})}</p>
                    <HiCheck className="ms-2 h-5 w-5"/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}