import {Label, Select} from "flowbite-react";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SubjectLight} from "../../domain/Subject";
import {getSubjects} from "../../api/subjectConnector";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {QuizInstruction} from "../../domain/Quiz";

interface SubjectSelectProps {
    selectedSubject: SubjectLight;
    instructions: QuizInstruction[];
    setSubject: Dispatch<SetStateAction<SubjectLight>>;
}

export default function SubjectSelect({selectedSubject, setSubject, instructions} : SubjectSelectProps) {
    const { t } = useTranslation("enrolmentTests");
    const [subjects, setSubjects] = useState<SubjectLight[]>([]);
    const { throwError } = useAppContext();

    useEffect(() => {
        getSubjects().then(subjectsAxiosResponse => {
            setSubjects(subjectsAxiosResponse.data);
        }).catch(() => throwError("getSubjectsError"));
    }, [throwError]);

    return (
        <div>
            <Label htmlFor="type" value={t("subject")}/>
            <div>
                <Select value={selectedSubject?.id}
                        onChange={(e) => setSubject(subjects.find(s => s.id === parseInt(e.target.value)) || selectedSubject)}
                        id="subject"
                        required
                >
                    <option disabled value={0}>
                        {t("selectSubject")}
                    </option>
                    {subjects.filter(s => !instructions.map(i => i.subject.id).includes(s.id)).map(subject => (
                        <option key={subject.id} value={subject.id}>
                            {subject.name} - {subject.questionCount} {t("questions")}
                        </option>
                    ))}
                </Select>
            </div>
        </div>
    );
}