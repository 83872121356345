import {Button, Modal} from "flowbite-react";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import {useTranslation} from "react-i18next";
import {HiCheck, HiPlus} from "react-icons/hi2";
import {addInstruction} from "../../api/quizConnector";
import {QuizInstruction} from "../../domain/Quiz";
import {useAppContext} from "../../../../components/AppContext/AppContext";
import {QuestionBankLight} from "../../domain/QuestionBank";
import QuestionBankSelect from "./QuestionBankSelect";
import WriteInput from "../../../../components/Inputs/WriteInput";

interface InstructionCreateModalProps {
    quizId: string;
    instructions: QuizInstruction[];
    setInstructions: Dispatch<SetStateAction<QuizInstruction[]>>;
}

export default function InstructionCreateModal({quizId, setInstructions, instructions} : InstructionCreateModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [questionBank, setQuestionBank] = useState<QuestionBankLight>({name: "", ectsCode: "", questionCount: 0, id: 0});
    const [questionNumber, setQuestionNumber] = useState(1);
    const { t } = useTranslation("assessments");
    const { throwError } = useAppContext();

    function submit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        addInstruction(quizId, {
            questionBank, quantity: questionNumber
        }).then(instructionAxiosResponse => {
            setInstructions(prevState => {
                let newState = [...prevState];
                newState.push(instructionAxiosResponse.data);
                return newState;
            });
            setIsOpen(false);
        }).catch(() => throwError(t("addInstructionError")));
    }

    return (
        <>
            <Button className="w-full" color="secondary" onClick={() => setIsOpen(true)}><HiPlus />&nbsp;{t("addInstruction")}</Button>
            <Modal show={isOpen} onClose={() => setIsOpen(false)}>
                <Modal.Header>
                    {t("addInstruction")}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submit} id="instructionForm">
                        <QuestionBankSelect selectedQuestionBank={questionBank} setQuestionBank={setQuestionBank} instructions={instructions}/>
                        <WriteInput label="questionNumber" value={questionNumber} setter={(v) => setQuestionNumber(parseInt(v))} max={questionBank?.questionCount !== undefined ? questionBank.questionCount : 0} min={questionBank?.questionCount !== undefined ? 1 : 0} type="number" t={t} />
                    </form>
                </Modal.Body>
                <Modal.Footer className="justify-end">
                    <Button type="submit" color="success" form="instructionForm">
                        <p>{t("validate", {ns: "common"})}</p>
                        <HiCheck className="ml-2 h-5 w-5"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}